import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Tooltip, Typography } from "@mui/material";
import { useIsMobile } from "../../../contexts/isMobile";
import FlowDiagram from "./FlowDiagram";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import WorkflowBuilder from "./WorflowBuilder";
import EditWorkflowBuilder from "./EditWorflowBuilder";
import { EditSvg } from "../../../assets/svgs";
import { EditJourneyModal } from "../Drawers/EditJourneyModal";
import {InactivityCheck} from "../../../Components/InactivityCheck"

export function Flow() {
  const isMobile = useIsMobile();
  const { id, patientId, assignId } = useParams();
  const [journeyTitle, setJourneyTitle] = useState(null);
  const [isJourneyError, setIsJourneyError] = useState(false)
  const [open, setOpen] = useState(false);
  const [refetchTitle, setRefetchTitle] = useState(false);
  const journeyId = localStorage.getItem("journeyId")
    ? JSON.parse(localStorage.getItem("journeyId"))
    : null;
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (assignId) {
      navigate(-1);
    } else {
      navigate("/journeys");
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          width: "100%",
          flexGrow: 1,
          bgcolor: "background.default",
          padding: isMobile ? "1.5rem 1rem" : "1.5rem 4rem",
        }}
      >
        {!isJourneyError && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginBottom:'10px'
          }}
        >
          <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={handleGoBack} />
          <Typography
            variant="h1"
            sx={{
              justifyContent: "center",
              textTransform: "capitalize",
              marginLeft: "10px",
              fontSize: "1.5em",
              cursor: "pointer",
            }}
          >
            {(journeyId || id || patientId) && (
              journeyTitle !== null ? (
                <div style={{ display: "flex" }}>
                  <Tooltip title={journeyTitle?.description}>
                    <span>{journeyTitle?.title}</span>
                  </Tooltip>
                  {!assignId && (
                  <div
                    style={{ marginLeft: "15px" }}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <EditSvg />
                  </div>
                  )}
                </div>
              ) : (
                "Loading...."
              )
            )}
          </Typography>
        </Box>
        )}
        {id || patientId ? (
          <EditWorkflowBuilder {...{ journeyTitle, setJourneyTitle, refetchTitle, setRefetchTitle, isJourneyError, setIsJourneyError }} />
        ) : (
          <WorkflowBuilder {...{ journeyTitle, setJourneyTitle, journeyId, refetchTitle, setRefetchTitle, isJourneyError, setIsJourneyError }} />
        )}

        <EditJourneyModal {...{ open, setOpen, data: journeyTitle, setRefetchTitle, id: journeyId ? journeyId : id }} />

        {/* <FlowDiagram /> */}
      </Box>
      <InactivityCheck />
    </Box>
  );
}
