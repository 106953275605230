import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { getTokenFromLocalStroage } from "../../../utils";
import { toast } from "react-toastify";


export default function MarkAsResolved({selectedPatientIds, setSelectedPatientIds, queries, refetchQueueAgain}){
    const config = {
        headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
      };
    const updateStatus = async ( currentStatus) => {
        const newStatus = currentStatus === "pending" ? "resolved" : "pending";
        try {
          await axios
            .put(
              `${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatuses`,
              { status: newStatus, ids: selectedPatientIds },
              config
            )
            .then((res) => {
              const status = res.data.status;
              toast.success("The selected issue is " + status);
            //   setItemStatus(status);
              refetchQueueAgain();
              setSelectedPatientIds([])
            //   refetchCount();
            });
        } catch (error) {
          toast.error("Error:", error);
        }
      };
    return(
        <>
        <Box sx={{display:'flex', justifyContent:'flex-start', alignItems:'center', ml:'5px'}}>
            <Typography><span style={{fontWeight:'bolder', marginRight:'5px'}}>({`${selectedPatientIds?.length}`})</span>Message Queries Selected</Typography>
            <Button
            variant="contained"
            size="small"
            sx={{
              padding: "0px",
              cursor: "pointer",
              ml:'10px'
            }}
            onClick={() => updateStatus(queries?.status)}
          >
            <Typography
              variant="caption"
              sx={{ color: "white", textWrap: "nowrap", padding: "5px	" }}
            >
              {queries?.status === "resolved"
                ? "Mark as Pending"
                : "Mark as Resolved"}
            </Typography>
          </Button>
        </Box>
        </>
    )
}