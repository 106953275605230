export const messagesColumn = [
	{ id: 'collapsibleIcon', label: '' },
	{
		id: 'patientInfo',
		label: 'Patient Information',
	},
	{
		id: 'taskCategory',
		label: 'Task Category',
	},
	{
		id: 'createdAt',
		label: 'Contact Time',
	},
	{
		id: 'surgeonName',
		label: 'Physician Name',
	},
	{
		id: 'surgeryType',
		label: 'Procedure Type',
	},
	// {
	// 	id: 'surgeryDate',
	// 	label: 'Procedure Date',
	// },
	{
		id:'queueType',
		label:'Queue Type'
	},
	{
		id: 'socialNeeds',
		label: 'Social Needs',
	},
	{
		id: 'acuity',
		label: 'Acuity',
	},
	// {
	// 	id: 'ChatbotMode',
	// 	label: 'Chatbot Mode',
	// },

	{
		id: 'action',
		label: 'Action',
	},
]

export const dialoguesColumn = [
	{ id: 'collapsibleIcon', label: '' },
	{
		id: 'patientInfo',
		label: 'Patient Information',
	},
    {
		id: 'dialogueIntent',
		label: 'Dialogue Intent',
	},
	{
		id: 'createdAt',
		label: 'Message Time',
	},
	{
		id: 'surgeonName',
		label: 'Surgeon Name',
	},
	{
		id: 'surgeryType',
		label: 'Procedure Type',
	},
	{
		id: 'surgeryDate',
		label: 'Procedure Date',
	},
	{
		id: 'socialNeeds',
		label: 'Social Needs',
	},
	{
		id: 'acuity',
		label: 'Acuity',
	},
	{
		id: 'ChatbotMode',
		label: 'Chatbot Mode',
	},

	{
		id: 'action',
		label: 'Action',
	},
]

export const CareAllyColumn = [
	{
		id: 'patientInfo',
		label: 'Patient Information',
	},
	{
		id: 'taskCategory',
		label: 'Task Category/Conversation',
	},
	{
		id: 'createdAt',
		label: 'Message Time',
	},
	{
		id: 'surgeonName',
		label: 'Surgeon Name',
	},
	{
		id: 'surgeryType',
		label: 'Procedure Type',
	},
	{
		id: 'surgeryDate',
		label: 'Procedure Date',
	},
	{
		id: 'socialNeeds',
		label: 'Social Needs',
	},
	{
		id: 'acuity',
		label: 'Acuity',
	},

	{
		id: 'action',
		label: 'Action',
	},
]

export const PhotosColumn = [
	{
		id: 'patientInfo',
		label: 'Patient Information',
	},
	{
		id: 'taskCategory',
		label: 'Task Category',
	},
	{
		id: 'createdAt',
		label: 'Creation Time',
	},
	{
		id: 'socialNeeds',
		label: 'Social Needs',
	},
	{
		id: 'acuity',
		label: 'Acuity',
	},
	{
		id: 'action',
		label: 'Action',
	},
]

export const FormsColumn = [
	{
		id: 'patientInfo',
		label: 'Patient Information',
	},
	{
		id: 'taskCategory',
		label: 'Task Category',
	},
	{
		id: 'createdAt',
		label: 'Creation Time',
	},
	{
		id: 'socialNeeds',
		label: 'Social Needs',
	},
	{
		id: 'acuity',
		label: 'Acuity',
	},
	{
		id: 'action',
		label: 'Action',
	},
]
