import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import { useState } from "react";
import PopoverQueueUI from "../FilterUtils/PopoverQueueUI";
import { useGetPatientQueueBadgeCountQuery } from "../../../store/patientQueuesSlice";

const dummyTabData = [
  { count: "31", label: "Messages", key: "message" },
  { count: "2", label: "Photo Uploads", key: "new_media" },
  { count: "3", label: "Forms", key: "form_submission" },
  { count: "4", label: "Care Ally Messages", key: "care_ally_message" },
  { count: "5", label: "Call", key: "call" },
  { count: "5", label: "Dialogue Assessments", key: "dialogue" },
  { count: "5", label: "Tasks", key: "task" },
];

const getCountByKey = (key, counts) => {
  switch (key) {
    case "message":
      return counts.messageCount;
    case "new_media":
      return counts.newMediaCount;
    case "form_submission":
      return counts.formSubmissionCount;
    case "care_ally_message":
      return counts.careAllyMessageCount;
    case "dialogue":
      return counts.dialoguesCount;
    case "call":
      return counts?.callCount || 0;
    case "task":
      return counts?.taskCount || 0;
    default:
      return 0; // Default fallback value
  }
};

const timeFrameData = [
  {
    title: "All",
    value: "all",
  },
  {
    title: "Today",
    value: "today",
  },
  {
    title: "Yesterday",
    value: "yesterday",
  },
  {
    title: "Last 7 Days",
    value: "7",
  },
  {
    title: "Last 30 Days",
    value: "30",
  },
];

export default function QueueTabsV2({queries, setQueries, selectedValue, setSelectedValue}) {
  const { data, isLoading, refetch } = useGetPatientQueueBadgeCountQuery()
  const [anchorEl, setAnchorEl] = useState(null);
  console.log(data, 'Badge Data')

  // For Popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Calculate today's date and the date two months ago
  const today = new Date();
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(today.getMonth() - 2);

  const [startDate, setStartDate] = useState(twoMonthsAgo);
  const [endDate, setEndDate] = useState(today);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (e, key) => {
    setQueries((prevQueries) => ({
      ...prevQueries,
      types: prevQueries?.types?.includes(key)
        ? prevQueries?.types?.filter((item) => item !== key) // Remove the key if it already exists
        : [...(prevQueries?.types || []), key], // Add the key if it doesn't exist
    }));
  };

  const handleChangeDropdown = (event) => {
    localStorage.setItem('selectedValue', event.target.value)
    setSelectedValue(event.target.value);
};
  
  

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Tabs Part */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {dummyTabData?.map((item) => (
            <Box
              sx={{
                border: "1px solid #d9d9d9",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                mr: "5px",
              }}
            >
              <Checkbox sx={{ padding: "0px 2px" }} size="small" onChange={(e)=> handleChange(e, item.key)} />
              <Typography sx={{ fontSize: "12px", cursor: "pointer" }}>
                {item?.label} {`(${getCountByKey(item.key, data?.patientQueueCount[0])})`}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Filters Part */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {/* <DateRangeRoundedIcon
            sx={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleClick}
          /> */}
          <Typography sx={{ marginRight: "8px"}} color={'#B3B7BA'}>Sort by:</Typography>
          <FormControl sx={{ minWidth: 80 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={"all"}
              sx={{ height: 35, borderRadius: '8px' }}  // Set the height here
              value={selectedValue}
              onChange={handleChangeDropdown}
            >
              {timeFrameData?.map((values, index) => (
              <MenuItem key={values._id} value={values.value}>{values.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <PopoverQueueUI
        {...{
          id,
          open,
          anchorEl,
          handleClose,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
        }}
      />
    </>
  );
}
