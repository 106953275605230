import React, { useState } from 'react'
import { Button, Modal, Typography, Box, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material'
import { useAddNewPatientMutation, useGetPatientsByHospitalIdQuery } from '../store/patientsSlice'
import DemographicsData from '../Pages/Patient/CreatePatient/DemographicsData'
import { useUpdatePatientInPatientQueueMutation } from '../store/patientQueuesSlice'
import { toast } from 'react-toastify'
import { formatPhone } from '../utils'
import { useSelector } from 'react-redux'
import { ArrowBack, PersonAdd } from '@mui/icons-material'

const LinkQueueModal = ({ open, onClose, queueId, refetchQueueAgain }) => {
	const { user } = useSelector((state) => state.user)

	const { data: patients, error, isLoading, refetch } = useGetPatientsByHospitalIdQuery()
	const [updatePatientInPatientQueue, { isLoading: isUpdationLoading }] = useUpdatePatientInPatientQueueMutation()
	const [addNewPatient, { isLoading: isPatientLoading }] = useAddNewPatientMutation()

	const [selectedPatient, setSelectedPatient] = useState('')
	const [creatingNewPatient, setCreatingNewPatient] = useState(false)
	const [language, setLanguage] = useState('')
	const [isValidNumber, setIsValidNumber] = useState(null)
	const [state, setState] = useState('')
	const [physician, setPhysician] = useState('')
	const [birthday, setBirthday] = useState('')
	const [mrn, setMrn] = useState('')
	const [team, setTeam] = useState('')
	const [selectedTeam, setSelectedTeam] = useState('')
	const [programTags, setProgramTags] = useState([])
	const [selectedProgramTags, setSelectedProgramTags] = useState('')
	const [selectedTags, setSelectedTags] = useState([])
	const [creatorData, setCreaterData] = useState('')
	const [membersData, setMembersData] = useState([])
	const [selectedPhysician, setSelectedPhysician] = useState(null)
	const [selectedEthnicity, setSelectedEthnicity] = useState('')
	const [selectedRace, setSelectedRace] = useState('')
	const [form, setForm] = useState({
		firstName: '',
		lastName: '',
		gender: '',
		patientSummary: '',
		email: '',
		phone: '',
		address1: '',
		address2: '',
		state: '',
		city: '',
		zip: '',
		tags: [],
		race: '',
		ethnicity: '',
	})

	const onChange = (e) => {
		setForm((form) => ({ ...form, [e.target.name]: e.target.value }))
	}

	const handlePatientChange = (event) => {
		setSelectedPatient(event.target.value)
	}

	const handleCreateNewPatient = () => {
		setCreatingNewPatient(true)
	}

	const handleClose = () => {
		setCreatingNewPatient(false)
		onClose()
	}

	const handleUpdatePatientInPatientQueue = (id) => {
		updatePatientInPatientQueue({ body: { patientId: id, patientQueueId: queueId } })
			.then((res) => {
				toast.success(res?.data?.message)
				setSelectedPatient('')
				refetchQueueAgain()
				handleClose()
			})
			.catch((error) => {
				toast.error('Something went wrong!')
				console.log('ERROR:', error)
			})
	}

	const handleCreate = () => {
		const requiredFields = ['firstName', 'lastName', 'phone']
		const fieldMessages = {
			firstName: 'First Name',
			lastName: 'Last Name',
			phone: 'Phone',
		}
		const missingField = requiredFields.find((field) => !form[field])

		if (missingField) {
			const friendlyFieldName = fieldMessages[missingField]
			toast.error(`${friendlyFieldName} is required!`)
			return
		}

		if (isValidNumber === true) {
			// Formatting phone number by replacing spaces and adding + sign for the number validator
			const formattedPhone = formatPhone(form?.phone)

			if (mrn?.length !== 0 && mrn.length > 20) {
				toast.error('MRN must not exceed 20 words')
				return
			}

			addNewPatient({
				...form,
				phone: formattedPhone,
				birthday,
				language,
				state,
				race: selectedRace,
				ethnicity: selectedEthnicity,
				team,
				physician,
				tags: selectedTags,
				pathway: programTags,
				program: programTags,
				members: [user.id],
				creator: user.id,
			})
				.unwrap()
				.then((data) => {
					refetch()
					handleUpdatePatientInPatientQueue(data?.data?._id)
					handleClose()
				})
				.catch((error) => {
					if (error.data === 'Cast to ObjectId failed for value "" (type string) at path "team" because of "BSONError"') {
						toast.error('Team is required')
					} else {
						toast.error(error.data?.message)
					}
				})
		} else {
			toast.error('The number is not valid!')
		}
	}

	if (isLoading) return <>{console.log('Loading....')}</>

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: creatingNewPatient ? '50vw' : 400,
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 4,
					borderRadius: 2,
					maxHeight: '90vh',
					overflowY: 'auto',
				}}
				className="DialogContent"
			>
				{!creatingNewPatient ? (
					<>
						<Typography variant="h6" component="h2" gutterBottom>
							Link the Queue with the Existing Patient
						</Typography>
						<FormControl fullWidth margin="normal">
							<InputLabel id="patient-select-label">Select Patient</InputLabel>
							<Select labelId="patient-select-label" value={selectedPatient} onChange={handlePatientChange}>
								{patients &&
									patients?.data?.map((patient) => (
										<MenuItem key={patient._id} value={patient._id}>
											{patient.firstName} {patient.lastName}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
							<Button variant="outlined" color="primary" startIcon={<PersonAdd />} onClick={handleCreateNewPatient} disabled={isUpdationLoading}>
								Create New Patient
							</Button>
							<Button variant="contained" color="primary" onClick={() => handleUpdatePatientInPatientQueue(selectedPatient)} disabled={isUpdationLoading}>
								Save
							</Button>
						</Box>
					</>
				) : (
					<Box>
						<Box sx={{ display: 'flex', alignItems: 'center', marginY: 2 }}>
							<IconButton onClick={() => setCreatingNewPatient(false)}>
								<ArrowBack />
							</IconButton>

							<Typography variant="h6" component="h2" sx={{ ml: 1, lineHeight: 1 }}>
								Create New Patient
							</Typography>
						</Box>
						<DemographicsData
							{...{
								selectedPhysician,
								selectedEthnicity,
								setSelectedEthnicity,
								selectedRace,
								setSelectedRace,
								setSelectedPhysician,
								physician,
								setPhysician,
								form,
								onChange,
								setForm,
								selectedProgramTags,
								setSelectedProgramTags,
								isValidNumber,
								setIsValidNumber,
								birthday,
								setBirthday,
								language,
								setLanguage,
								state,
								setState,
								team,
								setTeam,
								selectedTeam,
								setSelectedTeam,
								programTags,
								setProgramTags,
								selectedTags,
								setSelectedTags,
								mrn,
								setMrn,
							}}
						/>
						{/* Add your new patient creation form here */}
						<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
							<Button variant="contained" onClick={handleCreate}>
								Save
							</Button>
						</Box>
					</Box>
				)}
			</Box>
		</Modal>
	)
}

export default LinkQueueModal
