import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useChildNode } from "../contexts/addChildNode";
import {toast} from "react-toastify"

export function NodeMenu({
  handleOpenModal,
  toggleEditDrawer,
  anchorEl,
  setAnchorEl,
  setIsEdit,
  setExpanded,
  isfilled,
  hasChildNode,
  setAddChildNode,
  id,
  checkCondition,
  setAnchorElButton,
  isNextNodefilled,
  isEdit,
  title,
  nodeInternals,
  isConditionLastNode,
}) {
  const open = Boolean(anchorEl);
  const { setIsChildNodeAdded, setEditMenu, setForceRefreshChildNode } = useChildNode();

  const [permissionValues, setPermissionValues] = useState(null)

  const checkIsFilledNode = () => {
    for (const [key,value] of nodeInternals.entries()) {
      if (!value.data?.isFilled && value?.data?.label !== 'Start Journey' && value?.data?.method !== 'delay' ) {
        return key
      }
    }

    return null
  }

  const handleClick = (event) => {
    if(checkIsFilledNode() !== id && checkIsFilledNode() !== null){
      toast.warning('Please save or delete the unsaved node to continue. The unsaved node has a red flashing sign.')
    }else{
      setExpanded(false);
      setEditMenu(null);
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElButton(null);
  };

  const handleAdChild = () => {
    setAddChildNode(true);
    setIsChildNodeAdded(true);
    setForceRefreshChildNode(true);
    setAnchorEl(null);
    setAnchorElButton(null);
  };

  return (
    <div style={{marginTop: title && '5px'}}>
      <div
        sx={{ cursor: "pointer" }}
        id="basic-button"
        aria-controls={open ? "basic-menu1" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </div>
      <Menu
        id="basic-menu1"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!hasChildNode &&
          !checkCondition &&
          isNextNodefilled() &&
          !isConditionLastNode() &&
          isfilled() && (
            <MenuItem onClick={handleAdChild}>Add Child Node</MenuItem>
          )}
        {(isfilled()) && (
          <MenuItem
            onClick={() => {
              setIsEdit(true);
              setEditMenu(id);
              setAnchorEl(null);
            }}
          >
            Edit
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenModal();
            setAnchorEl(null);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}
