import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ChatBotDropdownButton from "./ChatBotModeDropdown";
import ConversationTab from "./ConversationTab";
import InterventionTab from "./InterventionTab";
import { useState } from "react";
import { useIsMobile } from "../../contexts/isMobile";
import GalleryTab from "./GalleryTab";
import CareAllyTab from "./CareAllyTab";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import CallIcon from "@mui/icons-material/Call";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { useLocation } from "react-router-dom";
import AiAssistantDropdownButton from "./AiAssistantDropdownButton";
import AiConversationTab from "./AiConversationTab";
import {
  useGetPatientImagesQuery,
  useSendImageLinkMutation,
} from "../../store/patientsSlice";
import { Badge, IconButton } from "@mui/material";
import CallTab from "./CallTab";
import FormTab from "./FormTab";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { AssistantSvg } from "../../assets/svgs/Queue Icons";
import DialogueTab from "./DialogueTab";
import { ConfirmationModal } from "./ConfirmationModal";
import { toast } from "react-toastify";

function findTabIndex(tabItems, value, assistantValue) {
  if (value === "assistant") return assistantValue;

  return tabItems.findIndex((item) => item.key === value);
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProfileTabV2({
  refetchAgain,
  chatData,
  isChatLoading,
  isDrawer,
  originalMessage,
  chatbotReply,
  patient,
  reasoning,
  createdAt,
  userFeedback,
  queueId,
  queueStatus,
  setPage,
  page,
  title,
  patientDrawer,
  consent,
  acuity,
  taskCategory,
  value,
  setValue,
  careAllyData,
  refetchCareAllyAgain,
  tabValue,
  status,
  refetchPatientAgain,
  refetchChat,
  clickedValue,
  setClickedValue,
  queueData,
}) {
  console.log("Queue Data:", queueData);
  const isMobile = useIsMobile();
  const location = useLocation();
  const pathname = location?.pathname.split("/")[1];
  const [isHumanInterventionAvailable, setIsHumanInterventionAvailable] =
    useState(false);
    const [isDialogueAssessmentAvailable, setIsDialogueAssessmentAvailable] =
    useState(false);
  const tabItems = [];
  const humanInterventionValue =
    isHumanInterventionAvailable && (careAllyData ? 5 : 4);
  const dialogueAssessmentValue = isHumanInterventionAvailable
    ? humanInterventionValue + 1
    : !isHumanInterventionAvailable && careAllyData
    ? 5
    : 4;

  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedCareAllyValue, setSelectedCareAllyValue] = useState(null);
  const [selectedAiValue, setSelectedAiValue] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { data } = useGetPatientImagesQuery(patient?._id);
  const [sendImageLink] = useSendImageLinkMutation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const AiAssistantValue = (() => {
    if (!careAllyData && (title === "patients" || title === "profile")) {
      return 4;
    } else if (careAllyData && (title === "patients" || title === "profile")) {
      return 5;
    } else if (
      (pathname === "queue" || pathname === "dashboard") &&
      !careAllyData
    ) {
      return 6;
    } else if (pathname === "queueprofile" && !careAllyData) {
      return 5;
    } else {
      return 7;
    }
  })();

  const handleOpenConfirmation = () => {
    setOpenModal(true);
  };

  const handleConfirmModal = async () => {
    if (status === "active") {
      try {
        const response = await sendImageLink({
          id: patient?._id,
          uploadBy: "patient",
        });
        if (response?.error?.data?.error) {
          toast.error("Something went wrong!");
        } else {
          toast.success("Image link sent to patient successfully!");
          refetchAgain();
          setOpenModal(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error:", error.message);
      }
    } else {
      toast.error("The patient status is currently inactive.");
      setOpenModal(false);
    }
  };

  tabItems.push(
    <Tab
      key="messages"
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: 600,
            textTransform: "capitalize",
            color: value === 0 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Badge
            color="error"
            variant="dot"
            invisible={
              !data?.patientImages || data?.patientImages?.length === 0
            } // Hide the dot if no data
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <ChatBubbleOutlineIcon
              style={{ marginRight: "5px" }}
              fontSize="small"
            />
            Messages
          </Badge>
        </div>
      }
      {...a11yProps(0)}
    />
  );
  tabItems.push(
    <Tab
      key="calls"
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: 600,
            textTransform: "capitalize",
            color: value === 1 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <CallIcon style={{ marginRight: "5px" }} fontSize="small" />
          Calls
        </div>
      }
      {...a11yProps(1)}
    />
  );
  tabItems.push(
    <Tab
      key="gallery"
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 2 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <Badge
            color="error"
            variant="dot"
            invisible={
              !data?.patientImages || data?.patientImages?.length === 0
            } // Hide the dot if no data
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CollectionsOutlinedIcon
              style={{ marginRight: "5px" }}
              fontSize="small"
            />
            <span style={{ marginRight: "7px" }}>Gallery</span>
          </Badge>
        </div>
      }
      {...a11yProps(2)}
    />
  );

  tabItems.push(
    <Tab
      key="Forms"
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            fontWeight: 600,
            color: value === 3 ? "#000" : "rgba(0,0,0,0.60)",
          }}
        >
          <FeedOutlinedIcon style={{ marginRight: "5px" }} fontSize="small" />
          Forms
        </div>
      }
      {...a11yProps(3)}
    />
  );

  if (careAllyData) {
    tabItems.push(
      <Tab
        key="care-ally"
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: 600,
              color: value === 4 ? "#000" : "rgba(0,0,0,0.60)",
            }}
          >
            <HandshakeOutlinedIcon
              style={{ marginRight: "5px" }}
              fontSize="small"
            />
            Care Ally
          </div>
        }
        {...a11yProps(4)}
      />
    );
  }

  if (queueData?.type === "message") {
    tabItems.push(
      <Tab
        key="intervention"
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: 600,
              color:
                value === humanInterventionValue ? "#000" : "rgba(0,0,0,0.60)",
            }}
          >
            <PersonOutlineIcon
              style={{ marginRight: "5px" }}
              fontSize="small"
            />
            Human Intervention
          </div>
        }
        {...a11yProps(humanInterventionValue)}
      />
    );
  }

  if(queueData?.type === "dialogue"){
    tabItems.push(
      <Tab
        key="dialogue"
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: 600,
              color:
                value ===dialogueAssessmentValue
                  ? "#000"
                  : "rgba(0,0,0,0.60)",
            }}
          >
            <QuestionAnswerOutlinedIcon
              style={{ marginRight: "5px" }}
              fontSize="small"
            />
            Dialogue Assessment
          </div>
        }
        {...a11yProps(
          dialogueAssessmentValue
        )}
      />
    );
  }

  React.useEffect(() => {
    if (refetchCareAllyAgain) {
      refetchCareAllyAgain();
    }
  }, []);

  React.useEffect(() => {
    if (tabItems?.length !== 0) {
      setIsHumanInterventionAvailable(
        title !== "patients" &&
          title !== "profile" &&
          tabValue !== 1 &&
          tabValue !== 2 &&
          queueData?.type === "message"
      );

      setIsDialogueAssessmentAvailable(queueData?.type === "dialogue")
    }
  }, [tabItems, queueData]);

  React.useEffect(() => {
    const objectData = {
      'message': 0,
      'call': 1,
      'new_media': 2,
      'form_submission': 3,
      'care_ally_message': careAllyData && 4,
      'dialogue': queueData?.type === "dialogue" && dialogueAssessmentValue,
      'assistant': AiAssistantValue,
      'task': 0
    };

    if (tabItems?.length !== 0 && clickedValue && clickedValue !== "gallery") {
      setValue(objectData[clickedValue])
    } else if (
      tabItems?.length !== 0 &&
      clickedValue &&
      clickedValue === "gallery"
    ) {
      setValue(0);
      handleOpenConfirmation();
    }
  }, [careAllyData]);

  return (
    <Box sx={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
          position: "sticky",
          top: patientDrawer ? "5.4rem" : "5.8rem",
          backgroundColor: "white",
          height: isDrawer ? "100%" : "65%",
          zIndex: "990",
          padding: 3,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            sx: {
              left: `calc(${value} * 15%)`,
              "&.MuiTabs-indicator": {
                backgroundColor: "#000",
              },
            },
          }}
        >
          {tabItems}
        </Tabs>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {(value === 0 || (careAllyData && value === 4)) && ( // Add this condition to hide the dropdown when Care Ally tab is selected
            <div style={{ margin: 2 }}>
              <ChatBotDropdownButton
                chatBotEnabled={
                  value === 0 ? patient?.chatMode : careAllyData?.chatMode
                }
                selectedValue={
                  value === 0 ? selectedValue : selectedCareAllyValue
                }
                setSelectedValue={
                  value === 0 ? setSelectedValue : setSelectedCareAllyValue
                }
                patientId={value === 0 ? patient?._id : careAllyData?._id}
                refetch={
                  value === 0 ? refetchPatientAgain : refetchCareAllyAgain
                }
                value={value}
                pathname={pathname}
                refetchChat={refetchChat}
                isV2True={true}
              />
            </div>
          )}
          {value === AiAssistantValue && ( // Add this condition to hide the dropdown when Care Ally tab is selected
            <div style={{ margin: 2 }}>
              <AiAssistantDropdownButton
                chatBotEnabled={
                  value === 0 ? patient?.chatMode : careAllyData?.chatMode
                }
                selectedValue={selectedAiValue}
                setSelectedValue={setSelectedAiValue}
                patientId={value === 0 ? patient?._id : careAllyData?._id}
                refetch={
                  value === 0 ? refetchPatientAgain : refetchCareAllyAgain
                }
                value={value}
                pathname={pathname}
                refetchChat={refetchChat}
                setAgentId={setAgentId}
                isV2True={true}
              />
            </div>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginLeft: "5px",
            }}
          >
            <IconButton
              aria-label="Ai-agent"
              size="large"
              sx={{
                border: "1px solid #cdcdcd",
                padding: "5px",
                color: value === AiAssistantValue && "white",
                background: value === AiAssistantValue && "#1B4DFF",
                "&:hover": {
                  backgroundColor: value === AiAssistantValue && "#fff",
                  color: value === AiAssistantValue && "black",
                },
              }}
              onClick={() => {
                setValue(AiAssistantValue);
              }}
            >
              <AssistantSvg />
            </IconButton>
          </Box>
        </Box>
      </div>
      <CustomTabPanel value={value} index={0}>
        <ConversationTab
          id={patient?._id}
          consent={consent}
          isDrawer={isDrawer}
          selectedValue={selectedValue}
          chatBot={patient?.chatBotEnabled}
          page={page}
          setPage={setPage}
          status={status}
          value={value}
          refetchChat={refetchChat}
          chatData={chatData}
          isChatLoading={isChatLoading}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <CallTab id={patient?._id} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <FormTab id={patient?._id} />
      </CustomTabPanel>

      {title !== "patients" &&
        title !== "profile" &&
        queueData?.type === "message" &&
        value === humanInterventionValue && (
          <CustomTabPanel value={value} index={humanInterventionValue}>
            <InterventionTab
              isDrawer={isDrawer}
              refetchAgain={refetchAgain}
              queueId={queueId}
              userFeedback={userFeedback}
              originalMessage={originalMessage}
              chatbotReply={chatbotReply}
              patient={patient}
              reasoning={reasoning}
              createdAt={createdAt}
              queueStatus={queueStatus}
              patientDrawer={patientDrawer}
              acuity={acuity}
              taskCategory={taskCategory}
              queueData={queueData}
            />
          </CustomTabPanel>
        )}

      <>
        <CustomTabPanel value={value} index={2}>
          <GalleryTab id={patient?._id} isDrawer={isDrawer} />
        </CustomTabPanel>
        {careAllyData && (
          <CustomTabPanel value={value} index={4}>
            <CareAllyTab
              id={patient?._id}
              consent={careAllyData?.consentResponse}
              isDrawer={isDrawer}
              careAllyData={careAllyData}
              page={page}
              selectedValue={selectedCareAllyValue}
              setPage={setPage}
              status={status}
              value={value}
            />
          </CustomTabPanel>
        )}
        <CustomTabPanel value={value} index={AiAssistantValue}>
          <AiConversationTab
            id={patient?._id}
            consent={consent}
            isDrawer={isDrawer}
            selectedValue={selectedValue}
            chatBot={patient?.chatBotEnabled}
            page={page}
            setPage={setPage}
            status={status}
            value={value}
            refetchChat={refetchChat}
            chatData={chatData}
            isChatLoading={isChatLoading}
            AiAssistantValue={AiAssistantValue}
            agentId={agentId}
          />
        </CustomTabPanel>

        <CustomTabPanel
          value={value}
          index={
            dialogueAssessmentValue
          }
        >
          <DialogueTab queueData={queueData} />
        </CustomTabPanel>
      </>

      <ConfirmationModal
        open={openModal}
        setOpen={setOpenModal}
        txt={
          "The secure link will be sent to the patient to upload images. Do you wish to proceed with this?"
        }
        handle={handleConfirmModal}
        id={patient?._id}
      />
    </Box>
  );
}
