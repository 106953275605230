import React, { useState } from 'react'
import {
	Drawer,
	Grid,
	Stack,
	Paper,
	Box,
	Typography,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	OutlinedInput,
	ListItemText,
	Checkbox,
	Divider,
} from '@mui/material'
import moment from 'moment'
import { SolidButton } from '../../../Components'
import { useIsMobile } from '../../../contexts/isMobile'
import { useGetPatientsSurveyResultByIDQuery } from '../../../store/patientsSurveySlice'

const Content = ({ toggleDrawer, pendingurl }) => {
	const id = pendingurl.split('/')
	console.log(id[5], 10)
	const { data: result, isSuccess } = useGetPatientsSurveyResultByIDQuery(id[5])

	const isMobile = useIsMobile()
	const data = result?.data?.formResult
	const renderResult = (item) => {
		if (item?.type === 'signaturepad' && item?.result) {
			return <img src={item.result} alt="Signature" />
		} else if (item?.type === 'multipletext' && typeof item?.result === 'object') {
			return (
				<Box>
					{Object.entries(item.result).map(([key, value], index) => (
						<Typography key={index} sx={{ marginTop: '10px', fontSize: '14px' }}>
							{key}: {value}
						</Typography>
					))}
				</Box>
			)
		} else {
			return item?.result
		}
	}

	// Code for format Date filled and Time filled of survey
	const formattedDate = moment(result?.data?.updatedAt).format('MM-DD-YYYY')
	const formattedTime = moment(result?.data?.updatedAt).format('hh:mm A')

	return (
		<Box sx={{ padding: '2rem' }}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '3rem' }}>
				<Typography variant="p" sx={{ fontSize: '28px', fontWeight: '400' }}>
					{result?.data?.title}
				</Typography>
			</Stack>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<Grid container spacing={2}>
					<Grid item md={12} xs={12}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'start',
							}}
						>
							<Typography variant="h2" sx={{ fontSize: '15px', marginRight: '2px' }}>
								Description:
							</Typography>
							<Typography
								sx={{
									wordWrap: 'break-word',
									overflowWrap: 'break-word',
									hyphens: 'auto',
								}}
							>
								{result?.data?.description}
							</Typography>
							{result?.data?.score && (
								<>
									<br />
									<Typography variant="h2" sx={{ fontSize: '15px', marginRight: '2px' }}>
										Score:
									</Typography>
									<Typography
										sx={{
											wordWrap: 'break-word',
											overflowWrap: 'break-word',
											hyphens: 'auto',
										}}
									>
										{result?.data?.score}
									</Typography>
								</>
							)}
							{result?.data?.globalMentalHealth && (
								<>
									<br />
									<Typography variant="h2" sx={{ fontSize: '15px', marginRight: '2px' }}>
										Gloabal Mental Health:
									</Typography>
									<Typography
										sx={{
											wordWrap: 'break-word',
											overflowWrap: 'break-word',
											hyphens: 'auto',
										}}
									>
										T-score: <strong>{result?.data?.globalMentalHealth.tscore}</strong> &nbsp; SE*:{' '}
										<strong>{result?.data?.globalMentalHealth.standardError}</strong>
									</Typography>
								</>
							)}
							{result?.data?.globalPhysicalHealth && (
								<>
									<br />
									<Typography variant="h2" sx={{ fontSize: '15px', marginRight: '2px' }}>
										Global Physical Health:
									</Typography>
									<Typography
										sx={{
											wordWrap: 'break-word',
											overflowWrap: 'break-word',
											hyphens: 'auto',
										}}
									>
										T-score: <strong>{result?.data?.globalPhysicalHealth.tscore}</strong> &nbsp; SE*:{' '}
										<strong>{result?.data?.globalPhysicalHealth.standardError}</strong>
									</Typography>
								</>
							)}
						</Box>
					</Grid>
					{isSuccess &&
						data &&
						data.length > 0 &&
						data
							.filter((items) => items?.type != 'panel')
							.map((item, index) => (
								<Grid item md={12} xs={12}>
									<Divider />
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'flex-start',
											alignItems: 'center',
										}}
									>
										<Box
											sx={{
												marginRight: '20px',
												marginBottom: '12px',
												padding: '6px',
											}}
										>
											<Typography variant="h2" sx={{ fontSize: '16px' }}>
												Q{index + 1}
											</Typography>
										</Box>
										<Box sx={{ marginTop: '20px' }}>
											<Typography variant="h2" sx={{ fontSize: '14px' }}>
												{item?.title}
											</Typography>
											<Typography sx={{ marginTop: '10px', fontSize: '14px' }}>
												{Array.isArray(item?.result)
													? item?.result.map((val, i) =>
															item.type == 'checkbox' ? (
																<Grid item md={12} xs={12}>
																	<Divider />
																	<Box
																		sx={{
																			display: 'flex',
																			justifyContent: 'flex-start',
																			alignItems: 'center',
																		}}
																	>
																		<Box
																			sx={{
																				marginRight: '20px',
																				marginBottom: '12px',
																				padding: '6px',
																			}}
																		></Box>
																		<Box sx={{ marginTop: '20px' }}>
																			<Typography sx={{ marginTop: '10px', fontSize: '14px' }}>
																				{i + 1}. {val}
																			</Typography>
																		</Box>
																	</Box>
																</Grid>
															) : (
																Object.keys(val).map((key, indexes) => (
																	<Grid item md={12} xs={12}>
																		<Divider />
																		<Box
																			sx={{
																				display: 'flex',
																				justifyContent: 'flex-start',
																				alignItems: 'center',
																			}}
																		>
																			<Box
																				sx={{
																					marginRight: '20px',
																					marginBottom: '12px',
																					padding: '6px',
																				}}
																			>
																				<Typography variant="h2" sx={{ fontSize: '16px' }}>
																					Q{indexes + 1}
																				</Typography>
																			</Box>
																			<Box sx={{ marginTop: '20px' }}>
																				<Typography variant="h2" sx={{ fontSize: '14px' }}>
																					{key}
																				</Typography>
																				<Typography sx={{ marginTop: '10px', fontSize: '14px' }}>{val[key]}</Typography>
																			</Box>
																		</Box>
																	</Grid>
																))
															)
													  )
													: renderResult(item)}
											</Typography>
										</Box>
									</Box>
								</Grid>
							))}
					<Grid item md={12} xs={12}>
						<Divider />
						<Box
							sx={{
								display: 'flex',
								flexDirection: isMobile ? 'column' : 'row',
								justifyContent: 'flex-end',
								alignItems: isMobile ? 'end' : 'center',
							}}
						>
							<Box
								sx={{
									marginTop: '20px',
									marginBottom: '20px',
									marginRight: '10px',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'start',
										alignItems: 'center',
									}}
								>
									<Typography variant="h2" sx={{ fontSize: '14px', marginRight: '5px' }}>
										Date Filled :
									</Typography>
									<Typography sx={{ fontSize: '14px' }}>{formattedDate}</Typography>
								</Box>
							</Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'start',
									alignItems: 'center',
									marginLeft: '5px',
								}}
							>
								<Typography variant="h2" sx={{ fontSize: '14px', marginRight: '5px' }}>
									Time Filled :
								</Typography>
								<Typography sx={{ fontSize: '14px' }}>{formattedTime}</Typography>
							</Box>
						</Box>
						<Divider />
					</Grid>
				</Grid>
				<Stack direction="row" justifyContent={'flex-end'} sx={{ marginTop: '3rem' }}>
					<SolidButton
						label="Close"
						onClick={(e) => {
							toggleDrawer(false)
						}}
					/>
				</Stack>
			</Box>
		</Box>
	)
}

export function DetailPageDrawer({ state, toggleDrawer, pendingurl }) {
	const isMobile = useIsMobile()
	return (
		<React.Fragment key={'anchor'}>
			<Drawer
				sx={{
					display: { sm: 'block' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						overflowX: 'hidden',
						width: !isMobile ? '45%' : '80%',
					},
				}}
				anchor="right"
				open={state}
				onClose={toggleDrawer(false)}
			>
				<Content toggleDrawer={toggleDrawer(false)} pendingurl={pendingurl} />
			</Drawer>
		</React.Fragment>
	)
}
