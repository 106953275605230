import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { DateField } from "../../../Components";
import OutlinedButton from "../../../Components/OutlinedButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useIsMobile } from "../../../contexts/isMobile";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useSelector } from "react-redux";
import { useGetTagsQuery } from "../../../store/tagsSlice";
import { languages } from "../utils";
import { statesData } from "../../../Components/Data/StateData";
import { careProgramsData } from "../../../careProgramUtils";
import { useGetAllTeamsQuery } from "../../../store/teamsSlice";
import { useGetPhysiciansOptionsQuery } from "../../../store/physiciansSlice";
import { EthincityData, RaceData } from "../patientUtils";
import { toast } from "react-toastify";

const othersData = [
  { label: "Non Binary", value: "nonBinary" },
  { label: "Transgender", value: "transGender" },
  { label: "Queer", value: "queer" },
  { label: "Prefer Not To Say", value: "notToSay" },
];


export default function DemographicsData({ form,selectedEthnicity,setSelectedEthnicity,selectedRace,setSelectedRace, onChange, setForm,selectedPhysician, setSelectedPhysician,physician,setPhysician, selectedProgramTags, setSelectedProgramTags, isValidNumber, setIsValidNumber, birthday, setBirthday, language, setLanguage,state, setState, team, setTeam, selectedTeam, setSelectedTeam, programTags, setProgramTags, selectedTags, setSelectedTags, mrn,setMrn }) {
  const { user } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const isMobile = useIsMobile();

const handlePhysicianChange=(event,newValue)=>{
  setSelectedPhysician(newValue)
  setPhysician(newValue?._id)
}
  const {
    data: TagsData,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useGetTagsQuery({ userID: user.id });

  const {
    data: physicianOptions,
    isLoading: isPhysicianLoading
  } = useGetPhysiciansOptionsQuery()

  const { data:TeamsData, isLoading: isTeamLoading } = useGetAllTeamsQuery(user.id)

  const handleClick = (event) => {
   
    setAnchorEl(event.currentTarget);
    setForm((prevForm) => ({ ...prevForm, gender: "other" }));
  };

  const handleChange = (newValue) => {
    matchIsValidTel(newValue); // boolean
    setIsValidNumber(matchIsValidTel(newValue));
  };

  const handleMrnChange = (e) => {
    const mrnValue = e.target.value 

    if(mrnValue?.length > 20){
      toast.warning('MRN must not exceed 20 words')
      return
    }
    
    setMrn(mrnValue)

  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setForm((prevForm) => ({ ...prevForm, gender: option }));
    handleClose();
  };

  const handleTagChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleStateChange = (event,newValue) => {
    // console.log(newValue)
    setState(newValue);
  };

  
  const handleProgramChange = (event,newValue) => {
    // Extract values from newValue array of objects
    const values = newValue.map(item => item.value);

    setSelectedProgramTags(// On autofill we get a stringified value.
    typeof newValue === "string" ? newValue?.split(",") : newValue)

    setProgramTags(values);
  };

  useEffect(()=>{
    if(team && TeamsData){
      const teamObject = TeamsData?.data?.find(item => item?._id === team)
      setSelectedTeam(teamObject?.name || "");
    }
  },[TeamsData])

  // if(isPhysicianLoading) return <>{console.log('Loading...')}</>

  return (
    <div id="demographics">
      <Paper
        elevation={2}
        sx={{ borderRadius: "10px", p: isMobile ? 1 : 2, mb: 3 }}
      >
        <Typography fontSize={"18px"} fontWeight={"bolder"}>
          Demographics
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent={"left"}
          alignItems={"center"}
        >
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              First Name <span style={{color:'red'}}>*</span>
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="firstName"
              value={form.firstName}
              onChange={onChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Last Name <span style={{color:'red'}}>*</span>
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="lastName"
              value={form.lastName}
              onChange={onChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <DateField
              date={birthday}
              setDate={setBirthday}
              label={"Birth Date"}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Gender
            </Typography>
            <Button
              variant={form.gender === "male" ? "contained" : "outlined"}
              sx={{
                borderColor: "#424242",
                color: form.gender === "male" ? "white" : "#424242",
                bgcolor: form.gender === "male" && "#424242",
                textTransform: "capitalize",
                fontSize:"1rem"
              }}
              onClick={(e) =>
                setForm((prevForm) => ({ ...prevForm, gender: "male" }))
              }
            >
              Male
            </Button>
            <Button
              variant={form.gender === "female" ? "contained" : "outlined"}
              sx={{
                borderColor: "#424242",
                mx: 2,
                color: form.gender === "female" ? "white" : "#424242",
                bgcolor: form.gender === "female" && "#424242",
                textTransform: "capitalize",
                fontSize:"1rem"
              }}
              onClick={(e) =>
                setForm((prevForm) => ({ ...prevForm, gender: "female" }))
              }
            >
              Female
            </Button>
            {/* <Button
              variant={form.gender === "other" ? "contained" : "outlined"}
              sx={{
                borderColor: "#424242",
                color: form.gender === "other" ? "white" : "#424242",
                bgcolor: form.gender === "other" && "#424242",
                textTransform: "capitalize",
              }}
              onClick={(e) => handleClick(e)}
            >
              Other
              <ArrowDropDownIcon />
            </Button> */}

            {/* <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {othersData.map((item) => (
                <MenuItem
                  key={item.value}
                  onClick={() => handleOptionSelect(item.value)}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu> */}
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} mt={2} color="#979797">
              Race
            </Typography>
            <Autocomplete
              value={selectedRace}
              onChange={(event, newValue) => {
                setSelectedRace(newValue);
              }}
              options={RaceData?.map((item)=>item.label)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} mt={2} color="#979797">
              Ethnicity
            </Typography>
            <Autocomplete
              value={selectedEthnicity}
              onChange={(event, newValue) => {
                setSelectedEthnicity(newValue);
              }}
              options={EthincityData?.map((item)=>item.label)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} mt={2} color="#979797">
              Language
            </Typography>
            <Autocomplete
              value={language}
              onChange={(event, newValue) => {
                setLanguage(newValue);
              }}
              options={languages?.map((item)=>item.label)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>
          {/* <Grid item md={4} xs={12}>
            <Typography fontSize={12} mt={2}>
              Tags
            </Typography>
            <Autocomplete
              value={selectedTags||[]}
              onChange={handleTagChange
              }
              options= {TagsData?.data?.map((item) => item.tag)}
              multiple
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid> */}
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Physician
            </Typography>
            <Autocomplete
              value={selectedPhysician}
              onChange={(e,newValue)=>handlePhysicianChange(e,newValue)}
              options={!isPhysicianLoading && Array.isArray(physicianOptions) ? physicianOptions : []}
              getOptionLabel={(option) => option?.name} 
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              MRN
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="mrn"
              value={mrn}
              onChange={(e)=>handleMrnChange(e)}
            />
          </Grid>
          {/* <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              SSN
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="ssn"
              //   value={originalMessage}
              // onChange={onChange}
            />
          </Grid> */}
          <Grid item md={8} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Patient Summary
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="patientSummary"
              value={form.patientSummary}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Typography fontSize={"18px"} fontWeight={"bolder"} mt={3}>
          Address Details
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item md={12} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Address Line 1
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="address1"
              value={form.address1}
              onChange={onChange}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Address Line 2
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="address2"
              value={form.address2}
              onChange={onChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              City
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="city"
              value={form.city}
              onChange={onChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              State
            </Typography>
            <Autocomplete
              value={state}
              onChange={(e,newValue)=>handleStateChange(e,newValue)}
              options={statesData?.map((item)=>item.label)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              ZIP Code
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="zip"
              value={form.zip}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Typography fontSize={"18px"} fontWeight={"bolder"} mt={3}>
          Contact Information
        </Typography>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Email
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="email"
              value={form.email}
              onChange={onChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Phone <span style={{color:'red'}}>*</span>
            </Typography>
            <MuiTelInput
              required
              id="standard"
              variant="standard"
              fullWidth
              name="phone"
              defaultCountry="US"
              onlyCountries={["US"]}
              error={isValidNumber === false}
              helperText={isValidNumber === false && "Incorrect entry."}
              value={form.phone}
              onChange={(newValue) => {
                const event = { target: { name: "phone", value: newValue } };
                onChange(event);
                handleChange(newValue);
              }}
              sx={{
                padding: "3px",
                "& .MuiInputLabel-root": {
                  padding: "3px",
                },
              }}
            />
            {/* <TextField
              variant="standard"
              fullWidth
              name="phone"
              value={form.phone}
              onChange={onChange}
            /> */}
          </Grid>
        </Grid>
        <Typography fontSize={"18px"} fontWeight={"bolder"} mt={3}>
          Care Program
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item md={4} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Care Programs
            </Typography>
            <Autocomplete
              value={selectedProgramTags || []}
              onChange={(e,newValue)=>handleProgramChange(e,newValue)}
              multiple
              options={careProgramsData}
              getOptionLabel={(option) => option.label} 
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>

          <Grid item md={8} xs={12}>
            <Typography fontSize={"1rem"} color={"#979797"} mt={2}>
              Team
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Autocomplete
                value={selectedTeam}
                onChange={(event, newValue) => {
                  const filteredTeam = TeamsData?.data?.find(item => item.name === newValue);
                  setSelectedTeam(newValue)
                  setTeam(filteredTeam?._id);
                }}
                options={!isTeamLoading && TeamsData?.data?.map((item) =>item.name)}
                sx={{ mx: 1, width: isMobile ? "50%" : "80%" }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
              {/* <OutlinedButton label="+ Program" onClick={""} /> */}
            </div>
          </Grid>
        </Grid>
       {/*  <Typography fontSize={"18px"} fontWeight={"bolder"} mt={3}>
          Care Ally
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Typography fontSize={12} mt={2}>
              Care Ally
            </Typography>
            <Autocomplete
              // value={selectPhysician}
              // onChange={(event, newValue) => {
              //   setSelectedPhysician(newValue);
              // }}
              // options={data.map((item)=>item.name)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography fontSize={12} mt={2}>
              Relationship
            </Typography>
            <Autocomplete
              // value={selectSpeciality}
              // onChange={(event, newValue) => {
              //   setSelectSpeciality(newValue);
              // }}
              // options={data.map((item)=>item.name)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Typography mx={4}>
                <Checkbox />
                Consent
              </Typography>
              <OutlinedButton label=" + Care Ally" onClick={""} />
            </div>
          </Grid>
        </Grid> */}
      </Paper>
    </div>
  );
}
