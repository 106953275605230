import { Grid, Typography, Box, Paper } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'

const DoughnutChart = ({chartData}) => {
	// Data for doughnut
	const data = {
		labels: chartData?.map(item => item.label || 'N/A'),
		datasets: [
			{
				data: chartData?.map(item => item.count || 0),
				borderColor: ['#FFFFFF'],
				backgroundColor: ['#75D3C8', '#FFB546', '#EF5F5F'],
				pointBackgroundColor: 'rgba(255,206,86,0.2)',
			},
		],
	}

	// Options for doughnut
	const options = {
		plugins: {
			title: {
				display: true,
				font: {
					size: 16,
					weight: 'bold',
				},
				padding: {
					bottom: 20,
				},
				align: 'start',
			},
			legend: {
				display: true,
				position: 'right',
				align: 'end',
				labels: {
					boxWidth: 10,
					padding: 10,
					usePointStyle: true,
					generateLabels: (chart) => {
						//For Showing Percentage in front of labels
						const dataset = chart?.data?.datasets[0]
						const total = dataset?.data?.reduce((sum, value) => sum + value, 0)

						return chart?.data?.labels?.map((label, index) => {
							const value = dataset?.data[index]
							const percentage = Math?.floor((value / total) * 100)
							const capitalizedLabel = label?.charAt(0)?.toUpperCase() + label?.slice(1);

							return {
								text: `${capitalizedLabel} (${percentage}%)`,
								fillStyle: dataset?.backgroundColor[index],
								lineWidth: 1,
								hidden: chart?.getDatasetMeta(0)?.data[index]?.hidden,
								index: index,
							}
						})
					},
				},
			},
			datalabels: {
				color: (ctx) => {
					const value = ctx?.dataset?.data[ctx?.dataIndex]
					return value < 100 ? 'black' : 'white' // Customize color based on value
				},
				formatter: (value, ctx) => {
					const dataset = ctx?.chart?.data?.datasets[ctx?.datasetIndex]
					const total = dataset?.data?.reduce((sum, current) => sum + current, 0)
					const percentage = Math?.floor((value * 100) / total)
					return percentage > 0 ? `${percentage}%` : '' // Display percentage if > 0
				},
				font: {
					size: 12,
					weight: 'bold',
				},
				display: false, // Set to true if you want labels inside the doughnut segments
			},
			afterDraw: (chart) => {
				if (chart?.data?.datasets[0]?.data?.length < 1) {
					const ctx = chart?.ctx
					const width = chart?.width
					const height = chart?.height
					ctx.textAlign = 'center'
					ctx.textBaseline = 'middle'
					ctx.font = '30px Arial'
					ctx.fillText('No data to display', width / 2, height / 2)
					ctx.restore()
				}
			},
		},
		maintainAspectRatio: false, // Disable aspect ratio to control size manually
		responsive: true,
		onHover: (event, chartElement) => {
			event.native.target.style.cursor = chartElement?.length ? 'pointer' : 'default'
		},
	}

	return (
		<div style={{ width: '100%', height: '130px' }}>
			<Doughnut data={data} options={options} />
		</div>
	)
}

const StatCard = ({ title, value, isGraph, data }) => {
	return (
		<Paper
			sx={{
				background: '#fff',
				margin: '10px',
				padding: '12px',
				borderRadius: '20px',
				height: '93%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				boxShadow: '0px 0px 15px rgba(52, 58, 64, 0.2)',
				border: '1px solid #D0EAE1',
			}}
		>
			<Box>
				<Typography sx={{ fontSize: '18px', color: '#4B4D4F', fontWeight: 400 }}>{title}</Typography>
				<Typography sx={{ fontSize: '26px', fontWeight: 600, color: '#05080B' }}>{value}</Typography>
			</Box>
			{isGraph ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<DoughnutChart chartData={data} />
				</Box>
			) : (
				<Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', overflow: 'hidden' }}>
					{[
						{ label: 'Low', value: 20, bg: '#A2F2EE' },
						{ label: 'Medium', value: 40, bg: 'rgba(255, 181, 70, 0.40)' },
						{ label: 'High', value: 40, bg: '#FFE1E1' },
					].map((item, index) => (
						<p
							style={{
								background: item?.bg,
								padding: '5px',
								margin: '4px',
								textAlign: 'center',
								flex: 1,
								boxSizing: 'border-box',
								borderRadius: '5px',
								fontWeight: 500,
								fontSize: '14px',
								color: '#05080B',
							}}
							key={index}
						>
							{item?.label}:{item?.value}
						</p>
					))}
				</Box>
			)}
		</Paper>
	)
}

export function RightSide({data}) {
	return (
		<>
			<Grid container spacing={0.5}>
				<Grid item xs={4}>
					<StatCard title="Active Triage Needs" value={data?.activeTriageNeeds[0]?.totalCount} data={data?.activeTriageNeeds[0]?.acuityBreakdown} isGraph={true} />
				</Grid>

				{/* <Grid item xs={3}>
					<StatCard title="Tasks By Acuity" value="120" isGraph={false} />
				</Grid> */}

				<Grid item xs={4}>
					<StatCard title="Surgeon's Patients" value={data?.surgeonPatientCounts[0]?.totalCount} data={data?.surgeonPatientCounts[0]?.surgeonBreakdown} isGraph={true} />
				</Grid>

				<Grid item xs={4}>
					<StatCard title="Outstanding Inquiries" value={data?.outstandingInquires[0]?.totalCount} data={data?.outstandingInquires[0]?.categoryBreakdown} isGraph={true} />
				</Grid>
			</Grid>
		</>
	)
}
