import React, { useState } from "react";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import OutlinedButton from "../OutlinedButton";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import SnackbarMsg from "../SnackbarMsg";
import WhatwentwrongDialog from "../../Pages/patientQueue/Profile/WhatwentwrongDialog";
import axios from "axios";
import { toast } from "react-toastify";
import { getTokenFromLocalStroage } from "../../utils";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import moment from "moment";

export default function InterventionTab({
  refetchAgain,
  queueStatus,
  isDrawer,
  queueId,
  originalMessage,
  chatbotReply,
  patient,
  reasoning,
  createdAt,
  userFeedback,
  patientDrawer,
  acuity,
  taskCategory,
  queueData,
}) {
  const [expanded, setExpanded] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [chatbotResponseCritique, setChatbotResponseCritique] = useState("");
  const [userFeedback2, setUserFeedback2] = useState([]);
  const [selectedAcuity, setSelectedAcuity] = useState("");
  const [proposedChatbotResponse, setProposedChatbotResponse] = useState("");
  const [selectedTaskLabel, setSelectedTaskLabel] = useState("");
  const [currentItemId, setCurrentItemId] = useState("");
  const [openDislikeModal, setOpenDislikeModal] = useState(false);
  const [isTaskCategorySelected, setIsTaskCategorySelected] = useState(true);
  const [isChatbotResponse, setIsChatbotResponse] = useState(true);
  const [isAcuitySelected, setIsAcuitySelected] = useState(true);
  const [itemStatus, setItemStatus] = useState(queueStatus);
  const [chatbotResponse, setChatbotResponse] = useState(null);

  const handleExpand = () => {
    setExpanded(!expanded);
  };
  const updateStatus = async () => {
    const newStatus = itemStatus === "pending" ? "resolved" : "pending";

    await axios
      .put(
        `${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatus/${queueId}`,
        { status: newStatus },
        config
      )
      .then((res) => {
        const status = res.data.status;
        toast.success("The issue is " + status);
        setItemStatus(status);
        if (refetchAgain) {
          refetchAgain();
        }
      })
      .catch((error) => {
        toast.error("Error:", error);
      });
  };
  const handleNotify = () => {};

  const addReaction = async (queueID, status) => {
    if (status === "dislike") {
      if (!selectedAcuity || !selectedTaskLabel || !chatbotResponseCritique) {
        toast.error("Please fill out the form in-order to submit the feedback");
        return;
      }
    }

    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/addReaction/${queueID}`,
          {
            status: status,
            userId: user.id,
            correctTaskLabel: selectedTaskLabel,
            correctAcuityLabel: selectedAcuity,
            proposedChatbotResponse: proposedChatbotResponse,
            chatbotResponseCritique: chatbotResponseCritique,
          },
          config
        )
        .then((res) => {
          setOpen(true);
          // if(patientDrawer){
          // 	setUserFeedback2([res?.data?.feedback])
          // }
        });
    } catch (error) {
      toast.error("Error:" + error);
    }
    setCurrentItemId("");
    setSelectedTaskLabel("");
    setSelectedAcuity("");
    setProposedChatbotResponse("");
    setChatbotResponseCritique("");
    setIsAcuitySelected(false);
    setIsChatbotResponse(false);
    setIsTaskCategorySelected(false);
    setOpenDislikeModal(false);
    if (refetchAgain) {
      refetchAgain();
    }
  };

  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };
  const setCurrentItem = async (itemId) => {
    setOpenDislikeModal(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function renderFeedbackIcons(itemId, userFeedbackData) {
    const iconSkeleton = {
      color: "#979797",
      cursor: "pointer",
    };

    const dislikeIcon = {
      cursor: "pointer",
      color: "red",
    };

    const feedbackIcons = {
      like: (
        <EmojiEmotionsOutlinedIcon
          fontSize="medium"
          style={{ color: "green" }}
        />
      ),
      dislike: (
        <SentimentDissatisfiedOutlinedIcon
          fontSize="medium"
          style={dislikeIcon}
          onClick={() => setCurrentItem(itemId)}
        />
      ),
    };

    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "start" }}
      >
        <>
          {userFeedbackData?.some((item) => item?.status === "like") &&
            feedbackIcons.like}
          {!userFeedbackData?.some((item) => item?.status === "like") && (
            <EmojiEmotionsOutlinedIcon
              fontSize="medium"
              onClick={() => addReaction(queueId, "like")}
              style={iconSkeleton}
            />
          )}
          &ensp;
        </>
        {userFeedbackData?.some((item) => item?.status === "dislike") &&
          feedbackIcons.dislike}
        {!userFeedbackData?.some((item) => item?.status === "dislike") && (
          <SentimentDissatisfiedOutlinedIcon
            fontSize="medium"
            onClick={() => setCurrentItem(itemId)}
            style={iconSkeleton}
          />
        )}
      </div>
    );
  }

  React.useEffect(() => {
    setProposedChatbotResponse(
      userFeedback?.proposedChatbotResponse
        ? userFeedback?.proposedChatbotResponse
        : userFeedback?.chatbotReply
    );
    setChatbotResponse(chatbotReply);
    setChatbotResponseCritique(userFeedback?.chatbotResponseCritique);
    setSelectedTaskLabel(taskCategory);
    setSelectedAcuity(acuity);

    setCurrentItemId(queueId);
  }, [userFeedback, queueId]);

  return (
    <>
      <div
        style={{
          overflowY: "scroll",
          paddingBottom: isDrawer ? "50px" : "100px",
          height: isDrawer ? "70vh" : "60vh",
          paddingLeft: 10,
          paddingRight: 10,
        }}
        className="DialogContent"
      >
        {/* <Typography sx={{ fontSize: "16px", fontWeight: 600, mb: 1 }}>
          Issue
        </Typography> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              <strong>Care Program</strong>
            </Typography>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  position: "relative",
                  borderRadius: "10px",
                  overflow: "hidden",
                  marginRight: "5px",
                }}
              >
                <Chip
                  label={
                    queueData.patient.program.length > 0
                      ? queueData.patient.program[0]
                          .replace(/_/g, " ")
                          .split(/\s+/)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : "No Care Program Assigned"
                  }
                  size="medium"
                  sx={{
                    marginTop: "-0.2em",
                    color: "black",
                    backgroundColor:
                      queueData.patient.program.length > 0
                        ? "#e5feec"
                        : "rgba(255, 31, 0, 0.08)",
                    borderRadius: "8px",
                    position: "relative",
                  }}
                />
                <LinearProgress
                  variant="determinate"
                  value={
                    queueData.patient.program.length > 0
                      ? 100 / queueData.patient.program.length
                      : 0
                  }
                  sx={{
                    position: "absolute",
                    bottom: "4px",
                    left: "2px",
                    width: "calc(100% - 4px)",
                    borderRadius: "10px",
                    height: "3.5px",
                    zIndex: 0,
                    backgroundColor:
                      queueData.patient.program.length > 0
                        ? "#8fdaa5"
                        : "#D3602F",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#208b40", // Desired color
                    },
                  }}
                />
              </div>
              <Tooltip
                title={
                  queueData.patient.program.length > 1
                    ? queueData.patient.program
                        .slice(1) // Exclude the first index
                        .map((word) =>
                          word
                            .replace(/_/g, " ")
                            .split(/\s+/)
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")
                        )
                        .join(", ")
                    : "No additional data"
                }
              >
                <Chip
                  label={
                    "+" +
                    (queueData.patient.program.length > 0
                      ? queueData.patient.program.length - 1
                      : 0)
                  }
                  sx={{ borderRadius: "10px" }}
                />
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Message Grading</strong>
            </Typography>
            <div>
              {renderFeedbackIcons(
                queueData?._id,
                queueData.userFeedback,
                queueData?.originalMessage,
                queueData
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Patient Summary</strong>
            </Typography>
            <Typography>
              {queueData.patient.patientSummary
                ? queueData.patient.patientSummary
                : "N/A"}
            </Typography>
          </Grid>

          {queueData.originalMessage && (
            <Grid item xs={12}>
              <Typography>
                <strong>Patient Message</strong>
              </Typography>
              <Alert
                severity="info"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: (props) => (
                      <a
                        href={props.href}
                        style={{ color: "#000" }}
                        target="blank"
                      >
                        {props.children}
                      </a>
                    ),
                  }}
                >
                  {queueData.originalMessage}
                </ReactMarkdown>
              </Alert>
            </Grid>
          )}
          {queueData.chatbotReply && (
            <Grid item xs={12}>
              <Typography>
                <strong>Chatbot Reply</strong>
              </Typography>
              <Alert
                severity="success"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: (props) => (
                      <a
                        href={props.href}
                        style={{ color: "#000" }}
                        target="blank"
                      >
                        {props.children}
                      </a>
                    ),
                  }}
                >
                  {queueData.chatbotReply}
                </ReactMarkdown>
              </Alert>
            </Grid>
          )}
          {queueData?.reasoning?.length !== 0 && (
            <Grid item xs={12}>
              <Typography>
                <strong>Prediction Reasoning</strong>
              </Typography>
              {queueData.reasoning.map((value, i) => (
                <Grid item xs={12} key={i}>
                  <Alert severity="warning">{value}</Alert>
                  <br />
                </Grid>
              ))}
            </Grid>
          )}

          {/* <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box sx={{ padding: "12px" }}>
              <OutlinedButton
                label={
                  itemStatus === "resolved"
                    ? "Mark as Pending"
                    : "Mark as Resolved"
                }
                handleClick={() => updateStatus()}
              />
            </Box>
          </Grid> */}
        </Grid>
      </div>
      <SnackbarMsg
        msg={"Thank you for giving us feedback!"}
        handleClose={handleClose}
        open={open}
        width={"310px"}
      />
      <WhatwentwrongDialog
        open={openDislikeModal}
        setOpen={setOpenDislikeModal}
        addReaction={addReaction}
        queueId={queueId}
        originalMessage={originalMessage}
        isAcuitySelected={isAcuitySelected}
        setIsAcuitySelected={setIsAcuitySelected}
        isChatbotResponse={isChatbotResponse}
        setIsChatbotResponse={setIsChatbotResponse}
        isTaskCategorySelected={isTaskCategorySelected}
        setIsTaskCategorySelected={setIsTaskCategorySelected}
        proposedChatbotResponse={proposedChatbotResponse}
        setProposedChatbotResponse={setProposedChatbotResponse}
        chatbotResponseCritique={chatbotResponseCritique}
        setChatbotResponseCritique={setChatbotResponseCritique}
        selectedAcuity={selectedAcuity}
        setSelectedAcuity={setSelectedAcuity}
        selectedTaskLabel={selectedTaskLabel}
        setSelectedTaskLabel={setSelectedTaskLabel}
        chatbotResponse={chatbotResponse}
      />
    </>
  );
}
