import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from "@mui/material";

function CallAccordion({title, data, defaultExpanded}) {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel1-${title}`}
          id={`panel1-${title}`}
          sx={{background:'#f1f3f4', fontWeight: 600}}
        >
          View {title}
        </AccordionSummary>
        <AccordionDetails sx={{background:'#f1f3f4'}}>
          {title === "Transcript"  ? (
            <>
            { data && data.length !== 0 ? data?.map((item, index) => (
            <Box key={index} sx={{display:'flex', alignItems: "center"}}>
            <Typography>
                <span style={{ fontSize: "15px", marginRight: "2px", fontWeight:800 }}>{item?.role === "user" ? "User :" : "Agent :"}</span> {item?.content}
              </Typography>
            </Box>
            )):(
              <Box sx={{display:'flex', alignItems: "center"}}>
              <Typography>
                  No Transcript To Show
                </Typography>
              </Box>
            )}
            </>
          ) : <>{data}</>}
        </AccordionDetails>
      </Accordion>
  );
}

export default CallAccordion;
