import React, { useEffect, useState } from 'react'
import ReactFlow, { Background, Edge, Node, ProOptions, ReactFlowProvider, MiniMap, Controls } from 'reactflow'

import useLayout from './hooks/useLayout'
import nodeTypes from './NodeTypes'
import edgeTypes from './EdgeTypes'

import 'reactflow/dist/style.css'
import { Box, Typography } from '@mui/material'
import { AddButtonFlow, CircularLoader } from '../../../Components'
import { AddDrawer } from '../Drawers'
import axios from 'axios'
import { useGetCallAgentsQuery } from "../../../store/callSlice";
import { useGetNoteTypesQuery } from "../../../store/notesSlice";

const proOptions = { account: 'paid-pro', hideAttribution: true }

function Workflow({journeyTitle , setJourneyTitle, journeyId, refetchTitle, setRefetchTitle,   isJourneyError, 
	setIsJourneyError}) {

	const {data: agentData, isLoading: isLoadingAgentData} = useGetCallAgentsQuery()
	const {data: noteData, isLoading: isLoadingNoteData} = useGetNoteTypesQuery()

	const nodeStyle = {
		borderColor: '#816179 !important',
		borderRadius: '8px',
		paddingTop: '15px',
		paddingBottom: '15px',
	}
	// const [label, setLabel] = useState("")
	// const [childNodeId, setChildNodeId] = useState("")
	// this hook call ensures that the layout is re-calculated every time the graph changes
	useLayout()
	const [defaultNodes, setNodes] = useState([
		{
		  id: "1",
		  data: { label: "Start Journey" },
		  position: { x: 0, y: 150 },
		  type: "workflow",
		  style: nodeStyle,
		  extra: "something",
		},
	  ]);
	  const [open, setOpen] = useState(true);
	  const [defaultEdges, setEdge] = useState([]);
	  const [isLoading, setIsLoading] = useState(true);


	const fitViewOptions = {
		padding: 0.95,
	}

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	  };

	const findAndFormatKey = (data, agentId) => {
		const key = Object.keys(data).find(key => data[key] === agentId);
	
		if (key) {
		  const formattedKey = key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
		  return formattedKey;
		} else {
		  return 'N/A';
		}
	  };

	  const triggerShowName = (triggerName) => {
		let name = triggerName;
		if (triggerName.length) {
		  let newNameArr1 = triggerName.split("_");
		  let firstPart = newNameArr1[0];
		  let secondPart = newNameArr1[1];
		  name =
			capitalizeFirstLetter(firstPart) +
			" " +
			capitalizeFirstLetter(secondPart);
		}
		return name;
	  };

	  // useEffects for axios
	  useEffect(() => {
		if(agentData && noteData)
		  if (journeyId) {
			axios
			  .get(`${process.env.REACT_APP_BASE_API_URL}/journeys/${journeyId}`)
			  .then((response) => {
				const data = response.data;
	  
				// Check if all journey-related data arrays are empty for Start Journey Node position
				const isJourneyEmpty =
				  data?.data?.form?.length === 0 &&
				  data?.data?.task?.length === 0 &&
				  data?.data?.assign_journey?.length === 0 &&
				  data?.data?.sms?.length === 0 &&
				  data?.data?.delay?.length === 0 && 
				  data?.data?.email?.length === 0
	  
				let newNode = [
				  {
					id: "1",
					data: { label: "Start Journey" },
					position: isJourneyEmpty ? { x: 165, y: 30 } : { x: 0, y: 150 },
					type: "workflow",
					style: nodeStyle,
					extra: "something",
				  },
				  ...data?.data?.task?.map((item) => {
					const id = item.childNodeId;
					const method = item.method;
					const label = item.task?.title;
					const taskId = item.task._id;
					const parentNodeId = item.parentNodeId;
					const isFilled = true;
					const prevNodeId = item?.prevNodeId;
					const nextNodeId = item?.nextNodeId;
					const title = item?.title;
					const skippable = item?.skippable 
	  
					return {
					  id,
					  data: {
						label,
						method,
						extra: taskId,
						parentNodeId: parentNodeId,
						isFilled,
						nextNodeId,
						prevNodeId,
						title,
						skippable
					  },
					  position: { x: 0, y: 0 },
					  type: "workflow",
					};
				  }),
				  ...data?.data?.assign_journey?.map((item) => {
					const id = item.childNodeId;
					const method = item.method;
					const triggerId = item._id;
					const parentNodeId = item.parentNodeId;
					const journeyId = item.journeyId;
					const journeyName = item.journeyName;
					let label = journeyName;
					const isFilled = true;
					const prevNodeId = item?.prevNodeId;
					const nextNodeId = item?.nextNodeId;
					const title = item?.title;
					const skippable = item?.skippable 
	  
					return {
					  id,
					  data: {
						label,
						method,
						extra: triggerId,
						parentNodeId: parentNodeId,
						journeyId,
						journeyName,
						isFilled,
						prevNodeId,
						nextNodeId,
						title,
						skippable
					  },
					  position: { x: 0, y: 0 },
					  type: "workflow",
					};
				  }),
				  ...data?.data?.sms?.map((item) => {
					const id = item.childNodeId;
					const label = item.sms;
					const method = item.method;
					const parentNodeId = item.parentNodeId;
					const isFilled = true;
					const prevNodeId = item?.prevNodeId;
					const nextNodeId = item?.nextNodeId;
					const title = item?.title;
					const skippable = item?.skippable 
	  
					return {
					  id,
					  data: {
						label,
						method,
						parentNodeId: parentNodeId,
						isFilled,
						prevNodeId,
						nextNodeId,
						title,
						skippable
					  },
					  position: { x: 0, y: 0 },
					  type: "workflow",
					};
				  }),
				  ...data?.data?.delay?.map((item) => {
					const id = item.childNodeId;
					const label = item.delay;
					const method = item.method;
					const parentNodeId = item.parentNodeId;
					const metric = item?.metric;
					const value = item?.value;
					const prevNodeId = item?.prevNodeId;
					const nextNodeId = item?.nextNodeId;
					const delayFor = item?.delayFor || "N/A";
					const timeRelation = item?.timeRelation || "before";
					const hourToRunAt = item?.hourToRunAt || {};
					const runAtSpecificHour = item?.runAtSpecificHour || false;
	  
					return {
					  id,
					  data: {
						label,
						value,
						method,
						metric,
						parentNodeId: parentNodeId,
						prevNodeId,
						nextNodeId,
						delayFor,
						timeRelation,
						hourToRunAt,
						runAtSpecificHour
					  },
					  position: { x: 0, y: 0 },
					  type: "workflow",
					};
				  }),
				  ...data?.data?.deactivate_patient?.map((item) => {
					const id = item.childNodeId;
					const method = item.method;
					const label = "Deactivate Patient";
					const parentNodeId = item.parentNodeId;
					const isFilled = true;
					const prevNodeId = item?.prevNodeId;
					const nextNodeId = item?.nextNodeId;
					const title = item?.title;
					const skippable = item?.skippable 
	  
					return {
					  id,
					  data: {
						label,
						method,
						parentNodeId: parentNodeId,
						isFilled,
						nextNodeId,
						prevNodeId,
						title,
						skippable
					  },
					  position: { x: 0, y: 0 },
					  type: "workflow",
					};
				  }),
				  ...data?.data?.note?.map((item) => {
					const id = item.childNodeId;
					const method = item.method;
					const parentNodeId = item.parentNodeId;
					const isFilled = true;
					const prevNodeId = item?.prevNodeId;
					const nextNodeId = item?.nextNodeId;
					const title = item?.title;
					const noteType = item?.noteType
					const label = Object.keys(noteData?.data).find(key => noteData?.data[key] === item?.noteType)
					const skippable = item?.skippable 
	  
					return {
					  id,
					  data: {
						label,
						method,
						parentNodeId: parentNodeId,
						isFilled,
						prevNodeId,
						nextNodeId,
						title,
						noteType,
						skippable
					  },
					  position: { x: 0, y: 0 },
					  type: "workflow",
					};
				  }),
				  ...data?.data?.email?.map((item) => {
					const id = item.childNodeId;
					const method = item.method;
					const parentNodeId = item.parentNodeId;
					const isFilled = true;
					const prevNodeId = item?.prevNodeId;
					const nextNodeId = item?.nextNodeId;
					const title = item?.title;
					const subject = item?.subject;
					const body = item?.body;
					const label = item?.subject;
					const skippable = item?.skippable 
	  
					return {
					  id,
					  data: {
						label,
						subject,
						body,
						method,
						parentNodeId: parentNodeId,
						isFilled,
						prevNodeId,
						nextNodeId,
						title,
						skippable
					  },
					  position: { x: 0, y: 0 },
					  type: "workflow",
					};
				  }),
				  ...data?.data?.form?.map((item) => {
					const message = `Dear Patient,\nWe value you and would appreciate it if you could take a moment to complete a short survey.\nPlease click on the following link to access the survey:\n{{ surveyLink }}`
					const pages = item?.form?.form?.pages;
					const id = item?.childNodeId;
					const positiveChildNodeId = item?.positiveChildNodeId;
					const negativeChildNodeId = item?.negativeChildNodeId;
					const method = item?.method;
					const checkCondition = item?.checkCondition;
					const surveyValues = item?.surveyValues;
					const conditionOperator = item?.conditionOperator;
					const label = item?.form?.form?.title;
					const formId = item?.form?._id;
					const parentNodeId = item?.parentNodeId;
					const metric = item?.metric;
					const value = item?.delayValue;
					const isFilled = true;
					const prevNodeId = item?.prevNodeId;
					const nextNodeId = item?.nextNodeId;
					const title = item?.title;
					const checkSubmission = item?.checkSubmission;
					const waitUntilReminderAreSend = item?.waitUntilReminderAreSend;
					const formSubmissionSmsReminder = item?.formSubmissionSmsReminder;
					const smsOnFormAssignment = item?.smsOnFormAssignment ? item?.smsOnFormAssignment : message
					const skippable = item?.skippable 
	  
					return {
					  id,
					  data: {
						positiveChildNodeId,
						negativeChildNodeId,
						checkCondition,
						label,
						method,
						extra: formId,
						parentNodeId: parentNodeId,
						surveyValues,
						conditionOperator,
						pages,
						metric,
						value,
						isFilled,
						nextNodeId,
						prevNodeId,
						title,
						checkSubmission,
						formSubmissionSmsReminder,
						waitUntilReminderAreSend,
						smsOnFormAssignment,
						skippable
					  },
					  position: { x: 0, y: 0 },
					  type: "workflow",
					};
				  }),
				  ...data?.data?.call?.map((item) => {
					const id = item.childNodeId;
					const method = item.method;
					const parentNodeId = item.parentNodeId;
					const isFilled = true;
					const prevNodeId = item?.prevNodeId;
					const nextNodeId = item?.nextNodeId;
					const title = item?.title;
					const positiveChildNodeId = item?.positiveChildNodeId
					const negativeChildNodeId = item?.negativeChildNodeId
					const agentId = item?.agentId
					// const callAttemptsUntilPhonePicked = item?.callAttemptsUntilPhonePicked
					const label = `Call from ${findAndFormatKey(agentData, item?.agentId)}`
					const skippable = item?.skippable 
	  
					return {
					  id,
					  data: {
						label,
						method,
						parentNodeId: parentNodeId,
						isFilled,
						prevNodeId,
						nextNodeId,
						title,
						positiveChildNodeId,
						negativeChildNodeId,
						agentId,
						skippable
						// callAttemptsUntilPhonePicked
					  },
					  position: { x: 0, y: 0 },
					  type: "workflow",
					};
				  }),
				];
				let newEdge = [
				  ...data?.data?.task?.map((item) => {
					const id = item.childNodeId;
					const pid = item.parentNodeId;
	  
					return {
					  id: `${pid}=>${id}`,
					  source: pid,
					  target: id,
					  type: "workflow",
					  // markerEnd: {
					  //   type: MarkerType.Arrow,
					  //   width: 20,
					  //   height: 20,
					  //   color: "#FF0072",
					  // },
					  // style: {
					  //   strokeWidth: 2,
					  //   stroke: '#FF0072',
					  // },
					};
	  
					// return  defaultEdges;
					// ;
				  }),
				  ...data?.data?.assign_journey?.map((item) => {
					const id = item.childNodeId;
					const pid = item.parentNodeId;
	  
					return {
					  id: `${pid}=>${id}`,
					  source: pid,
					  target: id,
					  type: "workflow",
					  // markerEnd: {
					  //   type: MarkerType.Arrow,
					  //   width: 20,
					  //   height: 20,
					  //   color: "#FF0072",
					  // },
					  // style: {
					  //   strokeWidth: 2,
					  //   stroke: '#FF0072',
					  // },
					};
				  }),
				  ...data?.data?.sms?.map((item) => {
					const id = item.childNodeId;
					const pid = item.parentNodeId;
	  
					return {
					  id: `${pid}=>${id}`,
					  source: pid,
					  target: id,
					  type: "workflow",
					  // markerEnd: {
					  //   type: MarkerType.Arrow,
					  //   width: 20,
					  //   height: 20,
					  //   color: "#FF0072",
					  // },
					  // style: {
					  //   strokeWidth: 2,
					  //   stroke: '#FF0072',
					  // },
					};
	  
					// return  defaultEdges;
					// ;
				  }),
				  ...data?.data?.delay?.map((item) => {
					const id = item.childNodeId;
					const pid = item.parentNodeId;
	  
					return {
					  id: `${pid}=>${id}`,
					  source: pid,
					  target: id,
					  type: "workflow",
					  // markerEnd: {
					  //   type: MarkerType.Arrow,
					  //   width: 20,
					  //   height: 20,
					  //   color: "#FF0072",
					  // },
					  // style: {
					  //   strokeWidth: 2,
					  //   stroke: '#FF0072',
					  // },
					};
	  
					// return  defaultEdges;
					// ;
				  }),
				  ...data?.data?.deactivate_patient?.map((item) => {
					const id = item.childNodeId;
					const pid = item.parentNodeId;
	  
					return {
					  id: `${pid}=>${id}`,
					  source: pid,
					  target: id,
					  type: "workflow",
					  // markerEnd: {
					  //   type: MarkerType.Arrow,
					  //   width: 20,
					  //   height: 20,
					  //   color: "#FF0072",
					  // },
					  // style: {
					  //   strokeWidth: 2,
					  //   stroke: '#FF0072',
					  // },
					};
	  
					// return  defaultEdges;
					// ;
				  }),
				  ...data?.data?.note?.map((item) => {
					const id = item.childNodeId;
					const pid = item.parentNodeId;
	
					return {
					  id: `${pid}=>${id}`,
					  source: pid,
					  target: id,
					  type: "workflow",
					  // markerEnd: {
					  //   type: MarkerType.Arrow,
					  //   width: 20,
					  //   height: 20,
					  //   color: "#FF0072",
					  // },
					  // style: {
					  //   strokeWidth: 2,
					  //   stroke: '#FF0072',
					  // },
					};
	
					// return  defaultEdges;
					// ;
				  }),
				  ...data?.data?.email?.map((item) => {
					const id = item.childNodeId;
					const pid = item.parentNodeId;
	
					return {
					  id: `${pid}=>${id}`,
					  source: pid,
					  target: id,
					  type: "workflow",
					};
				  }),
				];
	  
				if (data?.data?.trigger) {
				  newNode = [
					...newNode,
					...data?.data?.trigger?.map((item) => {
					  const id = item.childNodeId;
					  const method = item.method;
					  let label = triggerShowName(item.triggerType);
					  const triggerType = item?.triggerType;
					  const triggerId = item._id;
					  const parentNodeId = item.parentNodeId;
					  const journeyId = item.journeyId;
					  const isFilled = item?.isFilled;
					  const prevNodeId = item?.prevNodeId;
					  const nextNodeId = item?.nextNodeId;
					  const title = item?.title;
	  
					  return {
						id,
						data: {
						  label,
						  method,
						  extra: triggerId,
						  parentNodeId: parentNodeId,
						  journeyId,
						  isFilled,
						  prevNodeId,
						  nextNodeId,
						  triggerType,
						  title,
						},
						position: { x: 0, y: 0 },
						type: "workflow",
					  };
					}),
				  ];
	  
				  newEdge = [
					...newEdge,
					...data?.data?.trigger?.map((item) => {
					  const id = item.childNodeId;
					  const pid = item.parentNodeId;
	  
					  return {
						id: `${pid}=>${id}`,
						source: pid,
						target: id,
						type: "workflow",
						// markerEnd: {
						//   type: MarkerType.Arrow,
						//   width: 20,
						//   height: 20,
						//   color: "#FF0072",
						// },
						// style: {
						//   strokeWidth: 2,
						//   stroke: '#FF0072',
						// },
					  };
					}),
				  ];
				}
	  
				for (let i = 0; i < Array.from(data?.data?.form).length; i++) {
				  let id = Array.from(data?.data?.form)[i].childNodeId;
				  let pid = Array.from(data?.data?.form)[i].parentNodeId;
				  let yesId = Array.from(data?.data?.form)[i].positiveChildNodeId;
				  let noId = Array.from(data?.data?.form)[i].negativeChildNodeId;
				  let chCond = Array.from(data?.data?.form)[i].checkCondition;
				  let chSub = Array.from(data?.data?.form)[i].checkSubmission;
				  let waitUntilReminderAreSend = Array.from(data?.data?.form)[i].waitUntilReminderAreSend;
				  newEdge.push({
					id: `${pid}=>${id}`,
					source: pid,
					target: id,
					type: "workflow",
					// markerEnd: {
					//   type: MarkerType.Arrow,
					//   width: 20,
					//   height: 20,
					//   color: "#FF0072",
					// },
					// style: {
					//   strokeWidth: 2,
					//   stroke: '#FF0072',
					// },
				  });
				  if (chCond || (waitUntilReminderAreSend && chSub)) {
					newEdge.push({
					  id: `${id}=>${yesId}`,
					  source: id,
					  target: yesId,
					  type: "workflow",
					  // markerEnd: {
					  //   type: MarkerType.Arrow,
					  //   width: 20,
					  //   height: 20,
					  //   color: "#FF0072",
					  // },
					  // style: {
					  //   strokeWidth: 2,
					  //   stroke: '#FF0072',
					  // },
					});
					newEdge.push({
					  id: `${id}=>${noId}`,
					  source: id,
					  target: noId,
					  type: "workflow",
					  // markerEnd: {
					  //   type: MarkerType.Arrow,
					  //   width: 20,
					  //   height: 20,
					  //   color: "#FF0072",
					  // },
					  // style: {
					  //   strokeWidth: 2,
					  //   stroke: '#FF0072',
					  // },
					});
				  }
				}
	  
				for (let i = 0; i < Array.from(data?.data?.call).length; i++) {
				  let id = Array.from(data?.data?.call)[i].childNodeId;
				  let pid = Array.from(data?.data?.call)[i].parentNodeId;
				  let yesId = Array.from(data?.data?.call)[i].positiveChildNodeId;
				  let noId = Array.from(data?.data?.call)[i].negativeChildNodeId;
				  newEdge.push({
					id: `${pid}=>${id}`,
					source: pid,
					target: id,
					type: "workflow",
					// markerEnd: {
					//   type: MarkerType.Arrow,
					//   width: 20,
					//   height: 20,
					//   color: "#FF0072",
					// },
					// style: {
					//   strokeWidth: 2,
					//   stroke: '#FF0072',
					// },
				  });
				  newEdge.push({
					id: `${id}=>${yesId}`,
					source: id,
					target: yesId,
					type: "workflow",
					// markerEnd: {
					//   type: MarkerType.Arrow,
					//   width: 20,
					//   height: 20,
					//   color: "#FF0072",
					// },
					// style: {
					//   strokeWidth: 2,
					//   stroke: '#FF0072',
					// },
				  });
				  newEdge.push({
					id: `${id}=>${noId}`,
					source: id,
					target: noId,
					type: "workflow",
					// markerEnd: {
					//   type: MarkerType.Arrow,
					//   width: 20,
					//   height: 20,
					//   color: "#FF0072",
					// },
					// style: {
					//   strokeWidth: 2,
					//   stroke: '#FF0072',
					// },
				  });
	  
				}
				// Use the formData as needed
				setNodes(newNode);
				setEdge(newEdge);
	  
				setIsLoading(false);
			  })
			  .catch((error) => {
				console.log(error);
				setIsJourneyError(true);
				setIsLoading(false);
			  });
		  }

	  }, [noteData, agentData ]);

	useEffect(()=>{
		axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/journeys/${journeyId}`)
        .then((response) => {
          const data = response.data;
		  setJourneyTitle({title: data?.data?.title , description: data?.data?.description})
          
        })

		setRefetchTitle(false)
	},[refetchTitle])

	useEffect(() => {
		let stop = setInterval(() => {
		  setOpen(false);
		  clearInterval(stop);
		}, 2000);
	
		return () => {
		  clearInterval(stop);
		};
	  }, []);

	if (isLoading || open || isLoadingAgentData || isLoadingNoteData)
		return (
		  <p>
			<CircularLoader />
		  </p>
		);

	return (
		<div style={{ width: '100%', height: '90vh' }}>
			{defaultEdges.length > 0 && defaultNodes.length > 0 && !open && !isJourneyError && 
			(
			<ReactFlow
				defaultNodes={defaultNodes}
				defaultEdges={defaultEdges}
				proOptions={proOptions}
				fitView={true}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				fitViewOptions={fitViewOptions}
				minZoom={0.2}
				nodesDraggable={false}
				nodesConnectable={false}
				zoomOnDoubleClick={false}
				style={{ background: '#f2f2f2' }}
				// we are setting deleteKeyCode to null to prevent the deletion of nodes in order to keep the example simple.
				// If you want to enable deletion of nodes, you need to make sure that you only have one root node in your graph.
				deleteKeyCode={null}
			>
				<Controls showInteractive={false} />
				<MiniMap nodeStrokeColor={'black'} nodeColor={'rgb(199 151 187)'} style={{ border:'1px solid black'}} />
			</ReactFlow>
			)}

{defaultEdges.length === 0 && defaultNodes.length > 0 && !open && !isJourneyError &&(
	<ReactFlow
	defaultNodes={defaultNodes}
	defaultEdges={defaultEdges}
	proOptions={proOptions}
	fitView={true}
	nodeTypes={nodeTypes}
	edgeTypes={edgeTypes}
	fitViewOptions={fitViewOptions}
	minZoom={0.2}
	nodesDraggable={false}
	nodesConnectable={false}
	zoomOnDoubleClick={false}
	style={{ background: '#f2f2f2' }}
	// we are setting deleteKeyCode to null to prevent the deletion of nodes in order to keep the example simple.
	// If you want to enable deletion of nodes, you need to make sure that you only have one root node in your graph.
	deleteKeyCode={null}
>
	<Controls showInteractive={false} />
	<MiniMap nodeStrokeColor={'black'} nodeColor={'rgb(199 151 187)'} style={{ border:'1px solid black'}} />
</ReactFlow>
)}
		</div>
	)
}
function WorkflowBuilder({journeyTitle , setJourneyTitle, journeyId, refetchTitle, setRefetchTitle,   isJourneyError, 
	setIsJourneyError}) {
	return (
		<ReactFlowProvider>
			<Workflow {...{journeyTitle , setJourneyTitle, journeyId, refetchTitle, setRefetchTitle,   isJourneyError, 
  setIsJourneyError}} />
		</ReactFlowProvider>
	)
}

export default WorkflowBuilder
