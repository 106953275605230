import React, { useState, useEffect } from 'react'
import { Box, Badge, IconButton, Stack, Typography, Button } from '@mui/material'
import { RefreshSvg, BellSvg, MessageSvg } from '../../assets/svgs'
import { NotificationDrawer } from '../NotificationDrawer'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Breadcrumb } from '../index'
import { PatientBreadcrumb } from '../../Pages/Patient/index'
import { useGetNotificationsQuery, useViewNotificationMutation } from '../../store/notificationsSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useSelector } from 'react-redux'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { socket } from '../../socket'
import { useIsHovered } from '../../contexts/isHovered'
import { drawerWidth } from './constant'
import { MessageDrawer } from '../MessageLayout/MessageDrawer'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import { useGetPatientByIDQuery } from '../../store/patientsSlice'
import OutlinedButton from '../OutlinedButton'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { ROLES } from '../../constants/routes'
import { useChatValues } from '../../contexts/chatValue'
import AssistantRoundedIcon from '@mui/icons-material/AssistantRounded';
import { ExternalAIAssistant } from '../ExternalAIAssistant/ExternalAIAssistantDrawer'
import { useAssistantChatValues } from '../../contexts/assistantChatValue'
import { KIMISvg } from '../../assets/svgs/KIMI'
// import { Button } from '@material-ui/core'
export const Header = () => {
	const location = useLocation()
	const { sid, id } = useParams()
	const { user } = useSelector((state) => state.user)
    const {setChatMessages, chatMessages} = useChatValues()
	// States for notification
	const [count, setCount] = useState(0)
	const [notification, setNotification] = useState([])
	const { hovered } = useIsHovered()
	let pl = !hovered ? '60px' : `${drawerWidth + 10}px`
	const [page, setPage] = useState(1)
	const { data, refetch } = useGetNotificationsQuery(user.id)
	const [viewNotification] = useViewNotificationMutation()
	const {setAssistantChatMessages} = useAssistantChatValues()

	const refetchAgain = () => {
		refetch()
	}
	const [arrow, setArrow] = useState(false)
	const [pageTitle, setPageTitle] = useState('')
	const [prevTitle, setPrevTitle] = useState('')
	const [backArrow, setBackArrow] = useState(false)
	const [openAIAssistant, setOpenAIAssistant] = useState(false)

	useEffect(() => {
		const pathSegments = location.pathname.split('/')
		const currentPageTitle = pathSegments[1]

		// Set the previous page's title when the location changes
		setPrevTitle(pageTitle)
		if (currentPageTitle === 'queue') {
			// Set pageTitle based on currentPageTitle
			setPageTitle('Patient Queue')
		} else if ((currentPageTitle === 'queueprofile' && prevTitle !== 'dashboard') || currentPageTitle === 'createProfile') {
			setBackArrow(true)
			setPageTitle(prevTitle || 'Patient Queue Profile')
		} else if (currentPageTitle === 'queueprofile' && prevTitle === 'dashboard') {
			setBackArrow(true)
			setPageTitle('Patient Queue Profile')
		} else if (currentPageTitle === 'patients') {
			setBackArrow(false)
			setPageTitle('Patient Management')
		} else if (currentPageTitle === 'pros') {
			setBackArrow(false)
			setPageTitle('Patient Reported Outcome (PRO) Insights')
		} else if (currentPageTitle === 'social-care-orchestration') {
			setBackArrow(false)
			setPageTitle('social Care Orchestration')
		} else if (currentPageTitle === 'social-care-queue') {
			setBackArrow(false)
			setPageTitle('social Care Queue')
		} else {
			setBackArrow(false)
			setPageTitle(currentPageTitle)
		}
	}, [location.pathname, pageTitle])

	const pid = location.pathname.split('/').reverse()[0]

	// varibles for arrow button
	const formEdit = location.pathname.split('/')[2]
	const formCreate = location.pathname
	const navigate = useNavigate()

	const handleGoBack = () => {
		navigate(-1)
	}
	// States and Functions for Notification Drawer
	const [open, setOpen] = React.useState(false)
	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}

		viewNotification().then(() => {
			refetch()
		})
		setOpen(open)
	}

	function getRoleName(roleId) {
		for (const [roleName, id] of Object.entries(ROLES)) {
			if (id === roleId) {
				const formattedRoleName = roleName.replace(/([a-z])([A-Z])/g, '$1 $2')
				return formattedRoleName
			}
		}
		return 'Role not found'
	}

	useEffect(() => {
		if (formEdit === 'edit' || formCreate === '/forms/create') {
			setArrow(true)
		} else {
			setArrow(false)
		}
	}, [formEdit, formCreate])

	// useEffect for notification
	useEffect(() => {
		if (data) {
			setNotification(data.data)
		}
	}, [data])

	useEffect(() => {
		socket.on('Notification', (notificationData) => {
			setNotification((prevNotification) => [...prevNotification, notificationData])
		})
	}, []) // Only run once on mount

	useEffect(() => {
		const filteredCount = notification.filter((item) => !item?.isCount)
		const countLength = filteredCount.length
		setCount(countLength)
	}, [notification, count])

	const [openMsg, setOpenMsg] = useState(false)
	const { data: patient, isLoading, refetch: refetchPatient } = useGetPatientByIDQuery(id, { refetchOnMountOrArgChange: true })

	const toggleMsgDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}

		setOpenMsg(open)
		setChatMessages([])
	}
	const toggleAIDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}

		setAssistantChatMessages([])
		setOpenAIAssistant(open)
	}
	const refetchPatientAgain = () => {
		refetchPatient()
	}

	return (
		<>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems={arrow && 'center'}
				sx={{
					py: '10px',
					px: '40px',
					bgcolor: 'white',
					position: 'fixed',
					top: 0,
					zIndex: 900,
					left: '2rem',
					width: '100%',
					borderBottom: '1px solid #9797971a',
					pl,
				}}
			>
				<Box
					sx={{
						display: (arrow || backArrow) && 'flex',
						alignItems: (arrow || backArrow) && 'center',
					}}
				>
					{(arrow || backArrow) && <ArrowBackIcon sx={{ cursor: 'pointer', marginRight: '10px' }} onClick={handleGoBack} />}
					<Typography
						variant="h1"
						sx={{
							justifyContent: 'center',
							textTransform: 'capitalize',
							fontSize: '2em',
						}}
					>
						{pageTitle !== 'patientSurveys' && pageTitle !== 'profile' && pageTitle !== 'editProfile' ? (
							pageTitle
						) : pageTitle === 'profile' || pageTitle === 'editProfile' ? (
							<PatientBreadcrumb id={id} />
						) : (
							<Breadcrumb surveyid={id} />
						)}
					</Typography>
				</Box>

				{/* Commenting out the Icons part in navbar */}
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Box
						sx={{ display: 'flex', alignItems: 'center', marginTop: 'auto', marginRight: (pageTitle === 'profile' || pageTitle === 'EditProfile') && '10px' }}
					>
						<Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '5px' }}>
							<Typography sx={{ textAlign: 'end', color: 'grey', fontSize: '12px', fontWeight: 800 }}>{user.email}</Typography>
							<Typography sx={{ textAlign: 'end', color: 'grey', fontSize: '12px' }}>{getRoleName(user.role)}</Typography>
						</Box>

						<AccountCircleIcon sx={{ color: '#979797', fontSize: 40 }} />
					</Box>
					{(pageTitle === 'profile' || pageTitle === 'EditProfile') && <OutlinedButton label={'Message'} handleClick={toggleMsgDrawer(true)} />}
					<Button variant="contained" color="primary" startIcon={<KIMISvg/>} onClick={toggleAIDrawer(true)}>
						Ask KIMI
					</Button>
					<IconButton aria-label={'more than 99 notifications'} onClick={toggleDrawer(true)}>
						{count === 0 ? (
							<NotificationsNoneIcon sx={{ color: '#979797' }} />
						) : (
							<Badge badgeContent={count} color="secondary">
								<NotificationsNoneIcon />
							</Badge>
						)}
					</IconButton>
				</Box>
				<NotificationDrawer toggleDrawer={toggleDrawer} state={open} data={notification} refetchAgain={refetchAgain} />
				<ExternalAIAssistant toggleDrawer={toggleAIDrawer} state={openAIAssistant}/>
				<MessageDrawer
					toggleDrawer={toggleMsgDrawer}
					state={openMsg}
					id={id}
					name={patient?.firstName}
					user={user}
					path={patient?.path}
					patient={patient}
					title={pageTitle}
					consent={patient?.consentResponse}
					page={page}
					setPage={setPage}
					refetchPatientAgain={refetchPatientAgain}
				/>
			</Stack>
			<Box sx={{ height: '2rem' }} />{' '}
		</>
	)
}
