import { Box, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useIsMobile } from '../../contexts/isMobile'
import { useGetChatQuery } from '../../store/chatSlice'
import { DisplayMessageBox } from './DisplayMessagesBox'
import { SendMessageField } from './SendMessageField'

export default function ConversationTab({ id, isDrawer, selectedValue, page, setPage, chatBot, consent, status, value, AiAssistantValue, agentId }) {
	const [allMessages, setAllMessages] = useState([])
	const isMobile = useIsMobile()
	const [isPrevPageLoading, setisPrevPageLoading] = useState(false) // Track loading state for messages and pagination
	const [showLoader, setShowLoader] = useState(false)
	const [patientQuestion, setPatientQuestion] = useState('')
	const [aiGeneratedMessage, setAiGeneratedMessage] = useState('')
	const [lastMessageId, setLastMessageId] = useState(null)
	const [filteredData, setFilteredData] = useState([])
	const scrollableRef = useRef()
	const calculateScrollHeight = useRef()
	const prevDataRef = useRef()
	const sendMessageFieldRef = useRef()
	const { data, refetch, isLoading } = useGetChatQuery({ id, sid: '2', page })

	useEffect(() => {
		calculateScrollHeight.current = scrollableRef.current?.scrollHeight
		refetch()
	}, [page, refetch])

	const refetchAgain = () => {
		refetch()
	}

	React.useEffect(() => {
		if (data) {
			const interval = setInterval(() => {
				refetch()
				if (data?.data?.length !== prevDataRef?.current?.length) {
					prevDataRef.current = data?.data
					const tempAllMessages = page === 1 ? data?.data : allMessages.concat(data?.data)
					setAllMessages(tempAllMessages)
					const sortedDate = [...tempAllMessages]?.sort((a, b) => {
						const dateA = new Date(a.createdAt)
						const dateB = new Date(b.createdAt)

						return dateA - dateB
					})

					setFilteredData(sortedDate)
					const userLastMessageArray = data?.data?.filter((item) => item.sentBy === 'user')
					const userLastMessage = userLastMessageArray[userLastMessageArray?.length - 1]
					setLastMessageId(userLastMessage?._id)
					setisPrevPageLoading(false)
				}
			}, 5000)

			const tempAllMessages = page === 1 ? data?.data : allMessages.concat(data?.data)
			setAllMessages(tempAllMessages)
			const sortedDate = [...tempAllMessages]?.sort((a, b) => {
				const dateA = new Date(a.createdAt)
				const dateB = new Date(b.createdAt)

				return dateA - dateB
			})

			setFilteredData(sortedDate)
			const userLastMessageArray = data?.data?.filter((item) => item.sentBy === 'user')
			const userLastMessage = userLastMessageArray[userLastMessageArray?.length - 1]
			setLastMessageId(userLastMessage?._id)
			setisPrevPageLoading(false)
			prevDataRef.current = data?.data

			return () => clearInterval(interval)
		}
	}, [data])

	useEffect(() => {
		scrollDown()
	}, [filteredData])

	useEffect(() => {
		setPage(1)
	}, [])

	const scrollDown = () => {
		if (scrollableRef.current && page == 1) {
			scrollableRef.current.scrollTop = scrollableRef.current?.scrollHeight
		} else if (scrollableRef.current && page > 1) {
			scrollableRef.current.scrollTop = scrollableRef.current?.scrollHeight - calculateScrollHeight.current
		}
	}

	const adjustDisplayMessageBoxHeight = () => {
		if (sendMessageFieldRef.current && scrollableRef.current) {
			const sendMessageFieldHeight = sendMessageFieldRef.current.clientHeight
			const maxHeight = isDrawer ? 'calc(100vh - 150px)' : 'calc(100vh - 250px)'
			const adjustedHeight = `calc(${maxHeight} - ${sendMessageFieldHeight}px)`
			scrollableRef.current.style.maxHeight = adjustedHeight

			scrollDown()
		}
	}

	useEffect(() => {
		adjustDisplayMessageBoxHeight()
	}, [sendMessageFieldRef.current?.clientHeight])

	const handleScroll = () => {
		const scrollTop = scrollableRef.current.scrollTop
		if (scrollTop == 0) {
			// Near the top - fetch previous page
			setPage((prevPage) => prevPage + 1)
			setisPrevPageLoading(true)
		}
	}
	if (isLoading)
		return (
			<>
				<Stack spacing={1} py={2}>
					<Skeleton variant="rounded" animation="wave" width={360} height={100} />
					<div style={{ display: 'flex', justifyContent: 'right' }}>
						<Skeleton variant="rounded" animation="wave" width={360} height={100} />
					</div>
					<Skeleton variant="rounded" animation="wave" width={360} height={100} />
				</Stack>
			</>
		)

	return (
		<>
			{isPrevPageLoading && (
				<Typography
					sx={{
						fontSize: '14px',
						fontWeight: 600,
						textAlign: 'center',
						display: 'flex',
						justifyContent: 'center',
						color: 'gray',
					}}
				>
					Fetching Messages . . .
				</Typography>
			)}
			<Box
				className="ScrollDesign"
				ref={scrollableRef}
				sx={{
					overflowY: 'auto', // Enable vertical scrolling if needed
					marginBottom: '100px',
					p: 1,
				}}
				onScroll={handleScroll}
			>
				{filteredData?.length > 0 ? (
					<DisplayMessageBox
						data={filteredData}
						chatBot={chatBot}
						consent={consent}
						selectedValue={selectedValue}
						setShowLoader={setShowLoader}
						setAiGeneratedMessage={setAiGeneratedMessage}
						setPatientQuestion={setPatientQuestion}
					/>
				) : (
					<Typography
						sx={{
							fontSize: '18px',
							fontWeight: 600,
							mt: '2rem',
							textAlign: 'center',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '50vh',
						}}
					>
						No Messages Found.
					</Typography>
				)}
			</Box>
			<Box
				sx={{
					position: 'fixed',
					bottom: 0,
					width: isDrawer && isMobile ? '78%' : isDrawer ? '57%' : '64%',
					background: 'white',
				}}
			>
				<div ref={sendMessageFieldRef}>
					<SendMessageField
						id={id}
						refetchAgain={refetchAgain}
						setPage={setPage}
						scrollDown={scrollDown}
						chatBot={chatBot}
						consent={consent}
						status={status}
						showLoader={showLoader}
						aiGeneratedMessage={aiGeneratedMessage}
						setAiGeneratedMessage={setAiGeneratedMessage}
						patientQuestion={patientQuestion}
						setPatientQuestion={setPatientQuestion}
						value={value}
						AiAssistantValue={AiAssistantValue}
						agentId={agentId}
					/>
				</div>
			</Box>
		</>
	)
}
