import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Chrono } from "react-chrono";
import { dummyCallData } from "../Data/CallData";
import CircleIcon from "@mui/icons-material/Circle";
import CallIcon from "@mui/icons-material/Call";
import moment from "moment";
import { useGetPatientQueueCallDetailQuery } from "../../store/patientQueuesSlice";
import { CircularLoader } from "../Loader";
import {
  useGetCallAgentsQuery,
  useGetCallsByPatientIDQuery,
} from "../../store/callSlice";
import CallAccordion from "../../Pages/Patient/PatientProfile/CallDrawers/CallAccordion";

const findFormattedKeyById = (data, id) => {
  if (data) {
    const key = Object?.keys(data)?.find((key) => data[key] === id);

    if (key) {
      const formattedKey = key
        ?.replace(/_/g, " ")
        ?.replace(/\b\w/g, (char) => char?.toUpperCase());
      return formattedKey;
    } else {
      return "N/A";
    }
  } else {
    return "N/A";
  }
};

const ContentAccordion = ({ item, agentData }) => {
  return (
    <Accordion sx={{ border: "none", boxShadow: "none" }}>
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <Grid container spacing={12} alignItems={"center"}>
          <Grid item xs={12} sm={5}>
            <Typography variant="subtitle2">Agent Name:</Typography>
            <Typography variant="body2">
              {findFormattedKeyById(agentData, item?.agentId)}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Call Picked-up:</Typography>
            <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
              {item?.callPicked
                ? "Yes"
                : item?.callPicked === false
                ? "No"
                : "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Recording:</Typography>
            <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
              {item?.recordingUrl ? "Yes" : "No"}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Divider
          sx={{ background: "#ffffff", width: "100%", marginBottom: "20px" }}
        />

        <Grid container>
          {item?.transcriptObject ? (
            <Grid item xs={12} sm={12} sx={{ marginBottom: "20px" }}>
              <CallAccordion
                title={"Transcript"}
                data={item?.transcriptObject || []}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={4} sx={{ marginBottom: "20px" }}>
                <Typography variant="subtitle2">Transcript :</Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body2">No Transcript To Show</Typography>
                </Box>
              </Grid>
            </>
          )}

          {item?.metadata?.call_analysis?.call_summary ? (
            <Grid item xs={12} sm={12} sx={{ marginBottom: "20px" }}>
              <CallAccordion
                title={"Summary"}
                data={item?.metadata?.call_analysis?.call_summary}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">Call Summary :</Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <Typography
                  variant="body2"
                  sx={{ textTransform: "capitalize" }}
                >
                  {item?.summary || "N/A"}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const customContent = (dummyCallData, agentData) => {
  return dummyCallData.map((data, index) => (
    <Box key={index}>
      <ContentAccordion item={data} agentData={agentData} />
    </Box>
  ));
};

const CustomTitle = ({ dateTime }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="subtitle" color="textSecondary" fontSize={"14px"}>
        {moment(dateTime).format("DD-MMM-YY")}
      </Typography>
      <Typography variant="body2" color="textSecondary" fontSize={"12px"}>
        {moment(dateTime).format("h:mm A")}
      </Typography>
    </Box>
  );
};

export default function CallTab({ id }) {
  const [timelineItems, setTimelineItems] = useState([]);

  const { data, isLoading, refetch } = useGetCallsByPatientIDQuery({id, page:1});

  const { data: agentData, isLoading: isLoadingAgent } =
    useGetCallAgentsQuery();

  useEffect(() => {
    if (data?.calls) {
      const timelineDataReturn = data?.calls?.map((item) => {
        return { title: <CustomTitle dateTime={item?.createdAt} /> };
      });
      setTimelineItems(timelineDataReturn);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading || isLoadingAgent) return <CircularLoader />;

  return (
    <Box
      sx={{
        height: "80vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
      className="DialogContent"
    >
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          size="small"
          startIcon={<CallIcon />}
          sx={{
            mr: "10px",
            background: "#E9E9E9",
            color: "black",
            "&:hover": {
              backgroundColor: "#bdbdbd",
              color: "white",
            },
          }}
        >
          Create Call
        </Button>
      </Box> */}
      {data?.calls && data?.calls?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography variant="h6">No Record Found</Typography>
        </Box>
      ) : (
        <Chrono
          key={timelineItems?.length}
          items={timelineItems}
          hideControls
          disableClickOnCircle
          mode="VERTICAL"
          cardHeight={100}
          theme={{ primary: "#0049C6", secondary: "#f5f5f5" }}
          cardPosition="top"
        >
          {customContent(data?.calls || [], agentData || {})}

          <Box className="chrono-icons">
            {timelineItems.map((item, index) => (
              <CircleIcon
                key={index}
                sx={{ color: "#0049C6", fontSize: "20px" }}
              />
            ))}
          </Box>
        </Chrono>
      )}
    </Box>
  );
}
