import * as React from "react";
import { Avatar, IconButton, Tooltip, Box, Typography, Stack } from "@mui/material";
import { ConfirmationModal } from "../../../Components/MessageLayout/ConfirmationModal";
import ReplayIcon from "@mui/icons-material/Replay";

const calculateAge = (dob, gender) => {
  const genderShortForm =
    gender === "male" ? "M" : gender === "female" ? "F" : "N/A";
  if (dob) {
    // Check if the date is in ISO 8601 format
    let birthDate = dob.includes("T")
      ? new Date(dob)
      : new Date(`${dob}T00:00:00.000Z`);
    let today = new Date();
    let age_now = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return `${age_now}Y, ${genderShortForm}`;
  } else {
    return `${genderShortForm}`;
  }
};
function formatPhoneNumber(phoneNumber) {
  // Remove any non-digit characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Check if the input is valid (should be 11 digits with country code)
  if (cleaned.length === 11 && cleaned[0] === "1") {
    const country = cleaned[0]; // Country code
    const area = cleaned.slice(1, 4); // Area code
    const local = cleaned.slice(4, 7); // First 3 digits
    const number = cleaned.slice(7, 11); // Last 4 digits

    return `+${country} (${area}) ${local}-${number}`;
  }

  return phoneNumber; // Return original if not valid
}
const getConsentColors = (consent) => {
  if (consent === "yes") {
    return {
      marginRight: "5px",
      color: "#63A02C",
    };
  } else if (consent === "no") {
    return {
      marginRight: "5px",
      color: "#DC362E",
    };
  } else {
    return {
      marginRight: "5px",
      color: "#FFB400",
    };
  }
};

const getSmsConsent = (data) => {
  return data?.consentResponse === "NO_RESPONSE"
    ? "No Response"
    : data?.consentResponse?.charAt(0).toUpperCase() +
        data?.consentResponse?.slice(1);
};

const getColorStyles = (acuity) => {
  if (acuity === "high") {
    return {
      marginRight: "5px",
      color: "#DC362E",
    };
  } else if (acuity === "medium") {
    return {
      marginRight: "5px",
      color: "#FFB400",
    };
  } else if (acuity === "N/A") {
    return {
      marginRight: "5px",
      color: "black",
    };
  } else {
    return {
      marginRight: "5px",
      color: "#63A02C",
    };
  }
};

export function DrawerHeaderV2({ patientDetails }) {
  const [openResend, setOpenResend] = React.useState(false);

  const handleResend = () => {
    setOpenResend(true);
  };

  const handleDataResend = async () => {
    console.log("Resent");
  };
  console.log(patientDetails);
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        gap: 2,
        m: "0px 16px",
        mt:'10px',
        p: 1,
        bgcolor: "rgba(0,151,236,0.12)",
        color: "#50607A",
        border: "1px solid rgba(0,151,236,0.12)",
        borderRadius: 3,
        fontSize: "10px",
        fontWeight: "600",
      })}
    >
      <Avatar
        sx={{ width: 40, height: 40 }}
        alt="Profile Image"
        //   src={
        //     path
        //       ? `${process.env.REACT_APP_BASE_API_URL}/${path}`
        //       : user?.picture
        //   }
      />

      <Stack direction="column" spacing={0.5}>
        <Typography variant="body2" >
          {`${patientDetails?.firstName} ${patientDetails?.lastName}`}
        </Typography>
        <Typography variant="caption">
          {calculateAge(patientDetails?.birthday, patientDetails?.gender)}
        </Typography>
      </Stack>

      <Stack direction="column" justifyContent="center" spacing={0.5}>
        <Typography
          variant="body2"
          sx={{
            marginTop: patientDetails?.acuity && "15px !important",
          }}
        >
          Acuity
        </Typography>
        {patientDetails?.acuity ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "-10px !important",
            }}
          >
            <span
              style={{
                ...getColorStyles(patientDetails?.acuity),
                fontSize: "30px",
              }}
            >
              •
            </span>
            <Typography variant="caption">
              {patientDetails?.acuity?.charAt(0).toUpperCase() +
                patientDetails?.acuity?.slice(1)}{" "}
              Acuity
            </Typography>
          </Box>
        ) : (
          <Typography variant="caption">
            N/A
          </Typography>
        )}
      </Stack>

      <Stack direction="column" spacing={0.5}>
        <Typography variant="body2">
          Phone
        </Typography>
        <Typography variant="caption">
          {formatPhoneNumber(patientDetails?.phone)}
        </Typography>
      </Stack>

      <Stack direction="column" spacing={0.5}>
        <Typography variant="body2">
          Email
        </Typography>
        <Typography variant="caption">
          N/A
        </Typography>
      </Stack>

      <Stack direction="column" justifyContent="center" spacing={0.5}>
        <Typography
          variant="body2"
          sx={{ marginTop: "15px !important" }}
        >
          SMS Consent
          {patientDetails?.consentResponse !== "yes" && (
            <Tooltip title="Resend SMS Consent">
              <IconButton onClick={handleResend}>
                <ReplayIcon sx={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "-10px !important",
          }}
        >
          <span
            style={{
              ...getConsentColors(patientDetails?.consentResponse),
              fontSize: "30px",
            }}
          >
            •
          </span>
          <Typography variant="caption" color="#50607A">
            {getSmsConsent(patientDetails)}
          </Typography>
        </Box>
      </Stack>

      <ConfirmationModal
        open={openResend}
        setOpen={setOpenResend}
        txt="Are you sure you want to resend the SMS consent?"
        handle={handleDataResend}
      />
    </Box>
  );
}
