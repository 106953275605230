import { useNavigate } from 'react-router-dom'

export const setTokenInLocalStroage = (token) => {
  localStorage.setItem("token", token);
};

export const getTokenFromLocalStroage = () => {
  return localStorage.getItem("token");
};

export const setSiteIdInLocalStroage = (siteId) => {
  localStorage.setItem("currentSiteId", siteId);
};

export const getCurrentSiteId = () => {
  return localStorage.getItem("currentSiteId");
}

export const clearLocalStroage = () => {
  localStorage.clear();
};

export const isAllowed = (allowedRoles, currentUserRole) => {
  // if (!allowedRoles) return true;
  return allowedRoles?.includes(currentUserRole);
};

// This function will remove the spaces in phone number
export const formatPhone = (number) => {
  const phoneNumberWithoutSpaces = number.replace(/\D/g, "");
  const formattedPhoneNumber = "+" + phoneNumberWithoutSpaces;
  return formattedPhoneNumber;
}

export const hasScope = (scope, requiredScope) => {
  return scope?.includes(requiredScope);
};

export const toSnakeCase = (str) => {
  return str
    ?.toLowerCase()
    ?.replace(/\s+/g, '_');
}

export const toTitleCase = (str) => {
  return str
    ?.split('_')
    ?.map(word => 
      word?.charAt(0).toUpperCase() + 
      word?.slice(1)
    ) 
    ?.join(' ');
}