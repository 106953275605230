import React, { createContext, useContext, useState } from "react";

export const ChildNode = createContext();

export const ChildNodeProvider = ({ children }) => {
    const [isChildNodeAdded, setIsChildNodeAdded] = useState(false);
    const [editMenu, setEditMenu] = useState(null);
    const [refreshworkflow , setRefreshworkflow] = useState(false);
    const [forceRefreshChildNode, setForceRefreshChildNode] = useState(false);
    const [procedureReferesh, setProcedureReferesh] = useState(0)
    const [appointmentReferesh, setAppointmentReferesh] = useState(0)
    
  return (
    <ChildNode.Provider value={{ isChildNodeAdded, setIsChildNodeAdded, editMenu, setEditMenu  , refreshworkflow , procedureReferesh, setProcedureReferesh, appointmentReferesh, setAppointmentReferesh, setRefreshworkflow, forceRefreshChildNode, setForceRefreshChildNode}}>
      {children}
    </ChildNode.Provider>
  );
};

export function useChildNode() {
  return useContext(ChildNode);
}