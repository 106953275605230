import { useSelector } from 'react-redux'
import { useGetAllSurveysQuery } from '../../../store/suerveySlice'
import CloseIcon from '@mui/icons-material/Close'
import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Portal,
	Radio,
	RadioGroup,
	Select,
	Switch,
	TextField,
	Typography,
	Tooltip,
} from '@mui/material'
import { CircularLoader } from '../../../Components'
import React, { useEffect, useRef, useState } from 'react'
import { useChildNode } from '../../../contexts/addChildNode'
import { toast } from 'react-toastify'
import { uuid } from '../Flow/utils'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import PlaceholderMenu from '../../../Components/React-Flow-Form/PlaceholderMenu'

export function DelaySelect({ metric, handleFormSubmissionSmsReminder, componentId, forceUpdate, setForceUpdateMetric, components }) {
	const delayContainer = useRef(null)

	useEffect(() => {
		setForceUpdateMetric(!forceUpdate)
	}, [components])

	return (
		<FormControl
			fullWidth
			ref={delayContainer}
			sx={{
				textAlign: 'left',
				marginBottom: '15px',
			}}
		>
			<Portal container={() => delayContainer.current}>
				<Select fullWidth id="demo-simple-delay" value={metric} name="metric" onChange={(e) => handleFormSubmissionSmsReminder(e, componentId)}>
					{[
						{ id: 'minutes', label: 'Minutes' },
						{ id: 'hours', label: 'Hours' },
						{ id: 'days', label: 'Days' },
						{ id: 'weeks', label: 'Weeks' },
						{ id: 'months', label: 'Months' },
					].map((item) => (
						<MenuItem
							key={item.id}
							value={item.id}
							sx={{
								'&.css-6hp17o-MuiList-root-MuiMenu-list': {
									width: '100%',
								},
								width: '590px',
							}}
						>
							{item.label}
						</MenuItem>
					))}
				</Select>
			</Portal>
		</FormControl>
	)
}

export function CreateForm({
	selectForm,
	setSelectForm,
	setSurveyValues,
	setCheckCondition,
	setFormQuestions,
	setSelectedQuestions,
	checkCondition,
	checkConditionRender,
	setCheckConditionRender,
	selectedQuestionsRender,
	setSelectedQuestionsRender,
	conditionOperator,
	setConditionOperator,
	selectedQuestions,
	metric,
	setMetric,
	delay,
	setDelay,
	sms,
	setSms,
	components,
	setComponents,
	formSubmissionSmsReminder,
	setFormSubmissionSmsReminder,
	setWaitUntilReminderAreSend,
	waitUntilReminderAreSend,
	surveyValues,
	addQuestion,
	setAddQuestion,
	formQuestions,
	data: nodeData,
	nodeID,
	nodeInternals,
	selectedRemindersRender,
	setSelectedRemindersRender,
	smsOnFormAssignment,
	setSmsOnFormAssignment,
	placeholderData
}) {
	const { user } = useSelector((state) => state.user)
	const { isChildNodeAdded, editMenu } = useChildNode()
	const container = React.useRef(null)
	const conditionContainer = useRef(null)
	const containerSelect = useRef(null)
	const quesContainer = useRef(null)
	const inputRefs = useRef([]); // Refs for each TextField
	const inputRef = useRef(null);  // For Single text Field
	const [forceUpdate, setForceUpdate] = useState(false)
	const [forceUpdateQues, setForceUpdateQues] = useState(false)
	const [forceUpdateMetric, setForceUpdateMetric] = useState(false)
	const [cursorPositions, setCursorPositions] = useState([]);
	const [clickedIndex, setClickedIndex] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null);
	const openPlaceholder = Boolean(anchorEl);
	const [anchorElPlaceholder, setAnchorElPlaceholder] = React.useState(null);
	const openSinglePlaceholder = Boolean(anchorElPlaceholder);
	const { data, isLoading, isError } = useGetAllSurveysQuery(user.id)

	const questionsArray = []

	// Function to add more components
	const addMoreComponents = (index) => {
		const values = formSubmissionSmsReminder[index]
		if (!values?.value || !values?.metric || values?.message === '' || values?.message?.trim() === '{{ surveyLink }}') {
			toast.warning('Please fill in all values before proceeding')
			return
		} else {
			setComponents((prevComponents) => [...prevComponents, { id: components[components.length - 1]?.id + 1 }])
			setFormSubmissionSmsReminder((prevReminder) => [
				...prevReminder,
				{ value: 1, metric: 'minutes', message: `Please complete the survey at the following link:\n{{ surveyLink }}`, reminderNodeId: uuid() },
			])
			setSelectedRemindersRender((prevRender) => [
				...prevRender,
				{ value: 1, metric: 'minutes', message: `Please complete the survey at the following link:\n{{ surveyLink }}`, reminderNodeId: uuid() },
			])

			 // Also add a new entry for cursor position
			 setCursorPositions([...cursorPositions, 0]); // Initialize the cursor position
		}
	}

	const removeMoreComponent = (componentId, index) => {
		const filteredComponents = components?.filter((items) => items?.id !== componentId)
		let updatedReminder = [...formSubmissionSmsReminder]
		updatedReminder.splice(index, 1)
		setComponents(filteredComponents)
		setFormSubmissionSmsReminder(updatedReminder)
		setSelectedRemindersRender(updatedReminder)

		// Also reset cursorPositions to avoid index errors
		setCursorPositions(cursorPositions.filter((_, idx) => idx !== index));
	}

	const handleChange = (event) => {
		const selectedFormId = event.target.value
		const selectedForm = data?.data?.find((item) => item?._id === selectedFormId)

		setSurveyValues({})
		setSelectedQuestions([])
		setSelectedQuestionsRender([])
		// setCheckCondition(false)
		// setCheckConditionRender(false)
		setSelectForm({
			id: selectedForm._id,
			form: selectedForm.form.title,
			pages: selectedForm.form.pages,
		})

		selectedForm?.form?.pages?.forEach((page) => {
			page?.elements?.forEach((element) => {
				if (['radiogroup', 'checkbox', 'boolean', 'dropdown'].includes(element.type)) {
					questionsArray.push({
						name: element.name,
						title: element.title,
						type: element.type,
						choices: element.choices,
						labelTrue: element.labelTrue,
						labelFalse: element.labelFalse,
					})
				}
			})
		})

		setFormQuestions(questionsArray)
	}

	const handleInputChange = (elementName, value, isCheckbox = false) => {
		setSurveyValues((prevSurveyValues) => {
			if (isCheckbox) {
				const currentValues = prevSurveyValues[elementName] || []
				const updatedValues = currentValues.includes(value) ? currentValues?.filter((item) => item !== value) : [...currentValues, value]

				return {
					...prevSurveyValues,
					[elementName]: updatedValues,
				}
			} else {
				return {
					...prevSurveyValues,
					[elementName]: value,
				}
			}
		})
	}

	const handleRemoveQuestion = (questionName) => {
		const updatedSelectedQuestions = selectedQuestions?.filter((question) => question.name !== questionName)
		const updatedSurveyValues = { ...surveyValues }
		delete updatedSurveyValues[questionName]

		setSelectedQuestions(updatedSelectedQuestions)
		setSelectedQuestionsRender(updatedSelectedQuestions)
		setSurveyValues(updatedSurveyValues)
	}

	const handleCheckConditionUpdate = (e, checkValue) => {
		setCheckCondition((checkCondition) => !checkCondition)
		setCheckConditionRender((checkCondition) => !checkCondition)
		// if (checkValue === false) {
		// 	setWaitUntilReminderAreSend(false)
		// }
		setSurveyValues({})
		setSelectedQuestions([])
		setSelectedQuestionsRender([])
	}

	const handleDropdownChange = (selectedQuestion) => {
		const selectedQuestionObject = formQuestions.find((question) => question.name === selectedQuestion)

		setSelectedQuestions([...selectedQuestions, selectedQuestionObject])
		setSelectedQuestionsRender([...selectedQuestions, selectedQuestionObject])
		let defaultValue = ''
		if (selectedQuestionObject.type === 'checkbox') {
			defaultValue = []
		} else if (selectedQuestionObject.type === 'boolean') {
			defaultValue = false
		}

		setSurveyValues({
			[`${selectedQuestionObject.name}`]: defaultValue,
			...surveyValues,
		})

		setAddQuestion(false)
	}

	const dropdownOptions = formQuestions?.filter((question) => !selectedQuestions.some((selected) => selected.name === question.name))

	const handleFormSubmissionSmsReminder = (e, componentId) => {
		const { value, name } = e.target

		// Valitdation to check if someone is trying to remove {{ surveyLink }} from message i.e. SMS.
		if (name === 'message' && !value.includes('{{ surveyLink }}')) {
			toast.error('You must include {{ surveyLink }} in the SMS.')
			return
		}

		let updatedReminder = [...formSubmissionSmsReminder]
		updatedReminder[componentId][name] = name === 'value' ? parseInt(value) : value
		setFormSubmissionSmsReminder(updatedReminder)
	}

	// This function is to disable the apply condition check if another form is created
	const isAlreadyFormCreated = (paramsId) => {
		let alreadyFormId
		let lastKey
		for (const [key, value] of nodeInternals.entries()) {
			lastKey = key
			if (
				value.data.positiveChildNodeId !== null &&
				value.data.negativeChildNodeId !== null &&
				!value.data.positiveChildNodeId &&
				value.data.label !== 'yes' &&
				value.data.label !== 'no' &&
				value.data.label !== 'Start Journey'
			) {
				alreadyFormId = value?.id
			}
		}

		if (alreadyFormId === nodeID && lastKey !== nodeID) {
			return false
		} else {
			return true
		}
	}

	const handleSmsChange = (e) => {
		const newValue = e.target.value
		if (!newValue.includes('{{ surveyLink }}')) {
			toast.error('You must include {{ surveyLink }} in the SMS Notification Message.')
			return
		}
		setSmsOnFormAssignment(newValue)
	}

	const handleReminderToggle = () => {
		setWaitUntilReminderAreSend((waitUntilReminderAreSend) => !waitUntilReminderAreSend)
		const canToggleReminder = !nodeData?.waitUntilReminderAreSend || !nodeData?.isFilled
		// if(canToggleReminder){
		//   setWaitUntilReminderAreSend((waitUntilReminderAreSend)=> !waitUntilReminderAreSend)
		// }else{
		//   toast.warning('This selection cannot be changed after saving.')
		// }
	}

	const handleMenuClickPlaceholder = (value) => {
		if (inputRefs.current[clickedIndex]) {
			const cursorPosition = cursorPositions[clickedIndex] || 0; // Get cursor position
			const currentSms = formSubmissionSmsReminder[clickedIndex]?.message || '';
			// Construct the new SMS with the value inserted at the cursor position
			const newSms = currentSms.substring(0, cursorPosition) + value + currentSms.substring(cursorPosition);
			setFormSubmissionSmsReminder((prev) =>
			  prev.map((item, idx) =>
				idx === clickedIndex ? { ...item, message: newSms } : item
			  )
			);
		  }
		  setClickedIndex(null);
		  setAnchorEl(null);
	}

	const handleMenuClickSinglePlaceholder = (value) => {
		if (inputRef.current) {
			const cursorPosition = inputRef.current.selectionStart;
			const newSms = smsOnFormAssignment.substring(0, cursorPosition) + value + smsOnFormAssignment.substring(cursorPosition);
			setSmsOnFormAssignment(newSms); // Update SMS with the new value at cursor position
		}

		setAnchorElPlaceholder(null);
	}

	useEffect(() => {
		if (checkCondition) {
			setForceUpdate(true)
		} else {
			setForceUpdate(false)
		}

		if (addQuestion) {
			setForceUpdateQues(true)
		} else {
			setForceUpdateQues(false)
		}
	}, [checkCondition, addQuestion, components])

	useEffect(() => {
		let questionsArray = []
		if (nodeData?.extra && nodeData?.pages) {
			setSelectForm({
				id: nodeData?.extra,
				form: nodeData?.label,
				pages: nodeData?.pages,
			})
		}
		setSurveyValues(nodeData?.surveyValues)
		setConditionOperator(nodeData?.conditionOperator)
		if (nodeData?.checkSubmission !== undefined) {
			setCheckCondition(nodeData?.checkSubmission)
		}
		nodeData?.pages?.forEach((page) => {
			page?.elements?.forEach((element) => {
				if (['radiogroup', 'checkbox', 'boolean', 'dropdown'].includes(element?.type)) {
					questionsArray.push({
						name: element.name,
						title: element.title,
						type: element.type,
						choices: element.choices,
						labelTrue: element.labelTrue,
						labelFalse: element.labelFalse,
					})
				}
			})
		})

		setMetric(nodeData?.metric)
		setDelay(nodeData?.value)
		setFormQuestions(questionsArray)
		setSmsOnFormAssignment(
			nodeData?.smsOnFormAssignment
				? nodeData?.smsOnFormAssignment
				: nodeData?.form?.smsOnFormAssignment
				? nodeData?.form?.smsOnFormAssignment
				: `Dear Patient,\nWe value you and would appreciate it if you could take a moment to complete a short survey.\nPlease click on the following link to access the survey:\n{{ surveyLink }}`
		)
		let selectedPrevQuestions = []
		let keys

		if (nodeData?.surveyValues) {
			keys = Object?.keys(nodeData?.surveyValues)
			keys?.forEach((key) => {
				const selectedQuestionObject = questionsArray.find((question) => question.name === key)
				selectedPrevQuestions = [...selectedPrevQuestions, selectedQuestionObject]
			})
		}

		setSelectedQuestions(selectedPrevQuestions)
		if (nodeData?.waitUntilReminderAreSend) {
			setWaitUntilReminderAreSend(nodeData?.waitUntilReminderAreSend)
		} else {
			setWaitUntilReminderAreSend(false)
		}

		if (nodeData?.formSubmissionSmsReminder && nodeData?.formSubmissionSmsReminder?.length !== 0) {
			const allComponents = nodeData?.formSubmissionSmsReminder?.map((item, index) => ({ id: index }))
			setFormSubmissionSmsReminder(nodeData?.formSubmissionSmsReminder)
			setSelectedRemindersRender(nodeData?.formSubmissionSmsReminder)
			setComponents(allComponents)
		}
	}, [nodeData])

	return (
		<>
			<FormControl
				fullWidth
				ref={container}
				sx={{
					textAlign: 'left',
				}}
			>
				<InputLabel id="demo-simple-form-label">Select Form</InputLabel>
				<Portal container={() => container.current}>
					<Select
						fullWidth
						labelId="demo-simple-form-label"
						id="demo-simple-form"
						value={selectForm?.id || ''}
						label="Select Form"
						onChange={(e) => {
							handleChange(e)
						}}
					>
						{data?.data?.map((item) => (
							<MenuItem
								key={item._id}
								value={item._id}
								sx={{
									'&.css-6hp17o-MuiList-root-MuiMenu-list': {
										width: '100%',
									},
									width: '590px',
								}}
							>
								{item?.form?.title}
							</MenuItem>
						))}
					</Select>
				</Portal>
			</FormControl>
			{selectForm?.id && (
				<>
				<Grid item md={12} xs={12}>
				<PlaceholderMenu data={placeholderData?.messagePlaceHolders} open={openSinglePlaceholder} anchorEl={anchorElPlaceholder} setAnchorEl={setAnchorElPlaceholder} handleMenuClick={handleMenuClickSinglePlaceholder} />
			</Grid>
				<Grid item md={12} xs={12} textAlign="left" mt={2}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography fontSize={12} mr={1}>
							SMS Notification Message <span style={{ color: 'red' }}>*</span>
						</Typography>
						<Tooltip title="This message will be sent when a form is assigned to the patient. The placeholder {{ surveyLink }} will be replaced with the actual survey link when the message is delivered.">
							<Box sx={{ color: 'grey', paddingTop: '3px', cursor: 'pointer' }}>
								<InfoOutlinedIcon fontSize="small" />
							</Box>
						</Tooltip>
					</Box>
					<TextField
						variant="outlined"
						fullWidth
						className="nopan"
						name="address1"
						multiline
						rows={5}
						value={smsOnFormAssignment}
						onChange={(e) => handleSmsChange(e)}
						inputRef={inputRef}
					/>
				</Grid>
				</>
			)}
			{selectForm?.id && (
				<Grid item md={12} xs={12} textAlign="left">
					<FormControlLabel
						onClick={() => {
							if (nodeData?.isFilled) {
								toast.warn("This action can't be done after saving the node.")
							}
						}}
						control={
							<Checkbox
								checked={checkCondition}
								onChange={(e) => handleCheckConditionUpdate(e, !checkCondition)}
								disabled={nodeData?.isFilled} // Disable the checkbox if isFilled is true
								sx={{
									color: nodeData?.isFilled ? 'grey' : 'inherit', // Make the checkbox greyish if disabled
								}}
							/>
						}
						label="Apply Reminders"
					/>
				</Grid>
			)}
			{checkCondition && (
				<div>
					<Box sx={{ padding: '0.5rem' }}>
						<Box
							component="form"
							sx={{
								'& .MuiTextField-root': { width: '100%' },
							}}
							noValidate
							autoComplete="off"
						>
							{components?.map((component, index) => (
								<Grid container spacing={2} key={component.id}>
									<Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<Box>Reminder {index + 1}</Box>
										{components?.length !== 1 && (
											<Box onClick={() => removeMoreComponent(component?.id, index)}>
												<CloseIcon />
											</Box>
										)}
									</Grid>
								
									<Grid item md={12} xs={12}>
										<FormControl fullWidth>
											<Box sx={{ display: 'flex' }}>
												<Grid item md={6} xs={6} sx={{ display: 'flex', flexDirection: 'row' }}>
													<p style={{ marginRight: '5px', fontWeight: 600 }}>Delay</p>
													<TextField
														type="number"
														id="outlined-required"
														placeholder={`Enter ${formSubmissionSmsReminder[index]?.metric}`}
														className="nopan"
														name="value"
														value={formSubmissionSmsReminder[index]?.value}
														onChange={(e) => {
															handleFormSubmissionSmsReminder(e, index)
														}}
														sx={{
															width: '80%',
															'& input[type=number]': {
																'-moz-appearance': 'textfield',
																'-webkit-appearance': 'none',
																margin: 0,
															},
															'& input[type=number]::-webkit-outer-spin-button': {
																'-webkit-appearance': 'none',
																margin: 0,
															},
															'& input[type=number]::-webkit-inner-spin-button': {
																'-webkit-appearance': 'none',
																margin: 0,
															},
														}}
													/>
													<h2 style={{ marginLeft: '5px', marginTop: '5px' }}></h2>
												</Grid>
												<Grid item md={6} xs={6} sx={{ display: 'flex', flexDirection: 'row' }}>
													<p style={{ marginRight: '5px', fontWeight: 600 }}>Metric</p>
													<DelaySelect
														metric={formSubmissionSmsReminder[index]?.metric}
														handleFormSubmissionSmsReminder={handleFormSubmissionSmsReminder}
														componentId={index}
														forceUpdate={forceUpdateMetric}
														setForceUpdateMetric={setForceUpdateMetric}
														components={components}
													/>
												</Grid>
											</Box>
											<Grid item md={12} xs={12}>
										<PlaceholderMenu data={placeholderData?.messagePlaceHolders} open={openPlaceholder} anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleMenuClick={handleMenuClickPlaceholder} index={clickedIndex}  />
									</Grid>
											<Grid item md={12} xs={12} sx={{ marginBottom: '15px' }}>
												<TextField
													required
													multiline
													fullWidth
													rows={4}
													id="outlined-textarea"
													label="SMS"
													className="nopan"
													placeholder="Enter SMS"
													name="message"
													value={formSubmissionSmsReminder[index]?.message}
													onChange={(e) => {
														handleFormSubmissionSmsReminder(e, index)
													}}
													inputRef={el => (inputRefs.current[index] = el)} // Reference to the input
													onClick={() => {
													  if (inputRefs.current[index]) {
														const cursorPos = inputRefs.current[index].selectionStart;
														setCursorPositions((prev) => {
														  const newPositions = [...prev];
														  newPositions[index] = cursorPos; // Store cursor position for the current index
														  return newPositions;
														});
														setClickedIndex(index)
													  }
													}}
												/>
											</Grid>
											{index === components.length - 1 && (
												<Button
													variant="contained"
													onClick={() => addMoreComponents(index)}
													sx={{
														backgroundColor: '#816179',
													}}
												>
													Add Reminder
												</Button>
											)}
										</FormControl>
									</Grid>
								</Grid>
							))}
						</Box>
					</Box>
				</div>
			)}
			{checkCondition && (
				<Grid item md={12} xs={12} textAlign="left">
					<FormControlLabel
						onClick={() => {
							if (nodeData?.isFilled) {
								toast.warn("This action can't be done after saving the node.")
							}
						}}
						control={
							<Checkbox
								checked={waitUntilReminderAreSend}
								onChange={() => {
									handleReminderToggle()
								}}
								disabled={nodeData?.isFilled} // Disable the checkbox if isFilled is true
								sx={{
									color: nodeData?.isFilled ? 'grey' : 'inherit', // Make the checkbox greyish if disabled
								}}
							/>
						}
						label="Wait Until Reminders Are Sent"
					/>
				</Grid>
			)}

			{checkCondition && (
				<Grid item md={12} xs={12} textAlign="left">
					<p style={{ textAlign: 'justify', marginRight: '10px' }}>
						<span style={{ color: 'red' }}>Please note</span>: The Journey will proceed to the next node 24 hours after the last reminder is sent to the
						patient.
					</p>
				</Grid>
			)}
			{/* {((selectForm?.id && selectForm?.pages?.length > 0) && (!isChildNodeAdded && isAlreadyFormCreated())) && (
        <Grid item md={12} xs={12} textAlign="left">
          <FormControlLabel
            control={
              <Checkbox
                checked={checkCondition}
                onChange={(e) => handleCheckConditionUpdate(e)}
              />
            }
            label="Apply Conditions"
          />
        </Grid>
      )}
      {checkCondition && (
        <div>
          <FormControl
            fullWidth
            ref={conditionContainer}
            sx={{
              textAlign: "left",
              marginTop: "1em",
            }}
          >
            <InputLabel id="demo-simple-form-condition">
              Select Condition
            </InputLabel>
            {forceUpdate && (
              <Portal container={() => conditionContainer.current}>
                <Select
                  fullWidth
                  labelId="demo-simple-form-condition"
                  id="demo-simple-condition"
                  value={conditionOperator}
                  label="Select Condition"
                  onChange={(e) => setConditionOperator(e.target.value)}
                >
                  {[
                    { id: "and", label: "Check all the answers" },
                    { id: "or", label: "Check any of the answer" },
                  ].map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      sx={{
                        "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                          width: "100%",
                        },
                        width: "590px",
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </Portal>
            )}
          </FormControl>
          <Box sx={{ padding: "0.5rem" }}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    {selectedQuestions?.map((element, index) => (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              marginTop: "3px",
                              marginRight: "20px",
                              padding: "6px",
                            }}
                          >
                            <Typography variant="h2" sx={{ fontSize: "20px" }}>
                              Question
                            </Typography>
                          </Box>
                          <Box sx={{ width: "70%" }}>
                            <Typography variant="p" sx={{ fontSize: "16px" }}>
                              {element.title ? element.title : element.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{ marginLeft: "auto", cursor: "pointer" }}
                            onClick={(e) => handleRemoveQuestion(element.name)}
                          >
                            <CloseIcon />
                          </Box>
                        </Box>
                        <Typography
                          sx={{ marginTop: "10px", fontSize: "14px" }}
                        >
                          <Box
                            sx={{
                              padding: "6px",
                            }}
                          >
                            {element.type === "radiogroup" && (
                              <Grid item md={12} xs={12}>
                                {element?.choices?.map((choice, choiceIndex) => (
                                  <>
                                    <RadioGroup
                                      required
                                      aria-labelledby="demo-error-radios"
                                      name="quiz"
                                      value={choice}
                                      checked={
                                        surveyValues[`${element.name}`] ===
                                        choice
                                      }
                                      onChange={() =>
                                        handleInputChange(
                                          element.name,
                                          typeof choice === "object"
                                            ? choice.text
                                            : choice
                                        )
                                      }
                                    >
                                      <FormControlLabel
                                        checked={
                                          surveyValues[`${element.name}`] ===
                                          (typeof choice === "object"
                                            ? choice.text
                                            : choice)
                                        }
                                        value={
                                          typeof choice === "object"
                                            ? choice.text
                                            : choice
                                        }
                                        control={<Radio />}
                                        label={
                                          typeof choice === "object"
                                            ? choice.text
                                            : choice
                                        }
                                      />
                                    </RadioGroup>
                                  </>
                                ))}
                              </Grid>
                            )}
                            {element.type === "checkbox" && (
                              <Grid item md={12} xs={12}>
                                {element?.choices?.map((choice, choiceIndex) => (
                                  <FormGroup
                                    key={`${element.name}-${choiceIndex}`}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            surveyValues[
                                            `${element.name}-${typeof choice === "object"
                                              ? choice.text
                                              : choice
                                            }`
                                            ]
                                          }
                                          onChange={() =>
                                            handleInputChange(
                                              element.name,
                                              typeof choice === "object"
                                                ? choice.text
                                                : choice,
                                              true
                                            )
                                          }
                                          name={`${element.name}-${typeof choice === "object"
                                              ? choice.text
                                              : choice
                                            }`}
                                        />
                                      }
                                      label={
                                        typeof choice === "object"
                                          ? choice.text
                                          : choice
                                      }
                                    />
                                  </FormGroup>
                                ))}
                              </Grid>
                            )}
                            {element.type === "boolean" && (
                              <Grid item md={12} xs={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          surveyValues[`${element.name}`]
                                        }
                                        onChange={() =>
                                          handleInputChange(
                                            element.name,
                                            !surveyValues[`${element.name}`]
                                          )
                                        }
                                      />
                                    }
                                    label={element.labelTrue}
                                  />
                                </FormGroup>
                              </Grid>
                            )}
                            {element.type === "dropdown" && (
                              <Grid item md={12} xs={12}>
                                <FormControl fullWidth ref={containerSelect}>
                                  <InputLabel id="demo-simple-select-label">
                                    Select
                                  </InputLabel>
                                  <Portal
                                    container={() => containerSelect.current}
                                  >
                                    <Select
                                      labelId="demo-simple-form-label"
                                      id="demo-simple-form"
                                      value={
                                        surveyValues[`${element.name}`] || ""
                                      }
                                      label="Select"
                                      onChange={(e) =>
                                        handleInputChange(
                                          element.name,
                                          e.target.value
                                        )
                                      }
                                    >
                                      {element?.choices?.map(
                                        (choice, choiceIndex) => (
                                          <MenuItem
                                            key={choiceIndex}
                                            value={
                                              typeof choice === "object"
                                                ? choice.text
                                                : choice
                                            }
                                          >
                                            {typeof choice === "object"
                                              ? choice.text
                                              : choice}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </Portal>
                                </FormControl>
                              </Grid>
                            )}
                          </Box>
                        </Typography>
                        <Divider sx={{ marginTop: "3px" }} />
                        <br />
                      </>
                    ))}
                    {addQuestion && (
                      <FormControl
                        fullWidth
                        ref={quesContainer}
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        <InputLabel id="question-select-label">
                          Select Question
                        </InputLabel>
                        {forceUpdateQues && (
                          <Portal container={() => quesContainer.current}>
                            <Select
                              fullWidth
                              labelId="question-select-label"
                              id="question-select"
                              value=""
                              label="Select Question"
                              onChange={(e) =>
                                handleDropdownChange(e.target.value)
                              }
                            >
                              {dropdownOptions?.map((element) => (
                                <MenuItem
                                  key={element.name}
                                  value={element.name}
                                  sx={{
                                    "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                                      width: "100%",
                                    },
                                    width: "500px",
                                  }}
                                >
                                  {element.title ? element.title : element.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <br />
                          </Portal>
                        )}
                      </FormControl>
                    )}
                    {dropdownOptions?.length > 0 && (
                      <>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            setAddQuestion((addQuestion) => !addQuestion)
                          }
                          sx={{ marginBottom: "1em", backgroundColor: "#816179" }}
                        >
                          Add Condition
                        </Button>
                        <br />
                        <br />
                      </>
                    )}
                    <DelaySelect
                      defaultMetric={"weeks"}
                      metric={metric}
                      setMetric={setMetric}
                    />
                    <Grid item md={12} xs={12}>
                      <TextField
                        required
                        id="outlined-required"
                        label={`${metric}`}
                        placeholder={`Enter ${metric}`}
                        name="title"
                        value={delay}
                        onChange={(e) => {
                          setDelay(e.target.value);
                        }}
                      />
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      )} */}
		</>
	)
}
