import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useIsMobile } from "../../contexts/isMobile";
import { useIsHovered } from "../../contexts/isHovered";
import SavedFilterAccordion from "./FilterUtils/SavedFilterAccordion";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SelectField from "./FilterUtils/SelectField";

export function FilterSidebar({data, queriesValue, setQueriesValue}) {
  const isMobile = useIsMobile();
  const { hovered } = useIsHovered();

  const [surgeonValue, setSurgeonValue] = useState(localStorage.getItem('surgeon') ? JSON.parse(localStorage.getItem('surgeon')) :  [])
  const [taskCategoryValue, setTaskCategoryValue] = useState(localStorage.getItem('taskCategory') ? JSON.parse(localStorage.getItem('taskCategory')) :  [])
  const [procedureTypeValue, setProcedureTypeValue] = useState(localStorage.getItem('surgeryType') ? JSON.parse(localStorage.getItem('surgeryType')) :  [])
  const [careProgramValue, setCareProgramValue] = useState(localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) :  [])
  const [selectedPatientStatus,setSelectedPatientStatus] = useState(localStorage.getItem('selectedPatientStatus') ? localStorage.getItem('selectedPatientStatus') : 'pending')
	const [checkboxStates, setCheckboxStates] = useState(localStorage.getItem('checkboxStates') ? JSON.parse(localStorage.getItem('checkboxStates')) : {})

  const handleDelete = (label, value) => {
    let updatedState
    if(label === 'surgeon'){
      updatedState = surgeonValue.some((item) => item.value === value.value)
      ? surgeonValue.filter((item) => item.value !== value.value)  // Remove if value exists
      : surgeonValue             
        setSurgeonValue(updatedState
      );
    }else if(label === "taskCategory"){
      updatedState = taskCategoryValue.some((item) => item.value === value.value)
      ? taskCategoryValue.filter((item) => item.value !== value.value)  // Remove if value exists
      : taskCategoryValue 
        setTaskCategoryValue(updatedState);
    }else if(label === "surgeryType"){
      updatedState = procedureTypeValue.some((item) => item.value === value.value)
      ? procedureTypeValue.filter((item) => item.value !== value.value)  // Remove if value exists
      : procedureTypeValue 
        setProcedureTypeValue(updatedState);
    }else if(label === "program"){
      updatedState = careProgramValue.some((item) => item.value === value.value)
      ? careProgramValue.filter((item) => item.value !== value.value)  // Remove if value exists
      : careProgramValue 
        setCareProgramValue(updatedState);
    }

    const filteredObject = { 
      ...queriesValue, 
      [label]: updatedState 
    };
		// Set the queries value using the filtered object
		setQueriesValue(filteredObject);
		
		localStorage.setItem('queriesValue', JSON.stringify(filteredObject))
  };

  const deleteSurgeon = () => {
    setSurgeonValue([])
  }

  const handleChangPatientStatus = (event, item, key) => {
		const { checked } = event.target
		const filteredObject = { ...queriesValue };

		filteredObject['status'] = item.value;

		setSelectedPatientStatus(item?.value)
		setQueriesValue(filteredObject)
		
		localStorage.setItem('selectedPatientStatus', item?.value)
		localStorage.setItem('queriesValue', JSON.stringify(filteredObject))
	}

  const handleCheckboxChange = (event, item, key) => {
		const { checked } = event.target
		const filteredObject = { ...queriesValue };

		const updatedCheckboxStates = {
			...checkboxStates,
			[key]: {
			  ...checkboxStates[key],
			  [item.title]: checked,
			},
		};

		// All the logic used in setQueriesValue
		if (Array.isArray(filteredObject[key])) {
			if (!checked) {
				let updatedArray;
				if(key === 'surgeryType'){
					updatedArray = filteredObject[key].filter((val) => val !== item.id);
				}else{
					updatedArray = filteredObject[key].filter((val) => val !== item.value);
				}

				if (updatedArray.length === 0) {
					delete filteredObject[key];
				} else {
					filteredObject[key] = updatedArray;
				}
			} else if(key === 'surgeryType') {
				filteredObject[key] = [...filteredObject[key], item.id];
			} else{
				filteredObject[key] = [...filteredObject[key], item.value];
			}
		} else if (filteredObject[key]) {
			if (checked && key === 'surgeryType') {
				filteredObject[key] = [filteredObject[key], item.id];
			} else if(checked) {
				filteredObject[key] = [filteredObject[key], item.value];
			}else if(!checked && key === 'surgeryType'){
				filteredObject[key] = filteredObject[key] === item.id ? null : filteredObject[key];
			}else{
				filteredObject[key] = filteredObject[key] === item.value ? null : filteredObject[key];
			}
		} else if(key === 'surgeryType') {
			filteredObject[key] = item.id;
		} else {
			filteredObject[key] = item.value;
		}
		  
		setCheckboxStates(updatedCheckboxStates);
		// Set the queries value using the filtered object
		setQueriesValue(filteredObject);
		
		localStorage.setItem('queriesValue', JSON.stringify(filteredObject))
		localStorage.setItem('checkboxStates', JSON.stringify(updatedCheckboxStates))
		
	}

  const handleChange = (event, fieldKey) => {
    const { value } = event.target
		let updatedState;

    if(fieldKey === "taskCategory"){
      updatedState = taskCategoryValue.some((item) => item.value === value.value) ? taskCategoryValue : [...taskCategoryValue, value];
      setTaskCategoryValue(updatedState);
    }else if(fieldKey === "surgeryType"){
      updatedState = procedureTypeValue.some((item) => item.value === value.value) ? procedureTypeValue : [...procedureTypeValue, value];
      setProcedureTypeValue(updatedState);
    }else if(fieldKey === "program"){
      updatedState = careProgramValue.some((item) => item.value === value.value) ? careProgramValue : [...careProgramValue, value];
      setCareProgramValue(updatedState);
    }else if(fieldKey === "surgeon"){
      updatedState = surgeonValue.some((item) => item.value === value.value) ? surgeonValue : [...surgeonValue, value];
      setSurgeonValue(updatedState);
    }

    const filteredObject = { 
      ...queriesValue, 
      [fieldKey]: updatedState 
    };
		// Set the queries value using the filtered object
		setQueriesValue(filteredObject);
		
		localStorage.setItem('queriesValue', JSON.stringify(filteredObject))
    localStorage.setItem(fieldKey, JSON.stringify(updatedState))
  }

  const handleClear = () => {
    // Reset states to their default values
    setSurgeonValue([]);
    setTaskCategoryValue([]);
    setProcedureTypeValue([]);
    setCareProgramValue([]);
    setSelectedPatientStatus('active');
    setCheckboxStates({});
    setQueriesValue({})
  
    // Remove the respective items from local storage
    localStorage.removeItem('taskCategory');
    localStorage.removeItem('surgeryType');
    localStorage.removeItem('program');
    localStorage.removeItem('selectedPatientStatus');
    localStorage.removeItem('checkboxStates');
    localStorage.removeItem('queriesValue');
  };
  

  return (
    <>
      <Stack
        direction="column"
        justifyContent="left"
        sx={{
          height: "100%",
          alignItems: isMobile ? "baseline" : "left",
          position: "fixed",
          bgcolor: "white",
          paddingLeft: !hovered && "12px",
          mt: "1.5rem",
          pl: "1.2rem",
          width: "15%",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for WebKit browsers
          },
          scrollbarWidth: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              textTransform: "uppercase",
              fontWeight: "600",
              mt: "20px",
            }}
          >
            Filters
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              sx={{ mt: "20px", mr: "10px" }}
              size="small"
              onClick={()=> handleClear()}
            >
              Clear
            </Button>
            {/* <Button variant="contained" sx={{ mt: "20px" }} size="small">
              Save
            </Button> */}
          </Box>
        </Box>

        {/* Saved Filters */}
        {/* <Box sx={{ padding: "10px", mt: "10px" }}>
          <SavedFilterAccordion />
        </Box> */}

        {/* Acuity */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Acuity</Typography>
          {data['acuity']?.value?.map((item) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Checkbox 
              size="small" 
              value={item.title}
							checked={checkboxStates['acuity']?.[item.title] || false}
							onChange={(event) => handleCheckboxChange(event, item, 'acuity')} />
              <Typography> {item.title} </Typography>
            </Box>
          ))}
        </Box>

        {/* Status */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Status</Typography>
          {data['status']?.value?.map((item,index)=>(
          <RadioGroup
            aria-label="status" 
            name="status"
            value={selectedPatientStatus}
            onChange={(event) => handleChangPatientStatus(event, item , 'status')} sx={{ px: 2 }}
          >
            <FormControlLabel key={index} value={item.value} control={<Radio />} label={item?.title} />
          </RadioGroup>
            ))}
        </Box>

        {/* Surgeons */}
        {/* <Box sx={{ mt: "10px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography>Surgeons</Typography>
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ cursor: "pointer", mr: "10px" }}
              onClick={()=> deleteSurgeon()}
            />
          </Box>

          <SelectField label="Select" showValue={surgeonValue}  setShowValue={setSurgeonValue} data={data['surgeon']?.value}  onClick={handleChange} fieldKey={"surgeon"} />
            {surgeonValue?.map((item)=>(
          <Chip label={item.title} variant="outlined" onDelete={()=>handleDelete('surgeon', item)} sx={{mr:'3px'}} />
            ))}
        </Box> */}

        {/* Task Category */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Task Category</Typography>

          <SelectField label="All" showValue={taskCategoryValue}  setShowValue={setTaskCategoryValue} data={data['taskCategory']?.value}  onClick={handleChange} fieldKey={"taskCategory"} />

          {taskCategoryValue?.map((item)=>(
          <Chip label={item?.title} variant="outlined" onDelete={()=>handleDelete('taskCategory', item)} sx={{mr:'3px'}} />
            ))}
        </Box>

        {/*  Procedure Type */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Procedure Type</Typography>

          <SelectField label="Select Type" showValue={procedureTypeValue} setShowValue={setProcedureTypeValue} data={data['surgeryType']?.value} onClick={handleChange} fieldKey={"surgeryType"} />

          {procedureTypeValue?.map((item)=>(
          <Chip label={item?.title} variant="outlined" onDelete={()=>handleDelete('surgeryType', item)} sx={{mr:'3px'}} />
            ))}
        </Box>

        {/*  Care Program */}
        <Box sx={{ mt: "10px", mb:'4rem' }}>
          <Typography>Care Program</Typography>

          <SelectField label="Select Type" showValue={careProgramValue} setShowValue={setCareProgramValue} data={data['program']?.value} onClick={handleChange} fieldKey={"program"} />

          {careProgramValue?.map((item)=>(
          <Chip label={item?.title} variant="outlined" onDelete={()=>handleDelete('program', item)} sx={{mr:'3px'}} />
            ))}
        </Box>
      </Stack>
    </>
  );
}
