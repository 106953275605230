import { Box, Grid, Typography } from "@mui/material";
import DialogueAccordion from "../../Pages/patientQueue/DialogueAccordion";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export default function DialogueTab({queueData}){
    return(
        <Box
        sx={{
            height: "75vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          className="DialogContent"
          >
        <Grid container spacing={2}>
            {queueData?.dialogueChat?.length !== 0 && (
        <Grid item xs={12}>
            <DialogueAccordion title={'QnA'} chatData={queueData?.dialogueChat} />
        </Grid>
            )}

        <Grid item xs={12}>
            <Typography variant="h6" fontSize={'16px'}>
                Dialogue Summary
            </Typography>
            <Typography>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                        a: (props) => (
                            <a href={props.href} style={{ color: '#000' }} target="blank">
                                {props.children}
                            </a>
                        ),
                    }}
                >
                    {queueData?.dialogueSummary ? queueData?.dialogueSummary?.replace(/^\`\`\`|`\`\`$/g, '')?.replace(/\n/g, '  \n') : 'No Summary Available'}
                </ReactMarkdown>
            </Typography>
        </Grid>
    </Grid>
        </Box>
    )
}