export const PATHS = {
  index: "/",
  solve: "/SolveSurveys/:sid/:id",
  dashboardIndexPage: "/dashboard",
  patientsIndexPage: "/patients",
  prosIndexPage: "/pros",
  socialNeedsIndexPage: "/social-care-orchestration",
  createPatient: "/createProfile/",
  editPatient: "/editProfile/:id",
  patientsCareProgramPage: "/patients/:programValue",
  patientsProfilePage: "/profile/:id",
  queueProfilePage: "/queueprofile/:id",
  journeysIndexPage: "/journeys",
  patientQueue: "/queue",
  settigsIndexPage: "/settings",
  logoutIndexPage: "/",
  formsIndexPage: "/forms",
  formsCreatorPage: "/forms/create",
  formsEditorPage: "/forms/edit/:id",
  formsEditPage: "/forms/create",
  formsShowPage: "/forms/:id",
  role: "/roles",
  uploadImage:"/imageUpload/:id",
  formsAnalyticsPage: "/analytics",
  patientSurveys: "/patientSurveys/:sid/:id",
  ShowPatientSurvey: "/showPatientSurvey/:sid/:id",
  tasks: "/tasks",
  journeysflow: "/journeysflow",
  editjourneysflow: "/journeysflow/:id",
  viewjourneysflow: "/viewjourneysflow/:id/:patientId/:assignId",
  thankyou: "/thankyou",
  onBoarding: "/onboarding",
  socialCareQueue: "/social-care-queue"
};

export const ROLES = {
  Admin: 1526,
  ClientAdmin: 2615,
  CareTeamMember: 1122,
  ReportingUser: 1111,
};
