import React, { useContext, useState } from 'react'
import {
	MainLogoSvg,
	DashboardSvg,
	FormSvg,
	JourneysSvg,
	AnalyticsSvg,
	PatientsSvg,
	SettingsSvg,
	LogoutSvg,
	PatientQueueSvg,
	RoleSvg,
	TaskSvg,
} from '../../assets/svgs'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
	Box,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Toolbar,
	FormControl,
	Select,
	MenuItem,
	IconButton,
	Tooltip,
	Collapse,
} from '@mui/material'
import { useGetAllHospitalsQuery } from '../../store/hospitalsSlice'
import { drawerWidth } from './constant'
import { useNavigate } from 'react-router-dom'
import { PATHS, ROLES } from '../../constants/routes'
import { useIsMobile } from '../../contexts/isMobile'
import { useDispatch, useSelector } from 'react-redux'
import { resetUser } from './../../store/userSlice'
import { getCurrentSiteId, clearLocalStroage, isAllowed, setSiteIdInLocalStroage } from '../../utils'
import { useAuth0 } from '@auth0/auth0-react'
import { useIsHovered } from '../../contexts/isHovered'
import { useLocation } from 'react-router-dom'
import { persistor, removePersistedData } from '../../store'
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined'
import { NavLogo } from '../../assets/svgs/MainLogoSvg'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { AccountBoxRounded, AssessmentOutlined, PeopleAltRounded } from '@mui/icons-material'

export function Sidebar() {
	const { logout } = useAuth0()
	const navigate = useNavigate()
	const location = useLocation()
	const isMobile = useIsMobile()
	const { hovered, setHovered } = useIsHovered()
	const dispatch = useDispatch()
	const { user } = useSelector((state) => state.user)
	const { pathname } = location
	const hospitalData = useGetAllHospitalsQuery()
	const [openPatientManagement, setOpenPatientManagement] = useState(false)

	const handlePatientManagementClick = () => {
		setOpenPatientManagement(!openPatientManagement)
	}
	const emptyFunc = () => {}
	const logoutAndRedirect = async () => {
		logout()
		dispatch(resetUser())
		clearLocalStroage()
		// persistor.purge() // Delete persisted data
		await removePersistedData() // Delete persisted data
		// await purgeStoredState(persistor); // Delete persisted data
		navigate('/')
	}

	const updateSiteId = (siteId, e) => {
		e.stopPropagation()
		setSiteIdInLocalStroage(siteId)
		localStorage.removeItem('page')
		localStorage.removeItem('checkboxStates')
		localStorage.removeItem('selectedPatientStatus')
		localStorage.removeItem('queriesValue')
		localStorage.removeItem('selectedValue')
		localStorage.removeItem('value')
		localStorage.removeItem('sortField')
		localStorage.removeItem('sortOrder')
		localStorage.removeItem('selectedValue')
		localStorage.removeItem('sortCreatedAtOrder')
		localStorage.removeItem('sortAcuityOrder')
		localStorage.removeItem('sortSurgeonName')
		localStorage.removeItem('sortPatientOrder')
		localStorage.removeItem('createdAfter')
		localStorage.removeItem('consentApproved')
		localStorage.removeItem('pageName')
		localStorage.removeItem('prosPage')
		localStorage.removeItem('allMetric')
		window.location.reload()
	}

	const handleOnClick = (path) => {
		navigate(path)
		localStorage.removeItem('page')
		localStorage.removeItem('checkboxStates')
		localStorage.removeItem('selectedPatientStatus')
		localStorage.removeItem('queriesValue')
		localStorage.removeItem('selectedValue')
		localStorage.removeItem('value')
		localStorage.removeItem('sortField')
		localStorage.removeItem('sortOrder')
		localStorage.removeItem('selectedValue')
		localStorage.removeItem('sortCreatedAtOrder')
		localStorage.removeItem('sortAcuityOrder')
		localStorage.removeItem('sortSurgeonName')
		localStorage.removeItem('sortPatientOrder')
		localStorage.removeItem('createdAfter')
		localStorage.removeItem('consentApproved')
		localStorage.removeItem('pageName')
		localStorage.removeItem('prosPage')
		localStorage.removeItem('allMetric')
	}

	const drawer = (
		<>
			{!isMobile && (
				<Toolbar
					sx={{
						// paddingTop: '2rem !important',
						// paddingBottom: '2rem !important',
						justifyContent: 'center',
						display: !hovered && 'none',
					}}
				>
					<img
						src={require('./../../assets/RevelAi.png')}
						style={{
							width: '260px',
							height: '100px',
							objectFit: 'cover',
							background: 'transparent',
						}}
					/>
				</Toolbar>
			)}
			{!hovered && (
				<Toolbar
					sx={{
						// paddingTop: '2rem !important',
						// paddingBottom: '2rem !important',
						justifyContent: 'center',
						display: hovered && 'none',
					}}
				>
					<img
						src={require('./../../assets/RevelAi-03.png')}
						style={{
							width: '70px',
							height: '70px',
							objectFit: 'cover',
							background: 'transparent',
						}}
						alt="RevelAi logo"
					/>
				</Toolbar>
			)}
			<IconButton
				onClick={() => setHovered((hovered) => !hovered)}
				sx={{
					position: 'fixed',
					top: '1rem',
					left: hovered ? '13.8rem' : '2.5rem',
				}}
			>
				<PlayCircleFilledIcon
					sx={{
						color: '#0049C6',
						transform: hovered ? 'rotate(180deg)' : 'rotate(0deg)',
						transition: 'transform 0.3s ease-in-out',
					}}
				/>
			</IconButton>

			<Stack
				direction="column"
				justifyContent="space-between"
				sx={{
					height: '100%',
					alignItems: isMobile || !hovered ? 'baseline' : 'center',
					p: 1,
				}}
			>
				<List>
					{[
						{
							text: 'Dashboard',
							icon: <SpaceDashboardOutlinedIcon />,
							path: PATHS.dashboardIndexPage,
							allowedRoles: [ROLES.Admin, ROLES.ClientAdmin, ROLES.CareTeamMember, ROLES.ReportingUser],
						},
						{
							text: 'Patient Management',
							icon: <PatientsSvg fill={openPatientManagement && !hovered ? 'white' : '#536878'} />,
							allowedRoles: [ROLES.Admin, ROLES.ClientAdmin, ROLES.CareTeamMember],
							expandable: true,
							subMenuItems: [
								{
									text: 'All Patients',
									path: PATHS.patientsIndexPage,
									icon: <AccountBoxRounded />,
								},
								{
									text: 'PROs',
									path: PATHS.prosIndexPage,
									icon: <AssessmentOutlined />,
								},
								{
									text: 'Social Needs',
									path: PATHS.socialNeedsIndexPage,
									icon: <PeopleAltRounded />,
								},
							],
						},

						{
							text: 'Analytics',
							icon: <AnalyticsOutlinedIcon />,
							path: PATHS.formsAnalyticsPage,
							allowedRoles: [
								ROLES.Admin,
								ROLES.ClientAdmin,
								// ROLES.CareTeamMember,
								// ROLES.ReportingUser,
							],
						},
						{
							text: 'Forms',
							icon: <FeedOutlinedIcon />,
							path: PATHS.formsIndexPage,
							allowedRoles: [
								ROLES.Admin,
								ROLES.ClientAdmin,
								// ROLES.CareTeamMember,
								// ROLES.ReportingUser,
							],
						},
						{
							text: 'Journeys',
							icon: <AccountTreeOutlinedIcon />,
							path: PATHS.journeysIndexPage,
							allowedRoles: [
								ROLES.Admin,
								ROLES.ClientAdmin,
								// ROLES.CareTeamMember,
								// ROLES.ReportingUser,
							],
						},
						{
							text: 'Patient Queue',
							icon: <Groups2OutlinedIcon />,
							path: PATHS.patientQueue,
							allowedRoles: [
								ROLES.Admin,
								ROLES.ClientAdmin,
								// ROLES.CareTeamMember,
								// ROLES.ReportingUser,
							],
						},
						// {
						// 	text: 'Social Care',
						// 	icon: <Diversity3Icon />,
						// 	path: PATHS.socialCareQueue,
						// 	allowedRoles: [
						// 		ROLES.Admin,
						// 		ROLES.ClientAdmin,
						// 		// ROLES.CareTeamMember,
						// 		// ROLES.ReportingUser,
						// 	],
						// },
						{
							/* {
              text: "Task",
              icon: <TaskSvg />,
              path: PATHS.tasks,
              allowedRoles: [
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                ROLES.ReportingUser,
              ],
            }, */
						},
					].map(({ text, icon, path, allowedRoles, expandable, subMenuItems }) => {
						// return null;
						if (!isAllowed(allowedRoles || [], user.role)) {
							return null
						}
						if (expandable) {
							return (
								<React.Fragment key={text}>
									<ListItemButton
										onClick={handlePatientManagementClick}
										sx={{
											':hover': {
												backgroundColor: hovered ? '#D0EAE1' : 'inherit',
												borderRadius: hovered && '10px',
											},
											width: isMobile ? 48 : 220,
											marginBottom: '10px',
											p: 0,
											backgroundColor: openPatientManagement && hovered ? '#D0EAE1' : 'inherit',
											borderRadius: openPatientManagement && hovered && '10px',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<ListItemIcon sx={{ alignSelf: 'center' }}>
											<IconButton
												sx={{
													backgroundColor: openPatientManagement && !hovered ? '#0049C6' : 'inherit',
													borderRadius: openPatientManagement && !hovered && '50%',
													color: openPatientManagement && !hovered ? 'white' : '#536878',
													':hover': {
														backgroundColor: '#D0EAE1',
														borderRadius: '50%',
													},
												}}
											>
												{icon}
											</IconButton>
										</ListItemIcon>
										<ListItemText primary={text} sx={{ fontSize: '14px', fontWeight: 500 }} />
										{openPatientManagement ? <ExpandLess /> : <ExpandMore />}
									</ListItemButton>
									<Collapse in={openPatientManagement} timeout="auto" unmountOnExit>
										<List>
											{subMenuItems.map((subItem) => (
												<ListItemButton
													key={subItem.text}
													onClick={() => handleOnClick(subItem.path)}
													sx={{
														pl: hovered && 4,
														':hover': {
															backgroundColor: hovered ? '#D0EAE1' : 'inherit',
															borderRadius: hovered && '10px',
														},
														backgroundColor: `${subItem.path}` === pathname && hovered ? '#D0EAE1' : 'inherit',
														borderRadius: `${subItem.path}` === pathname && hovered && '10px',
													}}
												>
													{!hovered ? (
														<Tooltip title={subItem.text} placement="right">
															<ListItemIcon sx={{ alignSelf: 'center' }}>
																<IconButton
																	sx={{
																		backgroundColor: `${subItem.path}` === pathname && !hovered ? '#0049C6' : 'inherit',
																		borderRadius: `${subItem.path}` === pathname && !hovered && '50%',
																		color: `${subItem.path}` === pathname && !hovered ? 'white' : '#536878',
																		':hover': {
																			backgroundColor: '#D0EAE1',
																			borderRadius: '50%',
																		},
																	}}
																>
																	{subItem.icon}
																</IconButton>
															</ListItemIcon>
														</Tooltip>
													) : (
														<ListItemIcon sx={{ alignSelf: 'center' }}>
															<IconButton
																sx={{
																	backgroundColor: `${subItem.path}` === pathname && !hovered ? '#0049C6' : 'inherit',
																	borderRadius: `${subItem.path}` === pathname && !hovered && '50%',
																	color: `${subItem.path}` === pathname && !hovered ? 'white' : '#536878',
																	':hover': {
																		backgroundColor: '#D0EAE1',
																		borderRadius: '50%',
																	},
																}}
															>
																{subItem.icon}
															</IconButton>
														</ListItemIcon>
													)}
													<ListItemText primary={subItem.text} />
												</ListItemButton>
											))}
										</List>
									</Collapse>
								</React.Fragment>
							)
						}
						return (
							<ListItem key={text} disablePadding onClick={() => handleOnClick(path)}>
								<ListItemButton
									sx={{
										':hover': {
											backgroundColor: hovered ? '#D0EAE1' : 'inherit',
											borderRadius: hovered && '10px',
										},
										width: isMobile ? 48 : 220,
										marginBottom: '10px',
										p: 0,
										backgroundColor: `${path}` === pathname && hovered ? '#D0EAE1' : 'inherit',
										borderRadius: `${path}` === pathname && hovered && '10px',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									{!hovered ? (
										<Tooltip title={text} placement="right">
											<ListItemIcon sx={{ alignSelf: 'center' }}>
												<IconButton
													sx={{
														backgroundColor: `${path}` === pathname && !hovered ? '#0049C6' : 'inherit',
														borderRadius: `${path}` === pathname && !hovered && '50%',
														color: `${path}` === pathname && !hovered ? 'white' : '#536878',
														':hover': {
															backgroundColor: '#D0EAE1',
															borderRadius: '50%',
														},
													}}
												>
													{icon}
												</IconButton>
											</ListItemIcon>
										</Tooltip>
									) : (
										<ListItemIcon sx={{ alignSelf: 'center' }}>
											<IconButton
												sx={{
													backgroundColor: `${path}` === pathname && !hovered ? '#0049C6' : 'inherit',
													borderRadius: `${path}` === pathname && !hovered && '50%',
													color: `${path}` === pathname && !hovered ? 'white' : '#536878',
													':hover': {
														backgroundColor: '#D0EAE1',
														borderRadius: '50%',
													},
												}}
											>
												{icon}
											</IconButton>
										</ListItemIcon>
									)}

									<ListItemText primary={text} sx={{ fontSize: '14px', fontWeight: 500 }} />
								</ListItemButton>
							</ListItem>
						)
					})}
				</List>
				<List>
					{user.role === ROLES.Admin && hovered && (
						<FormControl sx={{ marginBottom: '10px' }}>
							<Select value={getCurrentSiteId()} onChange={(e) => updateSiteId(e.target.value, e)} className="" style={{ width: '14em' }}>
								<MenuItem value="" disabled>
									Switch Site
								</MenuItem>
								{hospitalData?.data?.data?.map((item) => (
									<MenuItem key={item._id} value={item._id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{[
						{
							text: 'Settings',
							icon: <SettingsSvg fill={PATHS.settigsIndexPage === pathname && !hovered ? 'white' : '#536878'} />,
							path: PATHS.settigsIndexPage,
							allowedRoles: [
								ROLES.Admin,
								ROLES.ClientAdmin,
								// ROLES.CareTeamMember,
								// ROLES.ReportingUser,
							],
						},
						{
							text: 'Role Management',
							icon: <Groups2OutlinedIcon />,
							path: PATHS.role,
							allowedRoles: [
								ROLES.Admin,
								ROLES.ClientAdmin,
								// ROLES.CareTeamMember,
								// ROLES.ReportingUser,
							],
						},
						{
							text: 'Logout',
							icon: <LogoutSvg />,
							allowedRoles: [ROLES.Admin, ROLES.ClientAdmin, ROLES.CareTeamMember, ROLES.ReportingUser],
						},
					].map(({ text, icon, path, allowedRoles }) => {
						if (!isAllowed(allowedRoles || [], user.role)) {
							return null
						}
						return (
							<ListItem
								key={text}
								disablePadding
								onClick={() => {
									path ? handleOnClick(path) : logoutAndRedirect()
								}}
							>
								<ListItemButton
									sx={{
										':hover': {
											backgroundColor: hovered ? '#D0EAE1' : 'inherit',
											borderRadius: hovered ? '10px' : '50%',
										},
										width: isMobile ? 48 : 220,
										marginBottom: '10px',
										p: 0,
										backgroundColor: `${path}` === pathname && hovered ? '#D0EAE1' : 'inherit',
										borderRadius: `${path}` === pathname && hovered && '10px',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									{!hovered ? (
										<Tooltip title={text} placement="right">
											<ListItemIcon sx={{ alignSelf: 'center' }}>
												<IconButton
													sx={{
														backgroundColor: `${path}` === pathname && !hovered ? '#0049C6' : 'inherit',
														borderRadius: `${path}` === pathname && !hovered && '50%',
														color: `${path}` === pathname && !hovered ? 'white' : '#536878',
														':hover': {
															backgroundColor: '#D0EAE1',
															borderRadius: '50%',
														},
													}}
												>
													{icon}
												</IconButton>
											</ListItemIcon>
										</Tooltip>
									) : (
										<ListItemIcon sx={{ alignSelf: 'center' }}>
											<IconButton
												sx={{
													backgroundColor: `${path}` === pathname && !hovered ? '#0049C6' : 'inherit',
													borderRadius: `${path}` === pathname && !hovered && '50%',
													color: `${path}` === pathname && !hovered ? 'white' : '#536878',
													':hover': {
														backgroundColor: '#D0EAE1',
														borderRadius: '50%',
													},
												}}
											>
												{icon}
											</IconButton>
										</ListItemIcon>
									)}

									<ListItemText primary={text} sx={{ fontSize: '14px', fontWeight: 500 }} />
								</ListItemButton>
							</ListItem>
						)
					})}
				</List>
			</Stack>
		</>
	)

	const width = isMobile || !hovered ? '60px' : drawerWidth
	return (
		<>
			<Drawer
				variant="permanent"
				sx={{
					display: { sm: 'block' },
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						overflowX: 'hidden',
						overflowY: 'scroll' /* Enable vertical scrolling */,
						scrollbarWidth: 'none' /* Hide scrollbar for Firefox */,
						msOverflowStyle: 'none' /* Hide scrollbar for IE and Edge */,
						'&::-webkit-scrollbar': {
							width: '0px' /* Hide scrollbar for WebKit browsers */,
						},
						width,
						transition: !hovered ? 'width 0.3s ease' : 'width 0.3s ease',
					},
				}}
				open
			>
				{drawer}
			</Drawer>
		</>
	)
}
