import React, { useState } from 'react'
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { SolidButton } from './SolidButton'
import SearchField from './SearchField'
import OutlinedButton from './OutlinedButton'

export default function FiltersDialog({ open, setOpen, queriesValue, setQueriesValue, data }) {
	const [expandedStates, setExpandedStates] = useState({})
	const [checkboxStates, setCheckboxStates] = useState(localStorage.getItem('checkboxStates') ? JSON.parse(localStorage.getItem('checkboxStates')) : {})
	const [selectedValue, setSelectedValue] = useState(localStorage.getItem('selectedValue') ? localStorage.getItem('selectedValue') : 'pending')
	const [selectedPatientStatus,setSelectedPatientStatus] = useState(localStorage.getItem('selectedPatientStatus') ? localStorage.getItem('selectedPatientStatus') : 'active')
	const [consentApproved,setConsentApproved] = useState(localStorage.getItem('consentApproved') ? localStorage.getItem('consentApproved') : '')


	const handleChange = (event, key) => {
		const { value } = event.target
		const filteredObject = {
			...queriesValue,
			[key]: value,
		  };

		// Update selected value state
		setSelectedValue(value)
		setSelectedPatientStatus(value)
		setConsentApproved(value)

		// Update setQueriesValue
		  setQueriesValue(filteredObject);
		  
		localStorage.setItem('selectedValue', value)
		localStorage.setItem('selectedPatientStatus', value)
		localStorage.setItem('consentApproved', value)
		localStorage.setItem('queriesValue', JSON.stringify(filteredObject))
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleExpandToggle = (key) => {
		setExpandedStates((prevStates) => ({
			...prevStates,
			[key]: !prevStates[key],
		}))
	}

	const handleCheckboxChange = (event, item, key) => {
		const { checked } = event.target
		const filteredObject = { ...queriesValue };

		const updatedCheckboxStates = {
			...checkboxStates,
			[key]: {
			  ...checkboxStates[key],
			  [item.title]: checked,
			},
		  };

		// All the logic used in setQueriesValue
		if (Array.isArray(filteredObject[key])) {
			if (!checked) {
				let updatedArray;
				if(key === 'surgeryType'){
					updatedArray = filteredObject[key].filter((val) => val !== item.id);
				}else{
					updatedArray = filteredObject[key].filter((val) => val !== item.value);
				}

				if (updatedArray.length === 0) {
					delete filteredObject[key];
				} else {
					filteredObject[key] = updatedArray;
				}
			} else if(key === 'surgeryType') {
				filteredObject[key] = [...filteredObject[key], item.id];
			} else{
				filteredObject[key] = [...filteredObject[key], item.value];
			}
		} else if (filteredObject[key]) {
			if (checked && key === 'surgeryType') {
				filteredObject[key] = [filteredObject[key], item.id];
			} else if(checked) {
				filteredObject[key] = [filteredObject[key], item.value];
			}else if(!checked && key === 'surgeryType'){
				filteredObject[key] = filteredObject[key] === item.id ? null : filteredObject[key];
			}else{
				filteredObject[key] = filteredObject[key] === item.value ? null : filteredObject[key];
			}
		} else if(key === 'surgeryType') {
			filteredObject[key] = item.id;
		} else {
			filteredObject[key] = item.value;
		}
		  
		setCheckboxStates(updatedCheckboxStates);
		// Set the queries value using the filtered object
		setQueriesValue(filteredObject);
		
		localStorage.setItem('queriesValue', JSON.stringify(filteredObject))
		localStorage.setItem('checkboxStates', JSON.stringify(updatedCheckboxStates))
		
	}

	const handleChangPatientStatus = (event, item, key) => {
		const { checked } = event.target
		const filteredObject = { ...queriesValue };

		if (key === 'patientStatus') {
			filteredObject['status'] = item.value;
		} else {
			if (Array.isArray(queriesValue[key])) {
				if (!checked) {
					const updatedArray = queriesValue[key].filter((val) => val !== item.value);
					if (updatedArray.length === 0) {
						delete filteredObject[key];
					} else {
						filteredObject[key] = updatedArray;
					}
				} else {
					filteredObject[key] = [...queriesValue[key], item.value];
				}
			} else if (queriesValue[key]) {
				if (checked) {
					filteredObject[key] = [queriesValue[key], item.value];
				} else {
					filteredObject[key] = queriesValue[key] === item.value ? null : queriesValue[key];
				}
			} else {
				filteredObject[key] = item.value;
			}
		}

		setSelectedPatientStatus(item?.value)
		setQueriesValue(filteredObject)
		
		localStorage.setItem('selectedPatientStatus', item?.value)
		localStorage.setItem('queriesValue', JSON.stringify(filteredObject))
	}

	const handleChangconsentApproved = (event, item, key) => {
		const { checked } = event.target
		const filteredObject = { ...queriesValue };

		if (key === 'consentApproved') {
			filteredObject['consentApproved'] = item.value;
		} else {
			if (Array.isArray(queriesValue[key])) {
				if (!checked) {
					const updatedArray = queriesValue[key].filter((val) => val !== item.value);
					if (updatedArray.length === 0) {
						delete filteredObject[key];
					} else {
						filteredObject[key] = updatedArray;
					}
				} else {
					filteredObject[key] = [...queriesValue[key], item.value];
				}
			} else if (queriesValue[key]) {
				if (checked) {
					filteredObject[key] = [queriesValue[key], item.value];
				} else {
					filteredObject[key] = queriesValue[key] === item.value ? null : queriesValue[key];
				}
			} else {
				filteredObject[key] = item.value;
			}
		}

		setConsentApproved(item?.value)
		setQueriesValue(filteredObject)
		
		localStorage.setItem('consentApproved', item?.value)
		localStorage.setItem('queriesValue', JSON.stringify(filteredObject))
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperProps={{
				sx: {
					position: 'absolute',
					right: '0',
					width: '600px',
					top: '50%',
					borderRadius: '10px',
					height: '85vh',
					padding: '0.5rem 1.5rem',
					transform: 'translateY(-50%)',
				},
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: '0.5rem 0rem',
				}}
			>
				<Typography fontSize={14} fontWeight={600}>
					Filter
				</Typography>

				<IconButton onClick={handleClose} sx={{ justifyContent: 'flex-end' }}>
					<CloseIcon />
				</IconButton>
			</div>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Divider sx={{ width: '100%' }} />
			</div>

			<DialogContent className="DialogContent" sx={{ padding: 0 }}>
				{Object.keys(data).map((key) => (
					<>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'left',
								paddingTop: '1rem',
								paddingBottom: '1rem',
							}}
							onClick={() => handleExpandToggle(key)}
						>
							{expandedStates[key] ? (
								<KeyboardArrowDownIcon sx={{ cursor: 'pointer', fontSize: '1rem' }} />
							) : (
								<KeyboardArrowUpIcon sx={{ cursor: 'pointer', fontSize: '1rem' }} />
							)}
							<Typography fontSize={'14px'} fontWeight={600} sx={{ cursor: 'pointer' }}>
								{key == 'taskCategory' ? 'Task Category' : key == 'program' ? 'Care Program'  : key==="patientStatus"?'Patient Status':key==="surgeryType"?' Procedure Type': key === "consentApproved" ? "SMS Consent" : key?.charAt(0)?.toUpperCase() + key?.slice(1)}
							</Typography>
						</div>
						{expandedStates[key] && (
							<Grid container spacing={2} justifyContent="space-between">
								{data[key]?.value?.map((item, index) => (
									<>
										{key === 'patientStatus' ? (
											<Grid item md={6} key={index}>
											<RadioGroup aria-label="status" name="status" value={selectedPatientStatus} onChange={(event) => handleChangPatientStatus(event, item , key)} sx={{ px: 2 }}>
												<FormControlLabel key={index} value={item.value} control={<Radio />} label={item.title} />
											</RadioGroup>
										</Grid>
										) : key === 'consentApproved' ? (
											<Grid item md={6} key={index}>
											<RadioGroup aria-label="status" name="status" value={consentApproved} onChange={(event) => handleChangconsentApproved(event, item , key)} sx={{ px: 2 }}>
												<FormControlLabel key={index} value={item.value} control={<Radio />} label={item.title} />
											</RadioGroup>
										</Grid>
										) :
										key !== 'status' ? (
											<Grid item md={6} key={index}>
												<Grid container alignItems="center">
													<Grid item xs={2}>
														<Checkbox
															value={item.title}
															checked={checkboxStates[key]?.[item.title] || false}
															onChange={(event) => handleCheckboxChange(event, item, key)}
														/>
													</Grid>
													<Grid item xs={10}>
														{' '}
														{/* Adjust this size according to your design */}
														<Typography fontSize={'14px'}>{item.title}</Typography>
													</Grid>
												</Grid>
											</Grid>
										) : (
											<Grid item md={6} key={index}>
												<RadioGroup aria-label="status" name="status" value={selectedValue} onChange={(event) => handleChange(event, key)} sx={{ px: 2 }}>
													<FormControlLabel key={index} value={item.value} control={<Radio />} label={item.title} />
												</RadioGroup>
											</Grid>
										)}
									</>
								))}
							</Grid>
						)}
					</>
				))}
			</DialogContent>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Divider sx={{ width: '100%' }} />
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: '0.5rem 0rem',
				}}
			>
				<Typography
					fontSize={14}
					fontWeight={600}
					sx={{
						borderBottom: '1px solid red',
						color: 'red',
						borderRadius: 0,
						cursor: 'pointer',
					}}
					onClick={() => {
						setQueriesValue({})
						setCheckboxStates({})
						setSelectedValue('pending')
						setSelectedPatientStatus('active')
						setConsentApproved('')
						localStorage.removeItem('checkboxStates')
						localStorage.removeItem('selectedPatientStatus')
						localStorage.removeItem('queriesValue')
						localStorage.removeItem('selectedValue')
						localStorage.removeItem('selectedPatientStatus')
						localStorage.removeItem('consentApproved')
					}}
				>
					Clear Filters
				</Typography>
			</div>
		</Dialog>
	)
}