import { Paper, Typography, Grid, TextField, Autocomplete, Box, Tooltip } from "@mui/material";
import React, {useState, useEffect} from "react";
import { useIsMobile } from "../../../contexts/isMobile";
import { DateField} from "../../../Components";
import TimeFieldPicker from "../../../Components/TimeField";
import { useGetPhysiciansOptionsQuery } from "../../../store/physiciansSlice";
import { useGetProceduresOptionsQuery } from "../../../store/proceduresSlice";

const AssignSurgery = ({surgeryDate, setSurgeryDate, time, setTime, setSurgeryTime, form, setForm, surgeon, setSurgeon, selectedSurgeon, setSelectedSurgeon }) => {
  const isMobile=useIsMobile()
  const [selectedSurgeryType, setSelectedSurgeryType] = useState(null);
  const {
    data: physicianOptions,
    isLoading: isPhysicianLoading
  } = useGetPhysiciansOptionsQuery()
  const {data: procedureTypeData, isLoading: isProcedureLoading} = useGetProceduresOptionsQuery()

  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const surgeryData = [
    {title:'Primary Total Hip Replacement', value:'primary_total_hip_replacement'},
    {title:'Primary Total Knee Replacement', value:'primary_total_knee_replacement'},
    {title:'Other Procedure', value:'other_procedure'},
  ]

  const handleSurgeryChange = (e, newValue) => {
    setSelectedSurgeryType(newValue);
    setForm((form)=>({...form, surgeryType:newValue?._id}))
  }

  const handleSurgeonChange=(event,newValue)=>{
    setSelectedSurgeon(newValue)
    setSurgeon(newValue?._id)
  }

  useEffect(()=>{
    if(physicianOptions && surgeon){
      const surgeonObject = physicianOptions?.find(item => item?._id === surgeon)
      setSelectedSurgeon(surgeonObject)
    }

    if(procedureTypeData?.data && form.surgeryType){
      const surgeryDataObject = procedureTypeData?.data?.find(item => item?._id === form.surgeryType)
      setSelectedSurgeryType(surgeryDataObject)
    }
  },[physicianOptions, procedureTypeData])

  if(isProcedureLoading) return <>{console.log('Loading')}</>

  return (
    <div>
      <Paper
        elevation={2}
        sx={{ borderRadius: "10px", p: isMobile ? 1 : 2, mb: 3 }}
      >
        <Typography fontSize={"18px"} fontWeight={"bolder"}>
          Assign Procedure
        </Typography>

        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
          padding={'20px'}
        >
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography fontSize={12} color={"#979797"} mt={2}>
                Surgeon Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                value={selectedSurgeon}
                onChange={(e, newValue) => handleSurgeonChange(e, newValue)}
                options={
                  !isPhysicianLoading && Array.isArray(physicianOptions)
                    ? physicianOptions
                    : []
                }
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography fontSize={12} color={"#979797"} mt={2}>
                Procedure Type <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                value={selectedSurgeryType}
                onChange={(e, newValue) => handleSurgeryChange(e, newValue)}
                options={procedureTypeData?.data}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography fontSize={12} color={"#979797"}>
                Procedure Date <span style={{ color: "red" }}>*</span>
              </Typography>
              <DateField
                date={surgeryDate}
                setDate={setSurgeryDate}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography fontSize={12} color={"#979797"}>
                Procedure Time
              </Typography>
              <TimeFieldPicker
                date={time}
                setDate={setTime}
                isSurgery={true}
                setHourToRunAt={setSurgeryTime}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography fontSize={12} color={"#979797"} mb={1}>
                Location
              </Typography>
              <TextField
                variant="standard"
                fullWidth
                name="location"
                value={form.location}
                onChange={onChange}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography fontSize={12} color={"#979797"} mt={2}>
                Description
              </Typography>
              <TextField
                variant="standard"
                fullWidth
                name="description"
                multiline
                rows={4}
                value={form.description}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default AssignSurgery;
