import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { careProgramsData } from '../../../careProgramUtils.js'
import { LastCell } from '../../patientQueue/LastCell'
import axios from 'axios'
import {
	Grid,
	Paper,
	FormControl,
	MenuItem,
	Select,
	Button,
	TableContainer,
	Alert,
	TableCell,
	TableRow,
	Divider,
	Stack,
	Table,
	TableHead,
	TableBody,
	Typography,
	IconButton,
	Chip,
	Checkbox,
	Box,
	OutlinedInput,
	ListItemText,
	InputLabel,
	LinearProgress,
	Tooltip,
	Collapse,
	Badge,
} from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import { useGetPatientQueuesQuery } from '../../../store/patientQueuesSlice'
import { CircularLoader, PaginationBar } from '../../../Components'
import { toast } from 'react-toastify'
import { useDebouncedValue } from '@mantine/hooks'
import { getTokenFromLocalStroage, toSnakeCase } from '../../../utils'
import { useIsMobile } from '../../../contexts/isMobile'
import './main.css'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import SouthIcon from '@mui/icons-material/South'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import FiltersDialog from '../../../Components/FiltersDialog'
import { useIsHovered } from '../../../contexts/isHovered'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined'
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined'
import SearchField from '../../../Components/SearchField'
import { patientQueueFilterData } from '../../../FilterUtils'
import WhatwentwrongDialog from '../../patientQueue/Profile/WhatwentwrongDialog'
import SnackbarMsg from '../../../Components/SnackbarMsg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment from 'moment'
import { TimeFrameDropdown } from '../../patientQueue/TimeFrameDropdown'
import filterByDate from '../../patientQueue/DateFilterFunction.js'
import { PatientInfo } from '../../../Components/TableFields/PatientInfo.js'
import { SurgeryInfo } from '../../../Components/TableFields/SurgeryInfo.js'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined'
import ShieldIcon from '@mui/icons-material/Shield'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import PaymentsIcon from '@mui/icons-material/Payments'
import { useGetProceduresOptionsQuery } from '../../../store/proceduresSlice.js'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import BlockIcon from '@mui/icons-material/Block'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import DialogueAccordion from '../../patientQueue/DialogueAccordion.js'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import CallIcon from "@mui/icons-material/Call";
import AssignmentIcon from '@mui/icons-material/Assignment';
import LinkQueueModal from '../../../Components/LinkQueueModal.js'
const typeData = [
	{ label: "Messages", key: "message", icon: 'ChatBubbleOutlineIcon' },
	{ label: "Photo Uploads", key: "new_media", icon:'CollectionsOutlinedIcon' },
	{ label: "Tasks", key: "task", icon:'AssignmentIcon' },
	{ label: "Forms", key: "form_submission", icon:"FeedOutlinedIcon" },
	{ label: "Care Ally Messages", key: "care_ally_message", icon:'HandshakeOutlinedIcon' },
	{ label: "Call", key: "call", icon:'CallIcon' },
	{ label: "Dialogue Assessments", key: "dialogue", icon:'ForumOutlinedIcon' },
  ];

const iconMapping = {
	ChatBubbleOutlineIcon: ChatBubbleOutlineIcon,
	CollectionsOutlinedIcon: CollectionsOutlinedIcon,
	AssignmentIcon: AssignmentIcon,
	FeedOutlinedIcon: FeedOutlinedIcon,
	HandshakeOutlinedIcon: HandshakeOutlinedIcon,
	ForumOutlinedIcon: ForumOutlinedIcon,
	CallIcon: CallIcon,
}

export function ProgramSelect({ searchProgram, setSearchProgram }) {
	const data = careProgramsData
	const isMobile = useIsMobile()
	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	}
	const handleChange = (event) => {
		const {
			target: { value },
		} = event
		setSearchProgram(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		)
	}

	const porgramTagsText = (selected) =>
		selected?.map((tagID) => {
			const tag = data?.find((tag) => tag.value === tagID)
			return tag ? tag.label : ''
		})

	return (
		<Box sx={{ padding: !isMobile && '10px' }}>
			<FormControl sx={{ width: '105%', margin: '-5px' }}>
				<InputLabel id="demo-multiple-checkbox-label">Care Programs</InputLabel>
				<Select
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					multiple
					value={searchProgram || []}
					onChange={handleChange}
					input={<OutlinedInput sx={{ height: '2.4em', margin: '10px' }} label="Care Programs" />}
					renderValue={(selected) => porgramTagsText(selected).join(', ')}
					style={{ borderColor: '#dad4d4' }}
					MenuProps={MenuProps}
				>
					{data?.map((item) => (
						<MenuItem key={item.id} value={item.value}>
							<Checkbox checked={searchProgram.indexOf(item.value) > -1} />
							<ListItemText primary={item.label} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	)
}

export function QueueTable({
	value,
	columns,
	refetchCount,
	acuityCount,
	queueData,
	selectedPatientIds,
	setSelectedPatientIds,
	page,
	setPage,
	handleSorting,
	sortPatientOrder,
	sortAcuityOrder,
	refetchQueueAgain,
}) {
	const isMobile = useIsMobile()
	const { hovered } = useIsHovered()
	const prevDataRef = useRef()
	const searchAbleFields = ['name', 'task category', 'acuity']
	const [selectedFields, setSelectedFields] = useState([...searchAbleFields])
	const { user } = useSelector((state) => state.user)
	const [searchTerm, setSearchTerm] = useState('')
	const [debouncedValue] = useDebouncedValue(searchTerm, 200)
	const [status, setStatus] = useState('pending')
	const [openRows, setOpenRows] = useState([])
	const [linkQueueModalOpen, setLinkQueueModalOpen] = useState({ value: false, data: null })
	const [filterData, setFilterData] = useState({})
	const [rows, setRows] = useState([])
	const [searchProgram, setSearchProgram] = useState([])
	const navigate = useNavigate()
	const queries = {}
	const [queriesValue, setQueriesValue] = useState(localStorage.getItem('queriesValue') ? JSON.parse(localStorage.getItem('queriesValue')) : {})
	const [open, setOpen] = useState(false)
	const [chatbotResponseCritique, setChatbotResponseCritique] = useState('')
	const [selectedAcuity, setSelectedAcuity] = useState('')
	const [proposedChatbotResponse, setProposedChatbotResponse] = useState('')
	const [selectedTaskLabel, setSelectedTaskLabel] = useState('')
	const [currentItemId, setCurrentItemId] = useState('')
	const [totalPages, setTotalPages] = useState('')
	const [patientQueues, setPatientQueues] = useState([])
	const [selectOriginalMessage, setSelectOriginalMessage] = useState(null)
	const [openDislikeModal, setOpenDislikeModal] = useState(false)
	const [isTaskCategorySelected, setIsTaskCategorySelected] = useState(true)
	const [isChatbotResponse, setIsChatbotResponse] = useState(true)
	const [chatbotResponse, setChatbotResponse] = useState(null)
	const [isAcuitySelected, setIsAcuitySelected] = useState(true)
	const [selectedValue, setSelectedValue] = useState(localStorage.getItem('selectedValue') ? localStorage.getItem('selectedValue') : null)
	const [sortCreatedAtOrder, setSortCreatedAtOrder] = useState(
		localStorage.getItem('sortCreatedAtOrder') ? JSON.parse(localStorage.getItem('sortCreatedAtOrder')) : { field: 'createdAt', sort: 'desc' }
	)
	const [sortField, setSortField] = useState(localStorage.getItem('sortField') ? localStorage.getItem('sortField') : '')
	const [sortSurgeonName, setSortSurgeonName] = useState(
		localStorage.getItem('sortSurgeonName') ? JSON.parse(localStorage.getItem('sortSurgeonName')) : { field: 'surgeonName', sort: 'asc' }
	)
	const [sortOrder, setSortOrder] = useState(localStorage.getItem('sortOrder') ? localStorage.getItem('sortOrder') : '')
	const [createdAfter, setCreatedAfter] = useState(localStorage.getItem('createdAfter') ? localStorage.getItem('createdAfter') : '')
	const [patientQueuesData, setPatientQueuesData] = useState([])
	const [isRowHovered, setIsRowHovered] = useState(null)
	const tabsValue = ['message', 'new_media', 'form_submission', 'care_ally_message', 'dialogue']

	const handleRefetch = () => {
		// refetch()
	}

	const handleFieldSelect = (option, checked) => {
		if (checked) {
			setSelectedFields([...selectedFields, option])
		} else {
			setSelectedFields(selectedFields.filter((o) => o !== option))
		}
	}

	// for (const field of selectedFields) {
	//   queries[field] = debouncedValue;
	// }

	queries['name'] = debouncedValue
	queries['acuity'] = queriesValue?.acuity || []
	queries['status'] = queriesValue?.status || 'pending'
	queries['taskCategory'] = queriesValue?.taskCategory || []
	queries['program'] = queriesValue?.program || []
	queries['surgeryType'] = queriesValue?.surgeryType || []

	// const { data, refetch, isLoading, isSuccess } = useGetPatientQueuesQuery(
	// 	{
	// 		page,
	// 		userId: user.id,
	// 		type: tabsValue[value],
	// 		sortField,
	// 		sortOrder,
	// 		createdAfter,
	// 		...queries,
	// 	},
	// 	{ refetchOnMountOrArgChange: true }
	// )

	const { data: procedureTypeData } = useGetProceduresOptionsQuery()

	// const totalPages = data?.totalPages;
	//  const patientQueues = data?.patientQueues;

	const calculateAge = (dob) => {
		if (dob) {
			// Check if the date is in ISO 8601 format
			let birthDate = dob.includes('T') ? new Date(dob) : new Date(`${dob}T00:00:00.000Z`)
			let today = new Date()
			let age_now = today.getFullYear() - birthDate.getFullYear()
			let m = today.getMonth() - birthDate.getMonth()
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age_now--
			}
			return `${age_now}Y`
		} else {
			return `N/A`
		}
	}

	function renderFeedbackIcons(itemId, userFeedbackData, originalMessage, patientData) {
		const iconSkeleton = {
			color: '#979797',
			cursor: 'pointer',
		}

		const dislikeIcon = {
			cursor: 'pointer',
			color: 'red',
		}

		const feedbackIcons = {
			like: <EmojiEmotionsOutlinedIcon fontSize="medium" style={{ color: 'green' }} />,
			dislike: (
				<SentimentDissatisfiedOutlinedIcon
					fontSize="medium"
					style={dislikeIcon}
					onClick={() => {
						setCurrentItem(patientData)
						setCurrentItemId(itemId)
						setSelectOriginalMessage(originalMessage)
					}}
				/>
			),
		}

		return (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
				<>
					{userFeedbackData?.some((item) => item?.status === 'like') && feedbackIcons.like}
					{!userFeedbackData?.some((item) => item?.status === 'like') && (
						<EmojiEmotionsOutlinedIcon fontSize="medium" onClick={() => addReaction(itemId, 'like')} style={iconSkeleton} />
					)}
					&ensp;
				</>
				{userFeedbackData?.some((item) => item?.status === 'dislike') && feedbackIcons.dislike}
				{!userFeedbackData?.some((item) => item?.status === 'dislike') && (
					<SentimentDissatisfiedOutlinedIcon
						fontSize="medium"
						onClick={() => {
							setCurrentItem(patientData)
							setCurrentItemId(itemId)
							setSelectOriginalMessage(originalMessage)
						}}
						style={iconSkeleton}
					/>
				)}
			</div>
		)
	}

	const addReaction = async (queueID, status) => {
		if (status === 'dislike') {
			if (!selectedAcuity || !selectedTaskLabel || !chatbotResponseCritique) {
				toast.error('Please fill out the form in-order to submit the feedback')
				return
			}
		}

		try {
			await axios
				.put(
					`${process.env.REACT_APP_BASE_API_URL}/patientQueues/addReaction/${queueID}`,
					{
						status: status,
						userId: user.id,
						correctTaskLabel: selectedTaskLabel,
						correctAcuityLabel: selectedAcuity,
						proposedChatbotResponse: proposedChatbotResponse,
						chatbotResponseCritique: chatbotResponseCritique,
					},
					config
				)
				.then((res) => {
					setOpen(true)
					// if(patientDrawer){
					// 	setUserFeedback2([res?.data?.feedback])
					// }
				})
		} catch (error) {
			toast.error('Error:' + error)
		}
		setCurrentItemId('')
		setSelectedTaskLabel('')
		setSelectedAcuity('')
		setProposedChatbotResponse('')
		setChatbotResponseCritique('')
		setIsAcuitySelected(false)
		setIsChatbotResponse(false)
		setIsTaskCategorySelected(false)
		setOpenDislikeModal(false)
		if (handleRefetch) {
			handleRefetch()
		}
	}
	const handleClose = () => {
		setOpen(false)
	}

	const setCurrentItem = async (patientData) => {
		setSelectedAcuity(patientData?.acuity)
		setSelectedTaskLabel(patientData?.taskCategory)
		setChatbotResponse(patientData?.chatbotReply)
		setOpenDislikeModal(true)
	}

	const handleChangePage = (event, newPage) => {
		localStorage.setItem('page', newPage)
		setPage(newPage)
		console.log('Page is changed')
	}

	const timeDifference = (createdDate) => {
		const startDate = new Date(createdDate)
		const endDate = new Date()
		let unit = ''
		let time = ''
		const timeDifference = endDate - startDate

		if (timeDifference < 1000) {
			unit = 'seconds'
			time = Math.floor(timeDifference / 1000)
		} else if (timeDifference < 1000 * 60) {
			unit = 'seconds'
			time = Math.floor(timeDifference / 1000)
		} else if (timeDifference < 1000 * 60 * 60) {
			unit = 'minutes'
			time = Math.floor(timeDifference / (1000 * 60))
		} else if (timeDifference < 1000 * 60 * 60 * 24) {
			unit = 'hours'
			time = Math.floor(timeDifference / (1000 * 60 * 60))
		} else {
			unit = 'days'
			time = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
		}

		return `${time} ${unit}`
	}

	const redirectToEditPage = (id) => {
		navigate(`/queueprofile/${id}`, {
			state: { action: 'read-only', id, value },
		})
	}

	const config = {
		headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
	}
	const getColorStyles = (acuity) => {
		if (acuity === 'high') {
			return {
				marginRight: '5px',
				color: '#DC362E',
			}
		} else if (acuity === 'medium') {
			return {
				marginRight: '5px',
				color: '#FFB400',
			}
		} else if (acuity === 'N/A') {
			return {
				marginRight: '5px',
				color: 'black',
			}
		} else {
			return {
				marginRight: '5px',
				color: '#63A02C',
			}
		}
	}
	function calculateDaysRemaining(surgeryDateStr) {
		const today = new Date()
		today.setHours(0, 0, 0, 0)
		const surgeryDate = new Date(surgeryDateStr)
		surgeryDate.setHours(0, 0, 0, 0)
		const differenceMs = surgeryDate - today
		if (differenceMs < 0) {
			return 'Procedure date has passed'
		}
		const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24))

		return `${differenceDays} days until procedure`
	}
	useEffect(() => {
		if (patientQueues) {
			const initialOpenState = new Array(patientQueues.length).fill(false)
			setOpenRows(initialOpenState)
		}
	}, [patientQueues])

	const handleRowToggle = (index) => {
		const newOpenRows = [...rows]
		newOpenRows[index] = !newOpenRows[index]
		setRows(newOpenRows)
	}

	const showMarkAsResolved = (index) => {
		const hoveredPatient = patientQueues[index]
		setIsRowHovered(hoveredPatient?._id)
	}

	const formatProgram = (program) => {
		const words = program.split('_')

		const formattedString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

		return formattedString
	}

	const calculateNumberOfDays = (createdDate) => {
		let today = new Date()
		let date = new Date(createdDate)

		let timeDifference = today - date

		let numberOfDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000))

		return numberOfDays + ' days ago'
	}
	const getCarePhase = (surgeryDate) => {
		if (new Date(surgeryDate) > new Date()) {
			return 'Post-op'
		} else if (new Date(surgeryDate) < new Date()) {
			return 'Pre-op'
		}
	}

	const createRows = (items, selectedPatientIds, setSelectedPatientIds, isRowHovered) => {
		const iconMap = {
			food: <RestaurantIcon />,
			transportation: <DirectionsCarIcon />,
			housing: <HouseOutlinedIcon />,
			safety: <ShieldIcon />,
			utility: <TipsAndUpdatesIcon />,
			financial: <PaymentsIcon />,
			urgent: <DirectionsRunIcon />,
		}

		return items.map((item, index) => ({
			patientInfo: (
				<Box>
					{item?.patient ? (
						<PatientInfo id={item?.patient?._id} patient={item?.patient} surgeryDate={item?.patient?.surgeryDate} redirectUrl={'profile'} />
					) : (
						<Chip label={'Not in System'} size="small" sx={{ backgroundColor: '#B71C1C', color: 'white', fontWeight: 600 }} />
					)}
				</Box>
			),
			dialogueIntent: (
				<>
					{' '}
					<Typography
						variant="p"
						sx={{
							fontSize: 16,
							fontWeight: 400,
							textAlign: 'left',
						}}
					>
						{item?.dialogueIntent ? item?.dialogueIntent : 'No Intent'}
					</Typography>
				</>
			),
			surgeonName: (
				<>
					<Typography
						variant="p"
						sx={{
							fontSize: 16,
							fontWeight: 400,
							textAlign: 'left',
						}}
					>
						{item?.surgeonName ? item?.surgeonName : 'Surgeon Name Unavailable '}
					</Typography>
				</>
			),
			surgeryType: (
				<>
					<Typography
						variant="p"
						sx={{
							fontSize: 16,
							fontWeight: 400,
							textAlign: 'left',
						}}
					>
						{item?.surgeryType
							? item?.surgeryType
									?.replace(/_/g, ' ')
									.split(/\s+/)
									.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									.join(' ')
							: 'Procedure Type Unavailable '}
					</Typography>
				</>
			),
			surgeryDate: (
				<>
					<Typography
						variant="p"
						sx={{
							fontSize: 16,
							fontWeight: 400,
							textAlign: 'left',
						}}
					>
						{item?.surgeryDate ? moment(item?.surgeryDate).format('MM-DD-YYYY') : 'Procedure Date Unavailable'}
					</Typography>
				</>
			),
			collapsibleIcon: (
				<>
					{/* <IconButton aria-label="expand row" size="small" onClick={() => handleRowToggle(index)}>
						{rows[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton> */}
					{console.log(item?._id, selectedPatientIds?.includes(item?._id))}
					<Checkbox
						size="small"
						checked={selectedPatientIds?.includes(item?._id)}
						onChange={() => {
							setSelectedPatientIds((prevState) => (prevState.includes(item._id) ? prevState.filter((id) => id !== item._id) : [...prevState, item._id]))
						}}
					/>
				</>
			),
			collapsibleBody: (
				<>
					<Collapse in={rows[index]} timeout="auto" unmountOnExit style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						{value === 4 ? (
							<Paper
								elevation={3}
								sx={{
									padding: '1.5rem',
									// height: '95%',
									my: '1em',
									borderRadius: '15px',
								}}
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<DialogueAccordion title={'QnA'} chatData={item?.dialogueChat} />
									</Grid>

									<Grid item xs={12}>
										<Typography variant="h6" fontSize={'16px'}>
											Dialogue Summary
										</Typography>
										<Typography>
											<ReactMarkdown
												remarkPlugins={[remarkGfm]}
												components={{
													a: (props) => (
														<a href={props.href} style={{ color: '#000' }} target="blank">
															{props.children}
														</a>
													),
												}}
											>
												{item?.dialogueSummary ? item?.dialogueSummary?.replace(/^\`\`\`|`\`\`$/g, '')?.replace(/\n/g, '  \n') : 'No Summary Available'}
											</ReactMarkdown>
										</Typography>
									</Grid>
								</Grid>
							</Paper>
						) : (
							<Paper
								elevation={3}
								sx={{
									padding: '1.5rem',
									height: '95%',
									my: '1em',
									borderRadius: '15px',
								}}
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography>
											<strong>Care Program</strong>
										</Typography>
										<div style={{ display: 'flex' }}>
											<div
												style={{
													position: 'relative',
													borderRadius: '10px',
													overflow: 'hidden',
													marginRight: '5px',
												}}
											>
												<Chip
													label={
														item?.patient?.program?.length > 0
															? item?.patient?.program[0]
																	.replace(/_/g, ' ')
																	.split(/\s+/)
																	.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
																	.join(' ')
															: 'No Care Program Assigned'
													}
													size="medium"
													sx={{
														marginTop: '-0.2em',
														color: 'black',
														backgroundColor: item?.patient?.program?.length > 0 ? '#e5feec' : 'rgba(255, 31, 0, 0.08)',
														borderRadius: '8px',
														position: 'relative',
													}}
												/>
												<LinearProgress
													variant="determinate"
													value={item?.patient?.program?.length > 0 ? 100 / item?.patient?.program?.length : 0}
													sx={{
														position: 'absolute',
														bottom: '4px',
														left: '2px',
														width: 'calc(100% - 4px)',
														borderRadius: '10px',
														height: '3.5px',
														zIndex: 0,
														backgroundColor: item?.patient?.program?.length > 0 ? '#8fdaa5' : '#D3602F',
														'& .MuiLinearProgress-bar': {
															backgroundColor: '#208b40', // Desired color
														},
													}}
												/>
											</div>
											<Tooltip
												title={
													item?.patient?.program?.length > 1
														? item?.patient?.program
																.slice(1) // Exclude the first index
																.map((word) =>
																	word
																		.replace(/_/g, ' ')
																		.split(/\s+/)
																		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
																		.join(' ')
																)
																.join(', ')
														: 'No additional data'
												}
											>
												<Chip label={'+' + (item?.patient?.program?.length > 0 ? item?.patient?.program?.length - 1 : 0)} sx={{ borderRadius: '10px' }} />
											</Tooltip>
										</div>
									</Grid>
									<Grid item xs={12}>
										<Typography>
											<strong>Message Grading</strong>
										</Typography>
										<div>{renderFeedbackIcons(item?._id, item?.userFeedback, item?.originalMessage, item)}</div>
									</Grid>
									<Grid item xs={12}>
										<Typography>
											<strong>Patient Summary</strong>
										</Typography>
										<Typography>{item?.patient?.patientSummary ? item?.patient?.patientSummary : 'N/A'}</Typography>
									</Grid>

									<Grid item xs={12}>
										<Typography>
											<strong>Patient Message</strong>
										</Typography>
										<Alert severity="info" sx={{ display: 'flex', alignItems: 'center' }}>
											<ReactMarkdown
												remarkPlugins={[remarkGfm]}
												components={{
													a: (props) => (
														<a href={props.href} style={{ color: '#000' }} target="blank">
															{props.children}
														</a>
													),
												}}
											>
												{item?.originalMessage}
											</ReactMarkdown>
										</Alert>
									</Grid>
									<Grid item xs={12}>
										<Typography>
											<strong>Chatbot Reply</strong>
										</Typography>
										<Alert severity="success" sx={{ display: 'flex', alignItems: 'center' }}>
											<ReactMarkdown
												remarkPlugins={[remarkGfm]}
												components={{
													a: (props) => (
														<a href={props.href} style={{ color: '#000' }} target="blank">
															{props.children}
														</a>
													),
												}}
											>
												{item.chatbotReply}
											</ReactMarkdown>
										</Alert>
									</Grid>
									<Grid item xs={12}>
										<Typography>
											<strong>Prediction Reasoning</strong>
										</Typography>
										{item?.reasoning.map((value, i) => (
											<Grid item xs={12} key={i}>
												<Alert severity="warning">{value}</Alert>
												<br />
											</Grid>
										))}
									</Grid>
								</Grid>
							</Paper>
						)}
					</Collapse>
				</>
			),
			createdAt: (
				<>
					<Typography fontSize={14} color="#979797">
						{moment(item?.createdAt).format('MM-DD-YYYY hh:mm a')}
					</Typography>
				</>
			),
			socialNeeds: (
				<div
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '20px',
					}}
				>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: 'repeat(3, 1fr)',
							gap: '10px',
						}}
					>
						{(item?.patient?.socialNeeds || item?.patient?.socialRisks) && (item?.patient?.socialNeeds?.length > 0 || item?.patient?.socialRisks?.length > 0)
							? [...(item?.patient?.socialRisks || []), ...(item?.patient?.socialNeeds || [])].map((icon, index) => {
									const isSocialNeed = item?.patient?.socialNeeds?.includes(icon)
									const color = isSocialNeed ? '#ffb400' : '#DC362E'
									const IconElement = iconMap[icon] || <BlockIcon />
									return (
										<Tooltip key={index} title={icon.charAt(0).toUpperCase() + icon.slice(1)}>
											<div style={{ cursor: 'pointer' }}>{React.cloneElement(IconElement, { sx: { color } })}</div>
										</Tooltip>
									)
							  })
							: 'N/A'}
					</div>
				</div>
			),
			taskCategory: (
				<>
					<Typography fontSize={14} color="black">
						{item.taskCategory ? item.taskCategory : 'N/A'}
					</Typography>
					{tabsValue[value] !== 'new_media' && tabsValue[value] !== 'form_submission' && (
						<Typography
							fontSize={14}
							color="#979797"
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								width: '170px',
							}}
						>
							{item.originalMessage ? item.originalMessage : 'No Message Available!'}
						</Typography>
					)}
				</>
			),
			care_program: (
				<div style={{ display: 'flex' }}>
					<div
						style={{
							position: 'relative',
							borderRadius: '10px',
							overflow: 'hidden',
							marginRight: '5px',
						}}
					>
						<Chip
							label={
								item?.patient?.program?.length > 0
									? item?.patient?.program[0]
											.replace(/_/g, ' ')
											.split(/\s+/)
											.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
											.join(' ')
									: 'No Care Program Assigned'
							}
							size="medium"
							sx={{
								marginTop: '-0.2em',
								color: 'black',
								backgroundColor: item?.patient?.program?.length > 0 ? '#e5feec' : 'rgba(255, 31, 0, 0.08)',
								borderRadius: '8px',
								position: 'relative',
							}}
						/>
						<LinearProgress
							variant="determinate"
							value={item?.patient?.program?.length > 0 ? 100 / item?.patient?.program?.length : 0}
							sx={{
								position: 'absolute',
								bottom: '4px',
								left: '2px',
								width: 'calc(100% - 4px)',
								borderRadius: '10px',
								height: '3.5px',
								zIndex: 0,
								backgroundColor: item?.patient?.program?.length > 0 ? '#8fdaa5' : '#D3602F',
								'& .MuiLinearProgress-bar': {
									backgroundColor: '#208b40', // Desired color
								},
							}}
						/>
					</div>
					<Tooltip
						title={
							item?.patient?.program?.length > 1
								? item?.patient?.program
										.slice(1) // Exclude the first index
										.map((word) =>
											word
												.replace(/_/g, ' ')
												.split(/\s+/)
												.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
												.join(' ')
										)
										.join(', ')
								: 'No additional data'
						}
					>
						<Chip label={'+' + (item?.patient?.program?.length > 0 ? item?.patient?.program?.length - 1 : 0)} sx={{ borderRadius: '10px' }} />
					</Tooltip>
				</div>
			),
			acuity: (
				<Typography fontSize={14} color="black" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					{item.acuity && <span style={{ ...getColorStyles(item.acuity), fontSize: '36px' }}>•</span>}
					{item.acuity ? item.acuity.charAt(0).toUpperCase() + item.acuity.slice(1) : 'N/A'}
				</Typography>
			),
			ChatbotMode: (
				<Typography fontSize={14} color="black">
					{item?.patient?.chatMode === 'automatic' ? 'Autopilot' : item?.patient?.chatMode === 'manual' ? 'Manual' : 'Care Guard'}
				</Typography>
			),
			action: (
				<Box>
					{item?.patient ? (
						<LastCell
							id={item._id}
							firstName={item?.patient?.firstName}
							patientId={item?.patient?._id}
							path={item?.patient?.path}
							queueStatus={item?.status}
							onRefetch={handleRefetch}
							patient={item?.patient}
							FormData={item}
							acuity={item?.acuity}
							userFeedback={item?.userFeedback}
							originalMessage={item?.originalMessage}
							chatbotReply={item?.chatbotReply}
							reasoning={item?.reasoning}
							createdAt={item?.createdAt}
							value={value}
							taskCategory={item?.taskCategory}
							refetchCount={refetchCount}
							formId={item?.metadata?.assignFormId}
							isRowHovered={isRowHovered}
						/>
					) : (
						<Button
							variant="contained"
							color="primary"
							size="small"
							startIcon={<LinkIcon />}
							onClick={() => setLinkQueueModalOpen({ value: true, data: item?._id })}
						>
							Link With Patient
						</Button>
					)}
				</Box>
			),
			queueType: (
				<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					{typeData
						.filter((type) => type.key === item?.type)
						.map((matchedType) => {
							const IconComponent = iconMapping[matchedType.icon]
							return (
								<React.Fragment key={matchedType.key}>
									{IconComponent && <IconComponent sx={{ marginRight: '5px' }} fontSize="small" />}
									<Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
										{matchedType.label || item?.type}
									</Typography>
								</React.Fragment>
							)
						})}
				</Box>
			),
		}))
	}
	const [openFilter, setOpenFilter] = useState(false)

	const handleOpenFilter = (e) => {
		setOpenFilter(true)
	}

	const handleSelectAll = () => {
		if (selectedPatientIds.length === patientQueues.length) {
			// Deselect all if all are already selected
			setSelectedPatientIds([])
		} else {
			// Select all patient IDs
			setSelectedPatientIds(patientQueues.map((patient) => patient._id))
		}
	}

	// useEffect(() => {
	// 	if (data) {
	// 		const interval = setInterval(() => {
	// 			refetch()
	// 		}, 5000)

	// 		return () => clearInterval(interval)
	// 	}
	// }, [data])

	useEffect(() => {
		if (queueData) {
			if (queueData?.patientQueues !== prevDataRef?.current?.length) {
				prevDataRef.current = queueData?.patientQueues

				setTotalPages(queueData?.totalPages)
				setPatientQueues(queueData?.patientQueues)
			}
		}
	}, [queueData])

	useEffect(() => {
		if (selectedValue) {
			setPage(1)
			const filteredDate = filterByDate(selectedValue)
			localStorage.setItem('createdAfter', filteredDate)
			setCreatedAfter(filteredDate)
		}
	}, [selectedValue])

	useEffect(() => {
		if (page > queueData?.totalPages) {
			localStorage.setItem('page', 1)
			setPage(1)
		}
	}, [queueData])

	useEffect(() => {
		if (procedureTypeData?.data) {
			const surgeryType = {
				value: procedureTypeData?.data?.map((item) => ({
					id: item._id,
					title: item.name,
					value: toSnakeCase(item.name),
				})),
			}

			const newPatientQueueFilterData = {
				...patientQueueFilterData,
				surgeryType,
			}

			setFilterData(newPatientQueueFilterData)
		} else {
			setFilterData(patientQueueFilterData)
		}
	}, [procedureTypeData])

	return (
		<>
			<div style={{ marginTop: '20px' }}>
				<Paper
					sx={{
						border: '1px ',
						width: '100%',
						borderRadius: '10px',
					}}
				>
					<TableContainer sx={{ borderRadius: '4px' }} className="DialogContent">
						<Table>
							<TableHead style={{ backgroundColor: 'rgba(0, 151, 236, 0.12)' }}>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											variant="head"
											key={column.id}
											align="left"
											sx={{
												fontWeight: 600,
												fontSize: '14px',
												color: '#50607A',
												paddingY: '5px',
											}}
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												{column.label}
												{column.label === '' && (
													<Checkbox
														size="small"
														checked={patientQueues?.length !== 0 && selectedPatientIds?.length === patientQueues?.length}
														onChange={handleSelectAll}
													/>
												)}
												{column.label === 'Message Time' && (
													<IconButton onClick={() => handleSorting(column.id)}>
														{sortCreatedAtOrder.field === column.id && sortCreatedAtOrder.sort === 'asc' ? (
															<ArrowUpwardIcon sx={{ height: '15px', color: '#536878' }} />
														) : (
															<SouthIcon sx={{ height: '15px', color: '#536878' }} />
														)}
													</IconButton>
												)}
												{column.label === 'Patient Information' && (
													<IconButton onClick={() => handleSorting(column.id)}>
														{sortPatientOrder.field === column.id && sortPatientOrder.sort === 'asc' ? (
															<ArrowUpwardIcon sx={{ height: '15px', color: '#536878' }} />
														) : (
															<SouthIcon sx={{ height: '15px', color: '#536878' }} />
														)}
													</IconButton>
												)}

												{column.label === 'Surgeon Name' && (
													<IconButton onClick={() => handleSorting(column.id)}>
														{sortSurgeonName.field === column.id && sortSurgeonName.sort === 'asc' ? (
															<ArrowUpwardIcon sx={{ height: '15px', color: '#536878' }} />
														) : (
															<SouthIcon sx={{ height: '15px', color: '#536878' }} />
														)}
													</IconButton>
												)}

												{column.label === 'Acuity' && (
													<>
														{acuityCount !== 0 && (
															<Tooltip title="High Acuity Queues Count" sx={{ mr: 1, ml: 2.5 }}>
																<Badge badgeContent={acuityCount} color="primary" />
															</Tooltip>
														)}
														<IconButton onClick={() => handleSorting(column.id)}>
															{sortAcuityOrder.field === column.id && sortAcuityOrder.sort === 'asc' ? (
																<ArrowUpwardIcon sx={{ height: '15px', color: '#536878' }} />
															) : (
																<SouthIcon sx={{ height: '15px', color: '#536878' }} />
															)}
														</IconButton>
													</>
												)}
											</div>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{createRows(patientQueues, selectedPatientIds, setSelectedPatientIds, isRowHovered).map((row, i) => (
									<>
										<TableRow
											hover
											role="checkbox"
											tabIndex={-1}
											key={i}
											sx={{
												background: row.status === 'resolved' || openRows[i] ? '#0000000a' : '',
											}}
											onMouseEnter={() => showMarkAsResolved(i)}
											onMouseLeave={() => setIsRowHovered(null)}
										>
											{columns.map((column) => {
												const value = row[column.id]
												return (
													<TableCell
														key={column.id}
														align="left"
														sx={{
															color: 'text.secondary',
														}}
													>
														{value}
													</TableCell>
												)
											})}
										</TableRow>
										<TableRow>
											<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
												{row.collapsibleBody}
											</TableCell>
										</TableRow>
									</>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<PaginationBar {...{ page, count: totalPages, handleChange: handleChangePage }} />
				</Paper>
			</div>
			<FiltersDialog open={openFilter} setOpen={setOpenFilter} queriesValue={queriesValue} setQueriesValue={setQueriesValue} data={filterData} />
			<SnackbarMsg msg={'Thank you for giving us feedback!'} handleClose={handleClose} open={open} width={'310px'} />
			<WhatwentwrongDialog
				open={openDislikeModal}
				setOpen={setOpenDislikeModal}
				addReaction={addReaction}
				queueId={currentItemId}
				originalMessage={selectOriginalMessage}
				isAcuitySelected={isAcuitySelected}
				setIsAcuitySelected={setIsAcuitySelected}
				isChatbotResponse={isChatbotResponse}
				setIsChatbotResponse={setIsChatbotResponse}
				isTaskCategorySelected={isTaskCategorySelected}
				setIsTaskCategorySelected={setIsTaskCategorySelected}
				proposedChatbotResponse={proposedChatbotResponse}
				setProposedChatbotResponse={setProposedChatbotResponse}
				chatbotResponseCritique={chatbotResponseCritique}
				setChatbotResponseCritique={setChatbotResponseCritique}
				selectedAcuity={selectedAcuity}
				setSelectedAcuity={setSelectedAcuity}
				selectedTaskLabel={selectedTaskLabel}
				setSelectedTaskLabel={setSelectedTaskLabel}
				chatbotResponse={chatbotResponse}
			/>
			<LinkQueueModal
				open={linkQueueModalOpen?.value}
				queueId={linkQueueModalOpen?.data}
				onClose={() => setLinkQueueModalOpen({ value: false, data: null })}
				refetchQueueAgain={refetchQueueAgain}
			/>
		</>
	)
}
