import React, { useEffect, useState } from "react";
import { CircularLoader, InactivityCheck } from "../../Components";
import { PatientQueue } from "./PatientQueue";
import Grid from "@mui/material/Grid";
import { UpperAnalytics } from "./UpperAnalytics";
import { FilterSidebar } from "./FilterSidebar";
import { useGetProceduresOptionsQuery } from "../../store/proceduresSlice";
import { toSnakeCase } from '../../utils'
import { patientQueueFilterData } from '../../FilterUtils'
import { useGetPatientQueuesQuery } from "../../store/patientQueuesSlice";
import { useSelector } from "react-redux";
import { useGetPhysiciansOptionsQuery } from "../../store/physiciansSlice";
import filterByDate from "../patientQueue/DateFilterFunction";

export function Dashboard() {
  const { user } = useSelector((state) => state.user)

  const [page, setPage] = useState(localStorage.getItem('page') ? parseInt(localStorage.getItem('page')) : 1)
  const [filterData, setFilterData] = useState({})
	const [queriesValue, setQueriesValue] = useState(localStorage.getItem('queriesValue') ? JSON.parse(localStorage.getItem('queriesValue')) : {})
  const [selectedPatientIds, setSelectedPatientIds] = useState([]);

  // Sorting states
  const [sortCreatedAtOrder, setSortCreatedAtOrder] = useState(
		localStorage.getItem('sortCreatedAtOrder') ? JSON.parse(localStorage.getItem('sortCreatedAtOrder')) : { field: 'createdAt', sort: 'desc' }
	)
	const [sortAcuityOrder, setSortAcuityOrder] = useState(
		localStorage.getItem('sortAcuityOrder') ? JSON.parse(localStorage.getItem('sortAcuityOrder')) : { field: 'acuity', sort: 'asc' }
	)
	const [sortPatientOrder, setSortPatientOrder] = useState(
		localStorage.getItem('sortPatientOrder') ? JSON.parse(localStorage.getItem('sortPatientOrder')) : { field: 'patientInfo', sort: 'asc' }
	)
	const [sortField, setSortField] = useState(localStorage.getItem('sortField') ? localStorage.getItem('sortField') : '')
	const [sortSurgeonName, setSortSurgeonName] = useState(
		localStorage.getItem('sortSurgeonName') ? JSON.parse(localStorage.getItem('sortSurgeonName')) : { field: 'surgeonName', sort: 'asc' }
	)
	const [sortOrder, setSortOrder] = useState(localStorage.getItem('sortOrder') ? localStorage.getItem('sortOrder') : '')
  const [createdAfter, setCreatedAfter] = useState(localStorage.getItem('createdAfter') ? localStorage.getItem('createdAfter') : '')
  const [selectedValue, setSelectedValue] = useState(localStorage.getItem('selectedValue') ? localStorage.getItem('selectedValue') : 'all')
  const queries = {}

  queries['acuity'] = queriesValue?.acuity || []
	queries['status'] = queriesValue?.status || 'pending'
	queries['taskCategory'] = queriesValue?.taskCategory?.map(item => item.value) || []
	queries['program'] = queriesValue?.program?.map(item => item.value)  || []
	queries['surgeryType'] = queriesValue?.surgeryType?.map(item => item.id)  || []
  queries['surgeon'] = queriesValue?.surgeon?.map(item => item.id)  || []
  queries['types'] = queriesValue?.types  || []

  const { data: procedureTypeData, isLoading } = useGetProceduresOptionsQuery()

  const { data: physicianOptions, isLoading: isPhysicianLoading } =
  useGetPhysiciansOptionsQuery();

  const { data, refetch, isLoading: isQueueLoading, isSuccess } = useGetPatientQueuesQuery(
		{
			page,
			userId: user.id,
			sortField,
			sortOrder,
			createdAfter,
			...queries,
		},
		{ refetchOnMountOrArgChange: true }
  )

  const refetchQueueAgain = () => {
    refetch()
  }

  const handleSorting = (field) => {
		if (field === sortCreatedAtOrder.field) {
			setSortCreatedAtOrder(sortCreatedAtOrder.sort === 'asc' ? { field: 'createdAt', sort: 'desc' } : { field: 'createdAt', sort: 'asc' })
			setSortField(field)
			setSortOrder(sortCreatedAtOrder.sort === 'asc' ? 'desc' : 'asc')
			localStorage.setItem(
				'sortCreatedAtOrder',
				JSON.stringify(sortCreatedAtOrder.sort === 'asc' ? { field: 'createdAt', sort: 'desc' } : { field: 'createdAt', sort: 'asc' })
			)
			localStorage.setItem('sortField', field)
			localStorage.setItem('sortOrder', sortCreatedAtOrder.sort === 'asc' ? 'desc' : 'asc')
		} else if (field === sortAcuityOrder.field) {
			setSortAcuityOrder(sortAcuityOrder.sort === 'asc' ? { field: 'acuity', sort: 'desc' } : { field: 'acuity', sort: 'asc' })
			setSortField(field)
			setSortOrder(sortAcuityOrder.sort === 'asc' ? 'desc' : 'asc')
			localStorage.setItem('sortField', field)
			localStorage.setItem('sortOrder', sortAcuityOrder.sort === 'asc' ? 'desc' : 'asc')
			localStorage.setItem(
				'sortAcuityOrder',
				JSON.stringify(sortAcuityOrder.sort === 'asc' ? { field: 'acuity', sort: 'desc' } : { field: 'acuity', sort: 'asc' })
			)
		} else if (field === sortSurgeonName.field) {
			setSortSurgeonName(sortSurgeonName.sort === 'asc' ? { field: 'surgeonName', sort: 'desc' } : { field: 'surgeonName', sort: 'asc' })
			setSortField(field)
			setSortOrder(sortSurgeonName.sort === 'asc' ? 'desc' : 'asc')
			localStorage.setItem(
				'sortSurgeonName',
				JSON.stringify(sortSurgeonName.sort === 'asc' ? { field: 'surgeonName', sort: 'desc' } : { field: 'surgeonName', sort: 'asc' })
			)
			localStorage.setItem('sortField', field)
			localStorage.setItem('sortOrder', sortSurgeonName.sort === 'asc' ? 'desc' : 'asc')
		} else if (field === sortPatientOrder.field) {
			setSortPatientOrder(sortPatientOrder.sort === 'asc' ? { field: 'patientInfo', sort: 'desc' } : { field: 'patientInfo', sort: 'asc' })
			setSortField('patientName')
			setSortOrder(sortPatientOrder.sort === 'asc' ? 'desc' : 'asc')
			localStorage.setItem(
				'sortPatientOrder',
				JSON.stringify(sortPatientOrder.sort === 'asc' ? { field: 'patientInfo', sort: 'desc' } : { field: 'patientInfo', sort: 'asc' })
			)
			localStorage.setItem('sortField', 'patientName')
			localStorage.setItem('sortOrder', sortPatientOrder.sort === 'asc' ? 'desc' : 'asc')
		}
	}

  useEffect(() => {
		if (procedureTypeData?.data) {
			const surgeryType = {
				value: procedureTypeData?.data?.map((item) => ({
					id: item._id,
					title: item.name,
					value: toSnakeCase(item.name),
				})),
			}

      const surgeon = {
        value: physicianOptions?.map((item) => ({
					id: item._id,
					title: item.name,
					value: toSnakeCase(item.name),
				})),
      }
			const newPatientQueueFilterData = {
				...patientQueueFilterData,
				surgeryType,
        surgeon
			}

			setFilterData(newPatientQueueFilterData)
		} else {
			setFilterData(patientQueueFilterData)
		}
	}, [procedureTypeData, physicianOptions])

  // This useEffect set the selectedIds null when filter change
  useEffect(()=>{
    if(queriesValue?.status){
      setSelectedPatientIds([]);
    }
  },[queriesValue])

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch()
    }, 10000);

    return () => clearInterval(intervalId);
  }, []); 

  useEffect(() => {
		if (selectedValue) {
			setPage(1)
			const filteredDate = filterByDate(selectedValue)
			localStorage.setItem('createdAfter', filteredDate)
			setCreatedAfter(filteredDate)
		}
	}, [selectedValue])

  // Loader for both data
  if (isLoading || isQueueLoading || isPhysicianLoading)
    return (
      <>
        <CircularLoader />
      </>
    );

  // If Token is expire it navigate to Login page
  // !isAuthenticated && navigate("/")
  return (
    <Grid container spacing={2} sx={{ background: "#fff" }}>
      <Grid
        item
        xs={2}
        sx={{
          borderRight: "1px solid #DFE1E6",
          // boxShadow: "1px 5px 1px 1px rgba(52, 58, 64, 0.2)",
        }}
      >
        <FilterSidebar data={filterData} queriesValue={queriesValue} setQueriesValue={setQueriesValue} />
      </Grid>
      <Grid item xs={10}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "1.5rem 1.5rem 1.5rem 0.5rem",
          }}
        >
          {/* <TotalCount {...{ data: data || [], isError }} /> */}
          <UpperAnalytics data={data?.metrics || {}} />
          <PatientQueue queueData={data || null} queries={queriesValue} setQueries={setQueriesValue} selectedPatientIds={selectedPatientIds} setSelectedPatientIds={setSelectedPatientIds} refetchQueueAgain={refetchQueueAgain} page={page} setPage={setPage} handleSorting={handleSorting} sortPatientOrder={sortPatientOrder} sortAcuityOrder={sortAcuityOrder} selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
          <InactivityCheck />
        </div>
      </Grid>
    </Grid>
  );
}
